import { Player } from '../types';
import undefinedImage from '../assets/undefined.png';

interface FIFACardProps {
    player: Player;
    teamImage: string | undefined;
    teamName: string | undefined;
}

export const FIFACard = (props: FIFACardProps) => {
    const player: Player = {
        name: props.player.name,
        email: props.player.email,
        uid: props.player.uid,
        image: props.player.image,
        country: props.player.country,
        position: props.player.position,
        cardStyle: props.player.cardStyle,
        stats: [],
        FIFAStats: props.player.FIFAStats,
    };
    const pace = player.FIFAStats.pace || 60;
    const shot = player.FIFAStats.shot || 60;
    const pass = player.FIFAStats.pass || 60;
    const dribbling = player.FIFAStats.dribbling || 60;
    const defense = player.FIFAStats.defense || 60;
    const physical = player.FIFAStats.physical || 60;
    const overall = player.FIFAStats.overall || 60;
    const cssTheme = 'light';
    const cssClass = player.cardStyle || '';
    const displayedImageUrl = player.image;
    const teamImage = props.teamImage;
    const teamName = props.teamName;

    return (
        <div className='fut-wrapper'>
            {/* <!-- *** fut-player-card ***--> */}
            <div className={`fut-player-card ${cssClass}`}>
                {/* <!-- Player Card Top--> */}
                <div className={`player-card-top ${cssClass}-top`}>
                    <div className={`player-master-info master-info-${cssTheme}`}>
                        <div className='player-rating'>
                            <span>{overall}</span>
                        </div>
                        <div className='player-position'>
                            <span>{player?.position || ''}</span>
                        </div>
                        <div className='player-nation'>
                            <img
                                loading='lazy'
                                src={`https://flagcdn.com/80x60/${player?.country?.code.toLowerCase()}.png`}
                                alt={`${player?.country?.code.toLowerCase() || ''}`}
                                draggable='false'
                            />
                        </div>
                        <div className='player-club'>
                            <img src={teamImage || undefinedImage} alt={`${teamName}`} draggable='false' />
                        </div>
                    </div>
                    <div className='player-picture'>
                        <img alt={player?.name || ''} src={displayedImageUrl || ''} draggable='false' />
                    </div>
                </div>
                {/* <!-- Player Card Bottom--> */}
                <div className='player-card-bottom'>
                    <div className={`player-info ${cssClass}-info`}>
                        {/* <!-- Player Name--> */}
                        <div className='player-name'>
                            <span>{player?.name || ''}</span>
                        </div>
                        {/* <!-- Player Features--> */}
                        <div className='player-features'>
                            <div className='player-features-col'>
                                <span>
                                    <div className='player-feature-value'>{pace}</div>
                                    <div className='player-feature-title'>PAC</div>
                                </span>

                                <span>
                                    <div className='player-feature-value'>{shot}</div>
                                    <div className='player-feature-title'>SHO</div>
                                </span>
                                <span>
                                    <div className='player-feature-value'>{pass}</div>
                                    <div className='player-feature-title'>PAS</div>
                                </span>
                            </div>
                            <div className='player-features-col'>
                                <span>
                                    <div className='player-feature-value'>{dribbling}</div>
                                    <div className='player-feature-title'>DRI</div>
                                </span>
                                <span>
                                    <div className='player-feature-value'>{defense}</div>
                                    <div className='player-feature-title'>DEF</div>
                                </span>
                                <span>
                                    <div className='player-feature-value'>{physical}</div>
                                    <div className='player-feature-title'>PHY</div>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
