import React from 'react';
import './App.css';
import './styles/FIFA.css';
import './styles/PublicNavBar.css';
import './styles/Swiper.css';
import { BrowserRouter, Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import AppContainer from './components/AppContainer';
import { PrivateRoute } from './routes/PrivateRoute';
import { PublicRoute } from './routes/PublicRoute';

import { Login } from './routes/Login/Login';
import { Signup } from './routes/Signup/Signup';
import { ForgotPassword } from './routes/ForgotPassword/ForgotPassword';

import { AuthContextProvider, UserAuth } from './context/AuthContext';
import { LoadingOverlay } from './components/LoadingOverlay';
import { AuthenticatedUser, UserRoles } from './types';
import { Home } from './routes/Public/Home/Home';
import { AdminDashboard } from './routes/AdminDashboard/AdminDashboard';
import { getUserByUID } from './utils/api';
import { initialAuthenticatedUserData } from './utils/consts';
import { Players } from './routes/AdminDashboard/Players/Players';
import { Teams } from './routes/AdminDashboard/Teams/Teams';
import { Provider } from 'react-redux';
import { useAppDispatch, useAppStore } from './store/store';
import {
    fetchAllFantasySessions,
    fetchAllFantasyTeams,
    fetchAllMatchDays,
    fetchAllNews,
    fetchAllPlayers,
    fetchAllSeasons,
    fetchAllTeams,
    fetchAllUsers,
    fetchAllVotingSessions,
    fetchSiteData,
} from './utils/actions';
import { NewsRoute } from './routes/AdminDashboard/News/News';
import { Seasons } from './routes/AdminDashboard/Seasons/Seasons';
import { Schedule } from './routes/AdminDashboard/Schedule/Schedule';
import { PlayerStats } from './routes/AdminDashboard/PlayerStats/PlayerStats';
import { FIFAManager } from './routes/AdminDashboard/FIFAManager/FIFAManager';
import { PlayerDetails } from './routes/AdminDashboard/Players/PlayerDetails';
import { TeamDetails } from './routes/AdminDashboard/Teams/TeamDetails';
import { UserManager } from './routes/AdminDashboard/UserManager/UserManager';
import { PlayerProfile } from './routes/PlayerDashboard/PlayerProfile/PlayerProfile';
import { VoteManager } from './routes/AdminDashboard/VoteManager/VoteManager';
import { Voting } from './routes/PlayerDashboard/Voting/Voting';
import { PublicTeams } from './routes/Public/Teams/Teams';
import { PublicTeamDetails } from './routes/Public/Teams/TeamDetails';
import { PublicPlayers } from './routes/Public/Players/Players';
import { PublicPlayerDetails } from './routes/Public/Players/PlayerDetails';
import { LeagueLeaders } from './routes/Public/LeagueLeaders/LeagueLeaders';
import { LoadingScreen } from './components/LoadingScreen';
import { Matches } from './routes/PlayerDashboard/Matches/Matches';
import { VoteSessionDetails } from './routes/AdminDashboard/VoteManager/VoteDetails';
import { FantasyManager } from './routes/AdminDashboard/FantasyManager/FantasyManager';
import { Fantasy } from './routes/UserDashboard/Fantasy/Fantasy';

function AuthenticatedApp() {
    const dispatch = useAppDispatch();
    const location = useLocation();
    const { user } = UserAuth();
    const [profileData, setProfileData] = React.useState<AuthenticatedUser>(initialAuthenticatedUserData);

    React.useEffect(() => {
        localStorage.setItem('lastPage', location.pathname);
    }, [location]);

    React.useEffect(() => {
        if (user) {
            const fetchUserData = async () => {
                const idToken = await user.getIdToken();
                getUserByUID(user.uid, idToken).then((data) => {
                    setProfileData(data.user);
                });
            };
            fetchUserData();
        }
    }, [user]);

    React.useEffect(() => {
        dispatch(fetchAllPlayers());
        dispatch(fetchAllTeams());
        dispatch(fetchAllNews());
        dispatch(fetchSiteData());
        dispatch(fetchAllSeasons());
        dispatch(fetchAllMatchDays());
        dispatch(fetchAllUsers());
        dispatch(fetchAllVotingSessions());
        dispatch(fetchAllFantasySessions());
        dispatch(fetchAllFantasyTeams());
    }, [dispatch]);

    return (
        <>
            <LoadingScreen />
            <AppContainer>
                <>
                    <Routes>
                        {/* admin */}
                        {user && profileData.role === UserRoles.ADMIN && (
                            <>
                                <Route
                                    path='/admin-dashboard/*'
                                    element={
                                        <PrivateRoute>
                                            <AdminDashboard />
                                        </PrivateRoute>
                                    }
                                />
                                <Route
                                    path='/admin-dashboard/players'
                                    element={
                                        <PrivateRoute>
                                            <Players />
                                        </PrivateRoute>
                                    }
                                />
                                <Route
                                    path='/admin-dashboard/players/:playerUid'
                                    element={
                                        <PrivateRoute>
                                            <PlayerDetails />
                                        </PrivateRoute>
                                    }
                                />
                                <Route
                                    path='/admin-dashboard/teams'
                                    element={
                                        <PrivateRoute>
                                            <Teams />
                                        </PrivateRoute>
                                    }
                                />
                                <Route
                                    path='/admin-dashboard/teams/:teamUid'
                                    element={
                                        <PrivateRoute>
                                            <TeamDetails />
                                        </PrivateRoute>
                                    }
                                />
                                <Route
                                    path='/admin-dashboard/news'
                                    element={
                                        <PrivateRoute>
                                            <NewsRoute />
                                        </PrivateRoute>
                                    }
                                />
                                <Route
                                    path='/admin-dashboard/seasons'
                                    element={
                                        <PrivateRoute>
                                            <Seasons />
                                        </PrivateRoute>
                                    }
                                />
                                <Route
                                    path='/admin-dashboard/schedule'
                                    element={
                                        <PrivateRoute>
                                            <Schedule />
                                        </PrivateRoute>
                                    }
                                />
                                <Route
                                    path='/admin-dashboard/player-stats'
                                    element={
                                        <PrivateRoute>
                                            <PlayerStats />
                                        </PrivateRoute>
                                    }
                                />
                                <Route
                                    path='/admin-dashboard/card-manager'
                                    element={
                                        <PrivateRoute>
                                            <FIFAManager />
                                        </PrivateRoute>
                                    }
                                />
                                <Route
                                    path='/admin-dashboard/user-manager'
                                    element={
                                        <PrivateRoute>
                                            <UserManager />
                                        </PrivateRoute>
                                    }
                                />
                                <Route
                                    path='/admin-dashboard/vote-manager/:voteSessionUid'
                                    element={
                                        <PrivateRoute>
                                            <VoteSessionDetails />
                                        </PrivateRoute>
                                    }
                                />
                                <Route
                                    path='/admin-dashboard/vote-manager'
                                    element={
                                        <PrivateRoute>
                                            <VoteManager />
                                        </PrivateRoute>
                                    }
                                />
                                <Route
                                    path='/admin-dashboard/fantasy-manager'
                                    element={
                                        <PrivateRoute>
                                            <FantasyManager />
                                        </PrivateRoute>
                                    }
                                />
                            </>
                        )}

                        {/* player */}
                        {user && profileData.role === UserRoles.PLAYER && (
                            <>
                                <Route
                                    path='/player-dashboard/profile/*'
                                    element={
                                        <PrivateRoute>
                                            <PlayerProfile />
                                        </PrivateRoute>
                                    }
                                />
                                <Route
                                    path='/player-dashboard/voting'
                                    element={
                                        <PrivateRoute>
                                            <Voting />
                                        </PrivateRoute>
                                    }
                                />
                            </>
                        )}
                        {/* user */}
                        {user && (profileData.role === UserRoles.USER || profileData.role === UserRoles.PLAYER) && (
                            <>
                                <Route
                                    path='/user-dashboard/fantasy'
                                    element={
                                        <PrivateRoute>
                                            <Fantasy />
                                        </PrivateRoute>
                                    }
                                />
                            </>
                        )}
                        {/* public */}
                        <Route path='/' element={<Home />} />
                        <Route
                            path='/login'
                            element={
                                <PublicRoute>
                                    <Login />
                                </PublicRoute>
                            }
                        />
                        <Route
                            path='/forgot-password'
                            element={
                                <PublicRoute>
                                    <ForgotPassword />
                                </PublicRoute>
                            }
                        />
                        <Route
                            path='/signup'
                            element={
                                <PublicRoute>
                                    <Signup />
                                </PublicRoute>
                            }
                        />
                        <Route path='/teams' element={<PublicTeams />} />
                        <Route path='/teams/:teamUid' element={<PublicTeamDetails />} />
                        <Route path='/players' element={<PublicPlayers />} />
                        <Route path='/matches' element={<Matches />} />
                        <Route path='/players/:playerUid' element={<PublicPlayerDetails />} />
                        <Route path='/leagueLeaders/:attribute/' element={<LeagueLeaders />} />
                        {/* Catch-all route */}
                        <Route path='*' element={<RedirectToHome user={user} />} />
                    </Routes>
                </>
            </AppContainer>
        </>
    );
}

const RedirectToHome = (props: { user: any }) => {
    const navigate = useNavigate();

    React.useEffect(() => {
        const publicRoutes = ['/', '/login', '/forgot-password', '/signup', '/teams', '/players', '/matches'];
        const privateRoutes = [
            ...publicRoutes,
            '/admin-dashboard',
            '/admin-dashboard/players',
            '/admin-dashboard/teams',
            '/admin-dashboard/news',
            '/admin-dashboard/seasons',
            '/admin-dashboard/player-stats',
            '/admin-dashboard/card-manager',
            '/admin-dashboard/user-manager',
            '/player-dashboard',
            '/player-dashboard/profile/',
            '/player-dashboard/voting/',
        ];
        const currentPath = window.location.pathname;
        if (props.user) {
            // If the user is authenticated, check if the current path is a valid route
            if (privateRoutes.includes(currentPath)) {
                navigate(currentPath, { replace: true });
            } else {
                // If the current path is not a valid route, navigate to the default authenticated route
                navigate('/', { replace: true });
            }
        } else {
            // If the user is not authenticated, check if the current path is a valid route
            if (publicRoutes.includes(currentPath)) {
                navigate(currentPath, { replace: true });
            } else {
                // If the current path is not a valid route, navigate to the login page
                navigate('/', { replace: true });
            }
        }
    }, [props.user, navigate]);

    return null;
};

function AuthWrapper({ children }: any) {
    const { user, isLoading } = UserAuth();
    const navigate = useNavigate();

    React.useEffect(() => {
        if (!isLoading && user) {
            const lastPage = localStorage.getItem('lastPage');
            if (lastPage) {
                navigate(lastPage);
            } else {
                navigate('/');
            }
        }
    }, [user, navigate, isLoading]);

    return isLoading ? <LoadingOverlay /> : children;
}

function App() {
    return (
        <BrowserRouter>
            <Provider store={useAppStore}>
                <AuthContextProvider>
                    <AuthWrapper>
                        <AuthenticatedApp />
                    </AuthWrapper>
                </AuthContextProvider>
            </Provider>
        </BrowserRouter>
    );
}

export default App;
