import React from 'react';
import { CircularProgress, Box, Typography } from '@mui/material';

interface LoadingOverlayProps {
    message?: string;
}

export const LoadingOverlay = (props: LoadingOverlayProps): JSX.Element => {
    return (
        <Box
            sx={{
                position: 'fixed',
                zIndex: 999,
                left: 0,
                top: 0,
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                alignItems: 'center',
                backgroundColor: 'rgba(241, 241, 241, 1)',
            }}
        >
            <Typography variant='subtitle1'>{props.message}</Typography>
            <br />
            <div>
                <CircularProgress />
            </div>
        </Box>
    );
};
