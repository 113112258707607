export enum UserRoles {
    ADMIN = 'admin',
    PLAYER = 'player',
    USER = 'user',
}

export interface AuthenticatedUser {
    role: UserRoles;
    uid: string;
    email: string;
    playerUid: string;
}

export interface LoggedInUser extends AuthenticatedUser {
    canVote: boolean;
}

// data
export interface FIFAStats {
    overall: number;
    pace: number;
    defense: number;
    dribbling: number;
    physical: number;
    pass: number;
    shot: number;
}

export interface GameStats {
    goals: number;
    nutMegs: number;
    blocks: number;
    assists: number;
    mp: number;
}

export interface Standing {
    position: number;
    team: string;
    teamUid: string;
    points: number;
    pld: number;
    w: number;
    d: number;
    l: number;
    gf: number;
    ga: number;
    gd: number;
    teamColor: string;
}

export interface PlayerLeaderBoard {
    name: string;
    rank: number;
    position: string;
    points: number;
    goals: number;
    assists: number;
    blocks: number;
    nutMegs: number;
    image: string;
    country: {
        name: string;
        code: string;
    };
}

export interface LeagueLeader {
    name: string;
    position: string;
    image: string;
    country: {
        name: string;
        code: string;
    };
    team: Team | undefined;
    value: number;
}

export interface VoteData {
    pace: string;
    shot: string;
    pass: string;
    dribbling: string;
    defense: string;
    physical: string;
    playerVotedOnUid: string; // UID of the player being voted on
}

export interface Vote {
    voterUid: string; // UID of the voter
    votingSessionId: string;
    votes: VoteData[]; // Votes made by this voter
}

// people
export interface Player {
    name: string;
    email: string;
    uid: string;
    image: string;
    country: {
        name: string;
        code: string;
    };
    position: string;
    cardStyle: string;
    FIFAStats: FIFAStats;
    stats: {
        seasonUid: string;
        matchDays: {
            goals: string | number;
            nutMegs: string | number;
            blocks: string | number;
            assists: string | number;
            matchId: string;
            mp: string | number;
        }[];
    }[];
}

// things
export interface Team {
    name: string;
    uid: string;
    image: string;
    players: string[];
    color: string;
}

export interface News {
    uid: string;
    text: string;
    date: string;
}

export interface Season {
    uid: string;
    seasonNumber: number;
}

export interface SeasonPayload {
    matches: MatchDay[];
    teams: string[];
    date: string;
}

export interface MatchDay {
    uid: string;
    seasonUid: string;
    teamAUid: string;
    teamBUid: string;
    teamAGoals: string;
    teamBGoals: string;
    date: string;
}

export interface FormmattedMatchDay {
    seasonNumber: number;
    date: string;
    teamAName: string;
    teamBName: string;
    teamAGoals: number;
    teamBGoals: number;
}

export interface SiteData {
    seasonUid: string;
    currentSeasonNumber: number;
    inSeason: boolean;
    lastUpdated: string;
    fantasySessionUid: string;
}

export interface VotingSession {
    startDate: string;
    endDate: string;
    seasonUid: string;
    uid: string;
    status: 'open' | 'closed';
    playersWhoVoted: string[];
    playersWhoGotVotedOn: string[];
}

export interface FantasySession {
    startDate: string;
    endDate: string;
    seasonUid: string;
    uid: string;
    status: 'open' | 'closed';
    weekNumber: number;
    teamCreationStatus: 'open' | 'closed';
}

export interface FantasyTeam {
    teamName: string;
    ownerUid: string;
    uid: string;
    seasonUid: string;
    weeks: {
        fantasySessionUid: string;
        players: string[];
        weekNumber: number;
    }[];
}

// react components
export interface CustomAlertProps {
    open: boolean;
    severity: string;
    message: string;
    closeAlert: Function;
}

// server
export interface GetAllPlayersServerResponse {
    success: boolean;
    players: Player[];
}

export interface GetAllUsersServerResponse {
    success: boolean;
    users: AuthenticatedUser[];
}

export interface GetAllTeamsServerResponse {
    success: boolean;
    teams: Team[];
}

export interface GetAllNewsServerResponse {
    success: boolean;
    news: News[];
}

export interface GetAllSeasonsServerResponse {
    success: boolean;
    seasons: Season[];
}

export interface GetAllMatchDaysServerResponse {
    success: boolean;
    matchDays: MatchDay[];
}

export interface GetAllVotingSessionServerResponse {
    success: boolean;
    votingSessions: VotingSession[];
}

export interface GetAllFantasyTeamsServerResponse {
    success: boolean;
    fantasyTeams: FantasyTeam[];
}

export interface GetAllFantasySessionsServerResponse {
    success: boolean;
    fantasySessions: FantasySession[];
}

export interface GetSiteDataServerResponse {
    success: boolean;
    siteData: SiteData;
}

export interface GetPlayerByUidServerResponse {
    success: boolean;
    player: Player;
}

export interface GetTeamByUidServerResponse {
    success: boolean;
    team: Team;
}

export interface GetNewsByUidServerResponse {
    success: boolean;
    news: News;
}

export interface GetSeasonByUidServerResponse {
    success: boolean;
    season: Season;
}

export interface GetMatchDayByUidServerResponse {
    success: boolean;
    matchDay: Season;
}

export interface AddPlayerServerResponse {
    success: boolean;
    message: string;
    uid: string;
}

export interface AddTeamServerResponse {
    success: boolean;
    message: string;
    uid: string;
}

export interface AddNewsServerResponse {
    success: boolean;
    message: string;
    uid: string;
}

export interface AddSeasonServerResponse {
    success: boolean;
    message: string;
    uid: string;
}

export interface AddMatchDayServerResponse {
    success: boolean;
    message: string;
    uid: string;
}

export interface PostVoteServerResponse {
    success: boolean;
    message: string;
}

export interface PostVotingSessionServerResponse {
    success: boolean;
    message: string;
    uid: string;
}

export interface AddFantasyTeamServerResponse {
    success: boolean;
    message: string;
    uid: string;
}

export interface AddFantasySessionServerResponse {
    success: boolean;
    message: string;
    uid: string;
}

export interface UpdatePlayerServerResponse {
    success: boolean;
    message: string;
}

export interface UpdateUserServerResponse {
    success: boolean;
    message: string;
}

export interface UpdateTeamServerResponse {
    success: boolean;
    message: string;
}

export interface UpdateNewsServerResponse {
    success: boolean;
    message: string;
}

export interface UpdateSeasonServerResponse {
    success: boolean;
    message: string;
}

export interface UpdateSiteDataServerResponse {
    success: boolean;
    message: string;
}

export interface UpdateMatchDayServerResponse {
    success: boolean;
    message: string;
}

export interface UpdatePlayerStatsForMatchDayServerResponse {
    success: boolean;
    message: string;
}

export interface UpdateFantasyTeamWeekServerResponse {
    success: boolean;
    message: string;
}

export interface DeleteMatchDayServerResponse {
    success: boolean;
    message: string;
}
