import {
    FormmattedMatchDay,
    LeagueLeader,
    MatchDay,
    Player,
    PlayerLeaderBoard,
    Season,
    Standing,
    Team,
} from '../types';
import { startOfMonth, startOfYear, formatISO, parseISO, addYears, addMonths } from 'date-fns';

/**
 * Convert "2023-12-30T20:15:00.000Z" format to `${formattedDate} ${formattedTime}`.
 * @param { string } dateString - The Firebase timestamp.
 * @return { string } - The formatted date.
 */
export const formatDate = (dateString: string): string => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    const formattedDate = new Date(dateString).toLocaleDateString(undefined, options as any);
    const formattedTime = new Date(dateString).toLocaleTimeString(undefined, { hour: '2-digit', minute: '2-digit' });

    return `${formattedDate} @ ${formattedTime}`;
};

/**
 * Get total assists for specified season.
 * @param { Player[] } players - The players.
 * @param { seasonUid } seasonUid - The Season UID.
 * @return { number } - The total assists.
 */
export const getTotalAssists = (players: Player[], seasonUid?: string): number => {
    // If no season UID provided, get assists across all history
    if (!seasonUid) {
        return players.reduce((totalAssists, player) => {
            return (
                totalAssists +
                player.stats.reduce((total, stats) => {
                    return (
                        total +
                        stats.matchDays.reduce((assists, matchDay) => assists + parseInt(matchDay.assists as string), 0)
                    );
                }, 0)
            );
        }, 0);
    }

    // Calculate total assists for the specified season UID
    return players.reduce((totalAssists, player) => {
        const seasonStats = player.stats.find((stat) => stat.seasonUid === seasonUid);
        if (seasonStats) {
            return (
                totalAssists +
                seasonStats.matchDays.reduce((assists, matchDay) => assists + parseInt(matchDay.assists as string), 0)
            );
        }
        return totalAssists;
    }, 0);
};

/**
 * Get total assists for specified season.
 * @param { Player[] } players - The players.
 * @param { seasonUid } seasonUid - The Season UID.
 * @return { number } - The total blocks.
 */
export const getTotalBlocks = (players: Player[], seasonUid?: string): number => {
    if (!seasonUid) {
        return players.reduce((totalBlocks, player) => {
            return (
                totalBlocks +
                player.stats.reduce((total, stats) => {
                    return (
                        total +
                        stats.matchDays.reduce((blocks, matchDay) => blocks + parseInt(matchDay.blocks as string), 0)
                    );
                }, 0)
            );
        }, 0);
    }

    return players.reduce((totalBlocks, player) => {
        const seasonStats = player.stats.find((stat) => stat.seasonUid === seasonUid);
        if (seasonStats) {
            return (
                totalBlocks +
                seasonStats.matchDays.reduce((blocks, matchDay) => blocks + parseInt(matchDay.blocks as string), 0)
            );
        }
        return totalBlocks;
    }, 0);
};

/**
 * Get total assists for specified season.
 * @param { Player[] } players - The players.
 * @param { seasonUid } seasonUid - The Season UID.
 * @return { number } - The total nutmegs.
 */
export const getTotalNutmegs = (players: Player[], seasonUid?: string): number => {
    if (!seasonUid) {
        return players.reduce((totalNutmegs, player) => {
            return (
                totalNutmegs +
                player.stats.reduce((total, stats) => {
                    return (
                        total +
                        stats.matchDays.reduce((nutMegs, matchDay) => nutMegs + parseInt(matchDay.nutMegs as string), 0)
                    );
                }, 0)
            );
        }, 0);
    }

    return players.reduce((totalNutmegs, player) => {
        const seasonStats = player.stats.find((stat) => stat.seasonUid === seasonUid);
        if (seasonStats) {
            return (
                totalNutmegs +
                seasonStats.matchDays.reduce((nutMegs, matchDay) => nutMegs + parseInt(matchDay.nutMegs as string), 0)
            );
        }
        return totalNutmegs;
    }, 0);
};

/**
 * Transform matchDay data.
 * @param { MatchDay[] } matchDays - The matches.
 * @param { Team[] } teams - The teams.
 * @param { Season[] } seasons - The seasons.
 * @return { FormmattedMatchDay } - The fomatted matchDay.
 */
export const formatMatchDays = (matchDays: MatchDay[], teams: Team[], seasons: Season[]): FormmattedMatchDay[] => {
    return matchDays.map((matchDay) => {
        return {
            seasonNumber: seasons.find((season) => season.uid === matchDay.seasonUid)?.seasonNumber || 0,
            date: formatDate(matchDay.date),
            teamAName: teams.find((team) => team.uid === matchDay.teamAUid)?.name || 'not found',
            teamBName: teams.find((team) => team.uid === matchDay.teamBUid)?.name || 'not found',
            teamAGoals: parseInt(matchDay.teamAGoals),
            teamBGoals: parseInt(matchDay.teamBGoals),
        };
    });
};

export const sortPlayersByAttribute = (
    players: Player[],
    seasonUid: string,
    attribute: string
): { name: string; image: string; value: number; uid: string }[] => {
    // Filter players who have stats for the current season
    const playersWithStats = players.filter((player) => player.stats.some((stat) => stat.seasonUid === seasonUid));

    // Calculate total assists for each player
    const sortedPlayers = playersWithStats.map((player) => {
        const total = player.stats
            .filter((stat) => stat.seasonUid === seasonUid)
            .flatMap((stat) => stat.matchDays)
            .reduce(
                (total, matchDay) =>
                    total +
                    // @ts-ignore
                    (typeof matchDay[attribute] === 'string' ? parseInt(matchDay[attribute]) : matchDay[attribute]),
                0
            );
        return { name: player.name, image: player.image, value: total, uid: player.uid };
    });

    // Sort players by total assists in descending order
    sortedPlayers.sort((a, b) => b.value - a.value);

    return sortedPlayers;
};

interface TeamStanding {
    teamUid: string;
    teamName: string;
    points: number;
    played: number;
    won: number;
    drawn: number;
    lost: number;
    goalsFor: number;
    goalsAgainst: number;
    color: string;
}

export const calculateStandings = (matchDays: MatchDay[], teams: Team[]): Standing[] => {
    // Initialize an object to store standings for each team
    const teamStandingsMap: Map<string, TeamStanding> = new Map();

    // Populate team standings map with initial data for teams that are in match days
    teams.forEach((team) => {
        if (matchDays.some((matchDay) => matchDay.teamAUid === team.uid || matchDay.teamBUid === team.uid)) {
            teamStandingsMap.set(team.uid, {
                teamUid: team.uid,
                teamName: team.name,
                points: 0,
                played: 0,
                won: 0,
                drawn: 0,
                lost: 0,
                goalsFor: 0,
                goalsAgainst: 0,
                color: team.color,
            });
        }
    });

    // Iterate through match days to update standings for games that have already happened
    matchDays.forEach((matchDay) => {
        const currentDate = new Date();
        const matchDate = new Date(matchDay.date);

        // Check if the match day's date is before the current date
        if (matchDate < currentDate) {
            const teamA = teamStandingsMap.get(matchDay.teamAUid);
            const teamB = teamStandingsMap.get(matchDay.teamBUid);

            // Update goals for and against
            if (teamA && teamB) {
                teamA.goalsFor += parseInt(matchDay.teamAGoals);
                teamA.goalsAgainst += parseInt(matchDay.teamBGoals);
                teamB.goalsFor += parseInt(matchDay.teamBGoals);
                teamB.goalsAgainst += parseInt(matchDay.teamAGoals);

                // Update points, played, won, drawn, and lost
                if (matchDay.teamAGoals > matchDay.teamBGoals) {
                    teamA.points += 3;
                    teamA.won++;
                    teamB.lost++;
                } else if (matchDay.teamAGoals < matchDay.teamBGoals) {
                    teamB.points += 3;
                    teamB.won++;
                    teamA.lost++;
                } else {
                    teamA.points++;
                    teamB.points++;
                    teamA.drawn++;
                    teamB.drawn++;
                }
                teamA.played++;
                teamB.played++;
            }
        }
    });

    // Convert team standings map to array of standings
    const standings: Standing[] = Array.from(teamStandingsMap.values()).map((teamStanding) => {
        const goalDifference = teamStanding.goalsFor - teamStanding.goalsAgainst;
        return {
            position: 0,
            team: teamStanding.teamName,
            teamUid: teamStanding.teamUid,
            points: teamStanding.points,
            pld: teamStanding.played,
            w: teamStanding.won,
            d: teamStanding.drawn,
            l: teamStanding.lost,
            gf: teamStanding.goalsFor,
            ga: teamStanding.goalsAgainst,
            gd: goalDifference,
            teamColor: teamStanding.color,
        };
    });

    // Sort standings based on points, goal difference, and goals scored
    standings.sort((a, b) => {
        if (a.points !== b.points) {
            return b.points - a.points;
        } else if (a.gd !== b.gd) {
            return b.gd - a.gd;
        } else {
            return b.gf - a.gf;
        }
    });

    // Update positions in standings
    standings.forEach((standing, index) => {
        standing.position = index + 1;
    });

    return standings;
};

interface PlayerStats {
    goals: number;
    assists: number;
    blocks: number;
    nutMegs: number;
}

/**
 * Calculate points for a single player.
 * @param { PlayerStats } playerStats - The player statistics.
 * @return { number } - The total points for the player.
 */
export const calculatePlayerPoints = (playerStats: PlayerStats): number => {
    // Initialize variables to store points for each category
    const { goals, assists, nutMegs, blocks } = playerStats;

    // Calculate total points for the player based on the scoring system
    let points = goals * 1.5 + assists + nutMegs * 0.5 + blocks * 0.1;

    // Round points to one decimal place
    points = parseFloat(points.toFixed(1));

    // Return the total points for the player
    return points;
};

/**
 * Calculate Player Leaderboard.
 * @param { Player[] } players - The players.
 * @param { string } [seasonUid] - The season uid (optional).
 * @return { PlayerLeaderBoard[] } - The formatted leaderboard.
 */
export const calculatePlayerLeaderboard = (players: Player[], seasonUid?: string): PlayerLeaderBoard[] => {
    // Initialize an empty leaderboard array to store the results
    const leaderboard: PlayerLeaderBoard[] = [];

    // Iterate over each player
    players.forEach((player) => {
        // Initialize variables to store points for each category
        let points = 0;
        let goals = 0;
        let assists = 0;
        let blocks = 0;
        let nutMegs = 0;

        // Iterate over each season of the player
        player.stats.forEach((season) => {
            // Check if seasonUid is provided and matches the current season
            if (!seasonUid || seasonUid === season.seasonUid) {
                // Update points based on goals, assists, nutMegs, and blocks
                season.matchDays.forEach((matchDay) => {
                    goals += Number(matchDay.goals);
                    assists += Number(matchDay.assists);
                    blocks += Number(matchDay.blocks);
                    nutMegs += Number(matchDay.nutMegs);
                });
            }
        });

        // Calculate total points for the player based on the scoring system
        points = goals * 1.5 + assists + nutMegs * 0.5 + blocks * 0.1;
        points = parseFloat(points.toFixed(1));

        // Create a leaderboard entry for the player
        const playerLeaderboardEntry: PlayerLeaderBoard = {
            name: player.name,
            rank: 0, // Placeholder position, will be updated later
            position: player.position,
            points,
            goals,
            assists,
            blocks,
            nutMegs,
            image: player.image,
            country: player.country,
        };

        // Push the entry to the leaderboard array
        leaderboard.push(playerLeaderboardEntry);
    });

    // Sort the leaderboard based on points in descending order
    leaderboard.sort((a, b) => b.points - a.points);

    // Update positions in the leaderboard
    leaderboard.forEach((player, index) => {
        player.rank = index + 1;
    });

    // Return the sorted leaderboard
    return leaderboard;
};

/**
 * Calculate Player Leaderboard.
 * @param { Player[] } players - The players.
 * @param { string } seasonUid - The season uid.
 * @param { Team[] } teams - The season uid.
 * @return { PlayerLeaderBoard[] } - The fomatted matchDays.
 */
export const createLeagueLeadersTable = (
    players: Player[],
    seasonUid: string,
    attribute: string,
    teams: Team[]
): LeagueLeader[] => {
    // Filter players based on the provided season UID
    const filteredPlayers = players.filter((player) => {
        return player.stats.some((stat) => stat.seasonUid === seasonUid);
    });

    // Sort players based on the specified attribute in descending order
    filteredPlayers.sort((a, b) => {
        const attributeA = getAttributeValue(a, seasonUid, attribute);
        const attributeB = getAttributeValue(b, seasonUid, attribute);
        return attributeB - attributeA;
    });

    // Map the sorted players to LeagueLeader format
    const leagueLeaders: LeagueLeader[] = filteredPlayers.map((player, index) => {
        return {
            name: player.name,
            position: (index + 1).toString(),
            image: player.image,
            country: player.country,
            team: teams.find((team) => team.players.includes(player.uid)),
            value: getAttributeValue(player, seasonUid, attribute),
        };
    });

    return leagueLeaders;
};

// Helper function to get the attribute value from a player based on the provided attribute
const getAttributeValue = (player: Player, seasonUid: string, attribute: string): number => {
    let value = 0;
    player.stats.forEach((stat) => {
        if (stat.seasonUid === seasonUid) {
            // @ts-ignore
            value += Number(stat.matchDays.reduce((acc, curr) => acc + Number(curr[attribute]), 0));
        }
    });
    return value;
};

/**
 * Filter Teams and Players by Season. Will return teams and players in current season.
 * @param { MatchDay[] } matchDays - The match days.
 * @param { Team[] } teams - The Teams.
 * @param { Player[]} players - The Players.
 * @param { string } seasonUid - The season uid to filter by.
 * @return { teams: Team[]; players: Player[] } - The filtered teams and players.
 */
export const filterTeamsAndPlayersBySeason = (
    matchDays: MatchDay[],
    teams: Team[],
    players: Player[],
    seasonUid: string
): { teams: Team[]; players: Player[] } => {
    const teamUidsInSeason: string[] = Array.from(
        new Set(
            matchDays
                .filter((matchDay) => matchDay.seasonUid === seasonUid)
                .map((matchDay) => [matchDay.teamAUid, matchDay.teamBUid])
                .flat(1)
        )
    );

    const teamsInSeason: Team[] = teams.filter((team) => teamUidsInSeason.includes(team.uid));

    const playerUidsInSeason: string[] = teamsInSeason.flatMap((team) => team.players);

    const playersInSeason: Player[] = players.filter((player) => playerUidsInSeason.includes(player.uid));

    return { teams: teamsInSeason, players: playersInSeason };
};

export const sortPlayersByFIFAStatsOverall = (players: Player[]): Player[] => {
    // Sort the players array based on FIFAStats.overall
    players.sort((a, b) => {
        const overallA = a.FIFAStats.overall;
        const overallB = b.FIFAStats.overall;

        // If FIFAStats.overall is not defined for either player, keep their relative order
        if (overallA === undefined && overallB === undefined) {
            return 0;
        } else if (overallA === undefined) {
            return 1; // Put players with undefined overall at the end
        } else if (overallB === undefined) {
            return -1; // Put players with undefined overall at the end
        }

        // Sort by FIFAStats.overall in descending order
        return overallB - overallA;
    });

    return players;
};

export interface TeamWithWins {
    team: Team | null;
    wins: number;
}

/**
 * Determine the team with most "x"; "x" being something like: 'wins', 'losses', etc.
 * @param { Team[] } teams - The Teams.
 * @param { MatchDay[] } matchDays - The match days.
 * @param { string } attribute - The season uid to filter by.
 * @return { team: Team} - The team with most "x".
 */
export const calculateTeamWithMostWins = (teams: Team[], matchDays: MatchDay[], attribute: string): TeamWithWins => {
    if (matchDays.length === 0) return { team: null, wins: 0 };

    // Create a map to store the wins count for each team
    const winsCountMap: Map<string, number> = new Map();

    // Loop through match days to calculate wins count
    matchDays.forEach((matchDay) => {
        const teamA = teams.find((team) => team.uid === matchDay.teamAUid);
        const teamB = teams.find((team) => team.uid === matchDay.teamBUid);

        if (!teamA || !teamB) return;

        const teamAWins = parseInt(matchDay.teamAGoals) > parseInt(matchDay.teamBGoals);
        const teamBWins = parseInt(matchDay.teamBGoals) > parseInt(matchDay.teamAGoals);

        if (attribute === 'wins') {
            if (teamAWins) {
                winsCountMap.set(teamA.uid, (winsCountMap.get(teamA.uid) || 0) + 1);
            }
            if (teamBWins) {
                winsCountMap.set(teamB.uid, (winsCountMap.get(teamB.uid) || 0) + 1);
            }
        }
        // Add other attributes here if needed (e.g., losses, goals scored, etc.)
    });

    // Find the team with the most wins
    let teamWithMost: Team | null = null;
    let maxWins = 0;

    winsCountMap.forEach((wins, teamUid) => {
        if (wins > maxWins) {
            maxWins = wins;
            teamWithMost = teams.find((team) => team.uid === teamUid) || null;
        }
    });

    return { team: teamWithMost, wins: maxWins };
};

/**
 * Determine the contrast color based on the give hex value.
 * @param { string } hexColor - The hex color value.
 * @return { string } The contrast color.
 */
export const getContrastColor = (hexColor: string): string => {
    // Remove '#' if present
    hexColor = hexColor.replace('#', '');

    // Convert hex to RGB
    const r = parseInt(hexColor.substring(0, 2), 16);
    const g = parseInt(hexColor.substring(2, 4), 16);
    const b = parseInt(hexColor.substring(4, 6), 16);

    // Calculate luminance
    const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;

    // Decide contrast color based on luminance
    return luminance > 0.5 ? 'black' : 'white';
};

const isPastDate = (date: string) => new Date(date) < new Date();

// Function to calculate bar chart data
export const calculateD3BarChartDataWinsLossesDraws = (
    matchDays: MatchDay[],
    selectedSeason: Season | 'all',
    teamUid: string
): { name: string; value: number }[] => {
    // Filter matchDays based on selected season and date
    const filteredMatchDays = matchDays.filter((matchDay) => {
        const isInSeason = selectedSeason === 'all' || matchDay.seasonUid === selectedSeason.uid;
        const isForTeam = matchDay.teamAUid === teamUid || matchDay.teamBUid === teamUid;
        const isInPast = isPastDate(matchDay.date);
        return isInSeason && isForTeam && isInPast;
    });

    // Initialize counters for wins, losses, and draws
    let wins = 0;
    let losses = 0;
    let draws = 0;

    // Count results
    filteredMatchDays.forEach((matchDay) => {
        const isTeamA = matchDay.teamAUid === teamUid;
        const teamAGoals = parseInt(matchDay.teamAGoals, 10);
        const teamBGoals = parseInt(matchDay.teamBGoals, 10);

        if (isTeamA) {
            if (teamAGoals > teamBGoals) wins++;
            else if (teamAGoals < teamBGoals) losses++;
            else draws++;
        } else {
            if (teamBGoals > teamAGoals) wins++;
            else if (teamBGoals < teamAGoals) losses++;
            else draws++;
        }
    });

    // Return data formatted for the bar chart
    return [
        { name: 'Wins', value: wins },
        { name: 'Losses', value: losses },
        { name: 'Draws', value: draws },
    ];
};

interface AggregatedData {
    date: string; // ISO 8601 format date
    wins: number;
    draws: number;
    losses: number;
}

type TimeGranularity = 'year' | 'month';

export const transformMatchDaysToMultiLineData = (
    matchDays: MatchDay[],
    selectedSeason: Season | 'all', // Added parameter for selected season
    teamUid: string,
    granularity: TimeGranularity
): AggregatedData[] => {
    // Initialize data structure to hold aggregated results
    const aggregatedResults: Record<string, { wins: number; draws: number; losses: number }> = {};

    // Filter matchDays based on selected season
    const filteredMatchDays =
        selectedSeason === 'all'
            ? matchDays
            : matchDays.filter((matchDay) => matchDay.seasonUid === selectedSeason.uid);

    filteredMatchDays.forEach((matchDay) => {
        const isTeamA = matchDay.teamAUid === teamUid;
        const teamAGoals = parseInt(matchDay.teamAGoals, 10);
        const teamBGoals = parseInt(matchDay.teamBGoals, 10);

        const matchDate = parseISO(matchDay.date);
        let aggregatedDate: string;

        switch (granularity) {
            case 'year':
                aggregatedDate = formatISO(startOfYear(matchDate), { representation: 'date' }) + 'T00:00:00.000Z';
                break;
            case 'month':
                aggregatedDate = formatISO(startOfMonth(matchDate), { representation: 'date' }) + 'T00:00:00.000Z';
                break;
            default:
                throw new Error('Invalid granularity');
        }

        if (!aggregatedResults[aggregatedDate]) {
            aggregatedResults[aggregatedDate] = { wins: 0, draws: 0, losses: 0 };
        }

        if (isTeamA) {
            if (teamAGoals > teamBGoals) aggregatedResults[aggregatedDate].wins++;
            else if (teamAGoals < teamBGoals) aggregatedResults[aggregatedDate].losses++;
            else aggregatedResults[aggregatedDate].draws++;
        } else {
            if (teamBGoals > teamAGoals) aggregatedResults[aggregatedDate].wins++;
            else if (teamBGoals < teamAGoals) aggregatedResults[aggregatedDate].losses++;
            else aggregatedResults[aggregatedDate].draws++;
        }
    });

    return Object.entries(aggregatedResults).map(([date, stats]) => ({
        date,
        ...stats,
    }));
};

export const transformPlayerStatsToMultiLineData = (
    matchDays: MatchDay[],
    player: Player,
    selectedSeason: Season | 'all',
    granularity: 'year' | 'month'
): { date: string; goals: number; nutMegs: number; assists: number; blocks: number }[] => {
    const aggregatedResults: Record<string, { goals: number; nutMegs: number; assists: number; blocks: number }> = {};

    // Filter player's stats based on selected season
    const filteredPlayerStats = player.stats.filter(
        (stat) => selectedSeason === 'all' || stat.seasonUid === selectedSeason.uid
    );

    let earliestDate: Date | null = null;
    let latestDate: Date | null = null;

    filteredPlayerStats.forEach((seasonStats) => {
        seasonStats.matchDays.forEach((playerMatchStats) => {
            const matchDay = matchDays.find((md) => md.uid === playerMatchStats.matchId);
            if (matchDay) {
                const matchDate = parseISO(matchDay.date);
                if (!earliestDate || matchDate < earliestDate) earliestDate = matchDate;
                if (!latestDate || matchDate > latestDate) latestDate = matchDate;

                let aggregatedDate: string;

                switch (granularity) {
                    case 'year':
                        aggregatedDate =
                            formatISO(startOfYear(matchDate), { representation: 'date' }) + 'T00:00:00.000Z';
                        break;
                    case 'month':
                        aggregatedDate =
                            formatISO(startOfMonth(matchDate), { representation: 'date' }) + 'T00:00:00.000Z';
                        break;
                    default:
                        throw new Error('Invalid granularity');
                }

                if (!aggregatedResults[aggregatedDate]) {
                    aggregatedResults[aggregatedDate] = { goals: 0, nutMegs: 0, assists: 0, blocks: 0 };
                }

                aggregatedResults[aggregatedDate].goals += Number(playerMatchStats.goals);
                aggregatedResults[aggregatedDate].nutMegs += Number(playerMatchStats.nutMegs);
                aggregatedResults[aggregatedDate].assists += Number(playerMatchStats.assists);
                aggregatedResults[aggregatedDate].blocks += Number(playerMatchStats.blocks);
            }
        });
    });

    if (earliestDate && latestDate) {
        let currentDate = startOfYear(earliestDate);
        let incrementFunc = granularity === 'year' ? addYears : addMonths;

        while (currentDate <= latestDate) {
            const aggregatedDate =
                granularity === 'year'
                    ? formatISO(startOfYear(currentDate), { representation: 'date' }) + 'T00:00:00.000Z'
                    : formatISO(startOfMonth(currentDate), { representation: 'date' }) + 'T00:00:00.000Z';

            if (!aggregatedResults[aggregatedDate]) {
                aggregatedResults[aggregatedDate] = { goals: 0, nutMegs: 0, assists: 0, blocks: 0 };
            }

            currentDate = incrementFunc(currentDate, 1);
        }
    }

    return Object.entries(aggregatedResults)
        .sort(([dateA], [dateB]) => new Date(dateA).getTime() - new Date(dateB).getTime())
        .map(([date, stats]) => ({
            date,
            ...stats,
        }));
};

/**
 * Determine if a match has occured.
 * @param { string } date - the match date and time in string format: 2024-08-31T22:54:00.000Z.
 * @return { boolean } Yes or No.
 */
export const gameHasOccured = (date: string): boolean => {
    return new Date().getTime() > new Date(date).getTime();
};

/**
 * Calculate the points for a player in a given timeframe.
 * @param { string } startDate - the timeframe start date and time in string format: 2024-08-31T22:54:00.000Z.
 * @param { string } endDate - the timeframe end date and time in string format: 2024-08-31T22:54:00.000Z.
 * @param { string } seasonUid - The current season UID.
 * @param { MatchDay[] } matchDays - the Match days.
 * @param { Player } player - The player to calculate points for.
 * @return { number } The points.
 */
export const calculatePointsWithinTimeframe = (
    startDate: string,
    endDate: string,
    seasonUid: string,
    matchDays: MatchDay[],
    player: Player | undefined
): number => {
    if (!player) return 0;

    let totalPoints = 0;

    // Convert startDate and endDate to Date objects
    const start = new Date(startDate);
    const end = new Date(endDate);

    // Get player's stats for the given season
    const playerSeasonStats = player.stats.find((stats) => stats.seasonUid === seasonUid);
    if (!playerSeasonStats) {
        // No stats available for this season
        return totalPoints;
    }

    // Filter match days to find those within the timeframe
    const matchDaysInTimeframe = matchDays.filter((matchDay) => {
        const matchDate = new Date(matchDay.date);
        return matchDate >= start && matchDate <= end;
    });

    // Iterate over match days to calculate points
    matchDaysInTimeframe.forEach((matchDay) => {
        // Find the player's performance in this matchDay
        const matchStats = playerSeasonStats.matchDays.find((stats) => stats.matchId === matchDay.uid);
        if (matchStats) {
            // Calculate points for this match
            const playerStats = {
                goals: Number(matchStats.goals),
                assists: Number(matchStats.assists),
                nutMegs: Number(matchStats.nutMegs),
                blocks: Number(matchStats.blocks),
            };

            totalPoints += calculatePlayerPoints(playerStats);
        }
    });

    return totalPoints;
};
