import React, { useEffect, useRef, useState } from 'react';
import * as d3 from 'd3';
import { d3GraphConfig, universalColorScheme, useResizeObserver } from '../../utils/d3'; // Adjust the import path accordingly
import { axisStyle } from '../../styles/d3';

interface BarChartData {
    name: string;
    value: number;
}

interface BarChartProps {
    data: BarChartData[];
    yAxisTickFormat?: (value: number) => string;
    barColor?: (name: string) => string;
}

const D3BarChart: React.FC<BarChartProps> = ({ data, yAxisTickFormat, barColor }) => {
    const svgRef = useRef<SVGSVGElement>(null);
    const wrapperRef = useRef<HTMLDivElement>(null);
    const dimensions: any = useResizeObserver(wrapperRef);
    const [tooltipText, setTooltipText] = useState<string | null>(null);
    const [tooltipPosition, setTooltipPosition] = useState<{ x: number; y: number }>({ x: 0, y: 0 });
    const tooltipOffset = { x: 12, y: -3 };
    const [tooltipVisible, setTooltipVisible] = useState<boolean>(false);

    useEffect(() => {
        if (!data || !svgRef.current || !dimensions) return;

        const width = dimensions.width;
        const height = dimensions.height;
        const margin = { top: 20, right: 20, bottom: 50, left: 40 };

        const svg = d3.select(svgRef.current);

        // Clear existing content
        svg.selectAll('*').remove();

        // Example bar chart code (replace this with your specific bar chart implementation)
        const xScale = d3
            .scaleBand()
            .domain(data.map((d) => d.name))
            .range([margin.left, width - margin.right])
            .padding(0.1);

        const yMax = d3.max(data, (d) => d.value) || 0;

        const yScale = d3
            .scaleLinear()
            .domain([0, yMax])
            .range([height - margin.bottom, margin.top]);

        svg.attr('width', width).attr('height', height); // Update SVG dimensions

        // Initial rendering of X and Y Axes
        // @ts-ignore
        svg.append('g')
            .attr('class', 'x-axis')
            .attr('transform', `translate(0,${height - margin.bottom})`)
            .style('color', axisStyle.textColor)
            .call(d3.axisBottom(xScale).tickSizeOuter(0).tickSize(0));

        svg.append('g')
            .attr('class', 'y-axis')
            .attr('transform', `translate(${margin.left},0)`)
            .style('color', axisStyle.textColor)
            // @ts-ignore
            .call(
                d3
                    .axisLeft(yScale)
                    .ticks(yMax < 10 ? yMax : 10)
                    .tickSizeOuter(0)
                    .tickSize(0)
                    // @ts-ignore
                    .tickFormat(yAxisTickFormat || d3.format('.0f'))
            );

        // Apply styles to axes
        svg.selectAll('.x-axis text')
            .style('font-family', axisStyle.fontFamily)
            .style('font-size', axisStyle.fontSize)
            .style('color', axisStyle.textColor)
            .attr('transform', 'rotate(30)')
            .attr('text-anchor', 'start');

        svg.selectAll('.y-axis text')
            .style('font-family', axisStyle.fontFamily)
            .style('font-size', axisStyle.fontSize)
            .style('color', axisStyle.textColor);

        svg.selectAll('.x-axis .tick line, .y-axis .tick line')
            .style('stroke', axisStyle.textColor) // Change the tick line color
            .style('stroke-width', 1);

        svg.select('.y-axis')
            .selectAll('.tick')
            .append('line')
            .attr('class', 'horizontal-line')
            .attr('x1', 0)
            .attr('x2', width)
            .style('stroke', axisStyle.textColor)
            .style('stroke', '#eee')
            .style('stroke-width', 1);

        // Define a color scale
        const colorScale = barColor || d3.scaleOrdinal(universalColorScheme);

        // Transition for Bars
        svg.selectAll('.bar')
            .data(data)
            .enter()
            .append('rect')
            .attr('class', 'bar')
            .attr('x', (d) => xScale(d.name) || 0)
            .attr('y', height - margin.bottom)
            .attr('width', xScale.bandwidth())
            .attr('height', 0)
            .attr('fill', (d) => colorScale(d.name))
            .transition()
            .duration(d3GraphConfig.transitionDuration)
            .attr('y', (d) => yScale(d.value))
            .attr('height', (d) => height - margin.bottom - yScale(d.value));

        // Tooltip logic
        svg.selectAll('.bar')
            .style('font-size', '12px')
            .on('mousemove', function (event, d: any) {
                const [cursorX, cursorY] = d3.pointer(event);
                setTooltipPosition({ x: cursorX, y: cursorY });
                setTooltipText(`${d.name}: ${d.value}`);
                setTooltipVisible(true);
            })
            .on('mouseleave', function () {
                setTooltipVisible(false);
            });
    }, [data, dimensions, yAxisTickFormat, barColor]);

    return (
        <div
            ref={wrapperRef}
            style={{
                marginBottom: '2rem',
                maxHeight: d3GraphConfig.maxHeight, // Adjust max height as needed
                minHeight: d3GraphConfig.minHeight, // Adjust min height as needed
                position: 'relative',
            }}
        >
            <svg ref={svgRef}></svg>
            {tooltipVisible && (
                <div
                    style={{
                        position: 'absolute',
                        fontSize: d3GraphConfig.tooltipFontSize,
                        top: tooltipPosition.y + tooltipOffset.y,
                        left: tooltipPosition.x + tooltipOffset.x,
                        background: 'rgba(255, 255, 255, 0.9)',
                        padding: '0.5rem',
                        borderRadius: '4px',
                        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                    }}
                >
                    {tooltipText}
                </div>
            )}
        </div>
    );
};

export default D3BarChart;
