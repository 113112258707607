import React from 'react';
import {
    AddMatchDayServerResponse,
    CustomAlertProps,
    GetAllMatchDaysServerResponse,
    MatchDay,
    UpdateMatchDayServerResponse,
} from '../../../types';
import { Container, Grid, Typography } from '@mui/material';
import { initialAlertState } from '../../../utils/consts';
import { LoadingOverlay } from '../../../components/LoadingOverlay';
import { CustomAlert } from '../../../components/CustomAlert';
import { useAppDispatch, useAppSelector } from '../../../store/store';
import {
    addMatchDay as addMatchDayAsync,
    deleteMatchay as deleteMatchDayAsync,
    updateMatchDay as updateMatchDayAsync,
} from '../../../utils/actions';
import { LoadingButton } from '../../../components/LoadingButton';
import { SeasonSchedulemMaterialTable } from '../../../components/SeasonScheduleMaterialTable';

export const Schedule = () => {
    const dispatch = useAppDispatch();
    const teams = useAppSelector((state) => state.team.teams);
    const matchDays = useAppSelector((state) => state.matchDay.matchDays);
    const siteData = useAppSelector((state) => state.siteData.siteData);

    const [customAlertState, setCustomAlertState] = React.useState<CustomAlertProps>({
        ...initialAlertState,
        closeAlert: () => handleCloseAlert(),
    });
    const [matches, setMatches] = React.useState<MatchDay[]>([]);
    const [currentSeasonInitialMatchDays, setCurrentSeasonInitialMatchDays] = React.useState<MatchDay[]>([]);
    const [loading, setLoading] = React.useState<boolean>(false);
    const [addButtonIsProcessing, setAddButtonIsProcessing] = React.useState<boolean>(false);
    const [createMatchesButtonDisabled, setCreatedMatchesButtonDisabled] = React.useState<boolean>(true);

    React.useEffect(() => {
        if (teams) {
            setLoading(false);
        }
    }, [teams]);

    React.useEffect(() => {
        if (matchDays && siteData) {
            const currentSeasonMatchDays: MatchDay[] = matchDays.filter(
                (matchDay) => matchDay.seasonUid === siteData.seasonUid
            );
            setMatches(currentSeasonMatchDays);
            setCurrentSeasonInitialMatchDays(currentSeasonMatchDays);
        }
    }, [matchDays, siteData]);

    React.useEffect(() => {
        if (matches.some((match) => match.uid === '')) {
            setCreatedMatchesButtonDisabled(false);
        } else setCreatedMatchesButtonDisabled(true);
    }, [matches]);

    const handleCloseAlert = () => {
        setCustomAlertState((prev) => {
            prev = { ...prev };
            prev.open = false;
            return prev;
        });
    };

    const handleUpdateMatch = async (matchData: MatchDay) => {
        try {
            const completeMatchData: MatchDay = { ...matchData, seasonUid: siteData?.seasonUid || '' };
            const action = await dispatch(
                updateMatchDayAsync({ matchDayId: matchData.uid, matchDay: completeMatchData })
            );
            const data: UpdateMatchDayServerResponse = action.payload as UpdateMatchDayServerResponse;
            if (data.success) {
                setCustomAlertState((prev) => {
                    prev = { ...prev };
                    prev.open = true;
                    prev.message = data.message;
                    prev.severity = 'success';
                    return prev;
                });
            }
        } catch (error: any) {
            console.error('Error updating matchDay:', error.message);
            setCustomAlertState((prev) => {
                prev = { ...prev };
                prev.open = true;
                prev.message = error.message;
                prev.severity = 'error';
                return prev;
            });
        }
        return true;
    };

    const handleDeleteMatch = async (matchId: string) => {
        try {
            const action = await dispatch(deleteMatchDayAsync(matchId));
            const data: GetAllMatchDaysServerResponse = action.payload as GetAllMatchDaysServerResponse;
            if (data.success) {
                setCustomAlertState((prev) => {
                    prev = { ...prev };
                    prev.open = true;
                    prev.message = 'Succesfully deleted match day.';
                    prev.severity = 'success';
                    return prev;
                });
            }
        } catch (error: any) {
            console.error('Error deleting matchday:', error.message);
            setCustomAlertState((prev) => {
                prev = { ...prev };
                prev.open = true;
                prev.message = error.message;
                prev.severity = 'error';
                return prev;
            });
        }
        return true;
    };

    const handleAddMatches = async () => {
        const matchesToCreate: MatchDay[] = matches
            .filter((match) => match.uid === '')
            .map((match) => ({ ...match, seasonUid: siteData?.seasonUid || '' }));
        try {
            setAddButtonIsProcessing(true);
            const addMatchPromises = matchesToCreate.map(async (match) => {
                const action = await dispatch(addMatchDayAsync(match));
                const data: AddMatchDayServerResponse = action.payload as AddMatchDayServerResponse;
                if (data.success) {
                    setCustomAlertState((prev) => ({
                        ...prev,
                        open: true,
                        message: 'Successfully added match day.',
                        severity: 'success',
                    }));
                } else {
                    setCustomAlertState((prev) => ({
                        ...prev,
                        open: true,
                        message: 'Failed to add match day.',
                        severity: 'error',
                    }));
                }
            });
            // Wait for all add match promises to resolve
            await Promise.all(addMatchPromises);
        } catch (error: any) {
            console.error('Error adding match days:', error.message);
            setCustomAlertState((prev) => ({
                ...prev,
                open: true,
                message: error.message,
                severity: 'error',
            }));
        } finally {
            setAddButtonIsProcessing(false);
        }
    };

    return (
        <Container maxWidth='xl'>
            {loading ? (
                <LoadingOverlay message='Loading matches.' />
            ) : (
                <>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Typography variant='h4'>Matches</Typography>
                            {siteData?.inSeason ? (
                                <SeasonSchedulemMaterialTable
                                    teams={teams || []}
                                    teamsInSeason={teams?.map((team) => team.uid) || []}
                                    updateSeason={(matches) => {
                                        setMatches(matches);
                                    }}
                                    initialMatchDays={currentSeasonInitialMatchDays || []}
                                    deleteRowFromBackend={(matchId: string) => handleDeleteMatch(matchId)}
                                    updateRowFromBackend={(matchData: MatchDay) => handleUpdateMatch(matchData)}
                                />
                            ) : (
                                <Typography variant='caption'>Not currently in season.</Typography>
                            )}
                        </Grid>
                        <Grid item xs={12}>
                            <LoadingButton
                                disabled={createMatchesButtonDisabled}
                                isProcessing={addButtonIsProcessing}
                                type=''
                                text='Save Changes'
                                onClick={() => handleAddMatches()}
                            />
                        </Grid>
                    </Grid>
                    <CustomAlert {...customAlertState} />
                </>
            )}
        </Container>
    );
};
