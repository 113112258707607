import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Team } from '../../types';

interface TeamsState {
    teams: null | Team[];
}

const initialState: TeamsState = {
    teams: null,
};

export const TeamSlice = createSlice({
    name: 'team',
    initialState,
    reducers: {
        setTeams: (state, action: PayloadAction<Team[]>) => {
            state.teams = action.payload;
        },
    },
});

export default TeamSlice.reducer;
export const { setTeams } = TeamSlice.actions;
