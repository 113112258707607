import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';
import { universalColorScheme } from '../../utils/d3';

interface LegendItem {
    name: string;
    value: number;
}

interface D3LegendProps {
    data: LegendItem[];
}

const D3Legend: React.FC<D3LegendProps> = ({ data }) => {
    const svgRef = useRef<SVGSVGElement>(null);

    useEffect(() => {
        if (!svgRef.current || data.length === 0) return;

        const itemHeight = 30;
        const margin = 5;
        const total = data.reduce((acc, item) => acc + item.value, 0);

        const colorScale: any = d3
            .scaleOrdinal<string>()
            .domain(data.map((item) => item.name))
            .range(universalColorScheme);

        const svg = d3.select(svgRef.current);

        // Remove existing legend items
        svg.selectAll('*').remove();

        // Create new legend items
        const legendItems = svg
            .selectAll('g')
            .data(data)
            .enter()
            .append('g')
            .attr('transform', (d, i) => `translate(0, ${i * (itemHeight + margin)})`)
            .style('opacity', 0); // Set initial opacity to 0

        legendItems
            .transition() // Add transition
            .duration(1000) // Set the duration of the transition in milliseconds
            .style('opacity', 1); // Transition opacity to 1

        legendItems
            .append('rect')
            .attr('width', 10)
            .attr('height', 10)
            .style('fill', (d) => colorScale(d.name));

        legendItems
            .append('text')
            .attr('x', 20)
            .attr('y', 10)
            .style('text-anchor', 'start')
            .style('font-size', '14px')
            .text((d) => `${d.name} (${d.value}) - ${((d.value / total) * 100).toFixed(2)}%`);
    }, [data]);

    return <svg ref={svgRef} className='legend' width={'100%'} />;
};

export default D3Legend;
