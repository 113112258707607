import React from 'react';
import { Typography, Grid, Divider, Button, CircularProgress } from '@mui/material';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { VoteData } from '../types';
import nullAvatar from '../assets/null_avatar.png';

interface VoteCardProps {
    uid: string;
    name: string;
    email: string;
    image: string;
    teamName: string;
    teamImage: string;
    disabledVoteButtons: boolean;
    handleVoteCardChange: Function;
}
type VoteDataAttribute = keyof VoteData;

const voteAttributes: string[] = ['PAC', 'SHO', 'PAS', 'DRI', 'DEF', 'PHY'];
const initialVoteData: VoteData = {
    pace: '',
    shot: '',
    pass: '',
    dribbling: '',
    defense: '',
    physical: '',
    playerVotedOnUid: '',
};
const voteAttributesMap: { [key: string]: keyof VoteData } = {
    PAC: 'pace',
    SHO: 'shot',
    PAS: 'pass',
    DRI: 'dribbling',
    DEF: 'defense',
    PHY: 'physical',
};

const VotingIndicator = ({ hasVote }: { hasVote: boolean }) => {
    return (
        <CircularProgress
            variant='determinate'
            value={100}
            size={10}
            thickness={5}
            style={{
                color: hasVote ? 'green' : '#ddd',
                borderRadius: '50%',
                background: hasVote ? 'green' : '#ddd',
            }}
        />
    );
};

export const VoteCard = (props: VoteCardProps): JSX.Element => {
    const { uid, handleVoteCardChange } = props;
    const [voteData, setVoteData] = React.useState<VoteData>(initialVoteData);
    const [hasVote, setHasVote] = React.useState<boolean>(false);

    React.useEffect(() => {
        handleVoteCardChange(voteData, uid);
        if (Object.values(voteData).filter((data) => data !== '').length > 0) {
            setHasVote(true);
        } else setHasVote(false);
    }, [voteData, uid, handleVoteCardChange]);

    const handleVoteChange = (attribute: VoteDataAttribute, e: React.ChangeEvent<HTMLInputElement>) => {
        setVoteData((prev) => {
            prev = { ...prev };
            prev[attribute] = e.target.value;
            return prev;
        });
    };

    const handleClearClicked = () => {
        setVoteData(initialVoteData);
    };

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography variant='h6' style={{ display: 'flex', gap: '12px', alignItems: 'center' }}>
                        <img alt={props.name} height={40} src={props.image || nullAvatar} />
                        <b>{props.name}</b>
                        <img alt={props.teamName} height={40} src={props.teamImage} />
                    </Typography>

                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <VotingIndicator hasVote={hasVote} />
                    </div>
                </div>
            </Grid>
            <Grid item xs={12}>
                <Divider />
            </Grid>
            {voteAttributes.map((attribute, index: number) => {
                return (
                    <Grid key={index} item xs={12} style={{ display: 'flex', alignItems: 'center' }}>
                        <FormControl style={{ width: '100%' }}>
                            <RadioGroup
                                style={{
                                    justifyContent: 'space-around',
                                    flexWrap: 'nowrap',
                                    alignItems: 'center',
                                }}
                                row
                                aria-labelledby='demo-row-radio-buttons-group-label'
                                name='row-radio-buttons-group'
                                value={voteData[voteAttributesMap[attribute as VoteDataAttribute]]}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                    handleVoteChange(voteAttributesMap[attribute as VoteDataAttribute], e)
                                }
                            >
                                <FormControlLabel
                                    value='-1'
                                    control={<Radio disabled={props.disabledVoteButtons} />}
                                    label='-1'
                                />
                                <Typography variant='overline'>
                                    <b>{attribute}</b>
                                </Typography>
                                <FormControlLabel
                                    value='+1'
                                    control={<Radio disabled={props.disabledVoteButtons} />}
                                    label='+1'
                                />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                );
            })}
            <Grid item xs={12}>
                <Button fullWidth variant='outlined' color='info' onClick={handleClearClicked}>
                    Clear
                </Button>
            </Grid>
        </Grid>
    );
};
