import React from 'react';
import { Button, Card, CardContent, Container, Grid, Typography, TextField } from '@mui/material';
import { LoadingOverlay } from '../../../components/LoadingOverlay';
import { useAppSelector } from '../../../store/store';
import { useNavigate } from 'react-router-dom';
import { FIFACard } from '../../../components/FIFACard';
import { Player } from '../../../types';
import { sortPlayersByFIFAStatsOverall } from '../../../utils/helpers';

export const PublicPlayers = (): JSX.Element => {
    const navigate = useNavigate();
    const [loading, setLoading] = React.useState(true);
    const [sortedAndFilteredPlayers, setSortedAndFilteredPlayers] = React.useState<Player[]>([]);
    const [searchValue, setSearchValue] = React.useState<string>('');

    const teams = useAppSelector((state) => state.team.teams);
    const players = useAppSelector((state) => state.player.players);

    // const addTeamUidToPlayers = (players: Player[], teams: Team[]): Player[] => {
    //     return players.map((player) => {
    //         return {
    //             ...player,
    //             teamUid: teams.find((team) => team.players.includes(player.uid))?.uid || 'undrafted',
    //         };
    //     });
    // };

    React.useEffect(() => {
        if (teams && players) {
            setLoading(false);
            const tempSortedAnfFilteredPlayers: Player[] = sortPlayersByFIFAStatsOverall([...players]);

            setSortedAndFilteredPlayers(tempSortedAnfFilteredPlayers);
        } else setLoading(true);
    }, [teams, players]);

    React.useEffect(() => {
        if (players) {
            if (searchValue) {
                const tempSortedAnfFilteredPlayers: Player[] = sortPlayersByFIFAStatsOverall([
                    ...players.filter((player) =>
                        player.name.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase())
                    ),
                ]);
                setSortedAndFilteredPlayers(tempSortedAnfFilteredPlayers);
            } else if (searchValue === '') {
                const tempSortedAnfFilteredPlayers: Player[] = sortPlayersByFIFAStatsOverall([...players]);
                setSortedAndFilteredPlayers(tempSortedAnfFilteredPlayers);
            }
        }
    }, [searchValue, players]);

    return (
        <Container maxWidth='xl'>
            {loading ? (
                <LoadingOverlay message='Loading players.' />
            ) : (
                <>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <TextField
                                value={searchValue}
                                onChange={(e: any) => setSearchValue(e.target.value)}
                                fullWidth
                                label='Search Players'
                                variant='outlined'
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant='h4'>Players</Typography>
                        </Grid>
                        <Grid container spacing={1} item xs={12}>
                            {sortedAndFilteredPlayers?.map((player, index: number) => {
                                return (
                                    <Grid key={index} item xs={12} sm={6} md={4} lg={3} xl={2}>
                                        <Card variant='outlined'>
                                            <CardContent>
                                                <Grid container spacing={1}>
                                                    <Grid item xs={12}>
                                                        <FIFACard
                                                            player={player}
                                                            teamImage={
                                                                teams?.find((team) => team.players.includes(player.uid))
                                                                    ?.image
                                                            }
                                                            teamName={
                                                                teams?.find((team) => team.players.includes(player.uid))
                                                                    ?.name
                                                            }
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Button
                                                            onClick={() => navigate(`/players/${player.uid}`)}
                                                            fullWidth
                                                            variant='outlined'
                                                        >
                                                            Details
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                );
                            })}
                        </Grid>
                    </Grid>
                </>
            )}
        </Container>
    );
};
