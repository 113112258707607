import React from 'react';
import {
    Container,
    Divider,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    Typography,
    TextField,
    Card,
    CardContent,
} from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../../store/store';
import { CustomAlert } from '../../../components/CustomAlert';
import {
    CustomAlertProps,
    FantasySession,
    FantasyTeam,
    AddFantasySessionServerResponse,
    Player,
    UpdateFantasyTeamWeekServerResponse,
} from '../../../types';
import positions from '../../../data/positions.json';
import { UserAuth } from '../../../context/AuthContext';
import { LoadingButton } from '../../../components/LoadingButton';
import {
    addFantasyTeam as addFantasyTeamAsync,
    updateFantasyTeamWeek as updateFantasyTeamWeekAsync,
} from '../../../utils/actions';
import { initialNewFantasyTeamState } from '../../../utils/consts';
import { LoadingOverlay } from '../../../components/LoadingOverlay';
import { calculatePointsWithinTimeframe } from '../../../utils/helpers';

// TODO:
// 1. Figure out the points per Fantasy Session. use the start and end date. Any games between this will be considered.

export const Fantasy = (): JSX.Element => {
    const { user } = UserAuth();
    const dispatch = useAppDispatch();

    const players = useAppSelector((state) => state.player.players);
    const matchDays = useAppSelector((state) => state.matchDay.matchDays);
    const teams = useAppSelector((state) => state.team.teams);
    const fantasyTeams = useAppSelector((state) => state.fantasyTeam.fantasyTeams);
    const fantasySessions = useAppSelector((state) => state.fantasySession.fantasySessions);
    const siteData = useAppSelector((state) => state.siteData.siteData);

    const [filteredFantasyTeams, setFilteredFantasyTeams] = React.useState<FantasyTeam[]>([]);
    const [newFantasyTeam, setNewFantasyTeam] = React.useState<FantasyTeam>(initialNewFantasyTeamState);
    const [shouldUpdateAlreadyCreatedFantasyTeam, setShouldUpdateAlreadyCreatedFantasyTeam] =
        React.useState<boolean>(false);
    const [playersInSeason, setPlayersInSeason] = React.useState<Player[]>([]);
    const [selectedPlayers, setSelectedPlayers] = React.useState<{ [position: string]: string }>({});
    const [currentFantasySession, setCurrentFantasySession] = React.useState<FantasySession | null>(null);
    const [fantasyTeam, setFantasyTeam] = React.useState<FantasyTeam>();
    const [userCanCreateTeam, setUserCanCreateTeam] = React.useState<boolean>(false);
    const [teamCreationOpen, setTeamCreationOpen] = React.useState<boolean>(false);
    const [submitButtonDisabled, setSubmitButtonDisabled] = React.useState<boolean>(true);
    const [updateButtonDisabled, setUpdateButtonDisabled] = React.useState<boolean>(true);
    const [addButtonIsProcessing, setAddButtonIsProcessing] = React.useState<boolean>(false);
    const [loading, setLoading] = React.useState<boolean>(false);
    const [isNameValid, setIsNameValid] = React.useState<boolean>(true);
    const [customAlertState, setCustomAlertState] = React.useState<CustomAlertProps>({
        open: false,
        severity: 'info',
        message: '',
        closeAlert: () => handleCloseAlert(),
    });

    React.useEffect(() => {
        if (players && matchDays && teams && fantasyTeams && fantasySessions && siteData) {
            setLoading(false);
        } else setLoading(true);
    }, [players, matchDays, teams, fantasyTeams, fantasySessions, siteData]);

    React.useEffect(() => {
        if (siteData && filteredFantasyTeams && user) {
            const currentFantasySessionUid: string = siteData.fantasySessionUid;
            const currentUserUid: string = user.uid; // Replace this with the actual UID of the current user

            // Check if the user already has a team for the current fantasy session
            const userHasTeam = filteredFantasyTeams.some(
                (team) =>
                    team.ownerUid === currentUserUid &&
                    team.weeks.some((week) => week.fantasySessionUid === currentFantasySessionUid)
            );
            setShouldUpdateAlreadyCreatedFantasyTeam(!userHasTeam);
        }
    }, [siteData, filteredFantasyTeams, user]);

    React.useEffect(() => {
        if (currentFantasySession) {
            console.log('currentFantasySession', currentFantasySession);
        }
    }, [currentFantasySession]);

    React.useEffect(() => {
        if (fantasySessions && siteData && user && fantasyTeams) {
            const currentSeasonUid = siteData.seasonUid;
            const currentSession = fantasySessions
                .filter((session) => session.seasonUid === currentSeasonUid)
                .sort((a, b) => b.weekNumber - a.weekNumber)[0];
            const tempFantasyTeams: FantasyTeam[] = fantasyTeams.filter(
                (fs) => fs.seasonUid === siteData.seasonUid && fs.ownerUid === user.uid
            );
            setTeamCreationOpen(currentSession.teamCreationStatus === 'open' ? true : false);
            setUserCanCreateTeam(tempFantasyTeams.length === 0);
            setFilteredFantasyTeams(tempFantasyTeams);
            setFantasyTeam(tempFantasyTeams[tempFantasyTeams.length - 1]);
            setCurrentFantasySession(currentSession);
            setNewFantasyTeam((prev) => {
                prev = { ...prev };
                prev.seasonUid = siteData.seasonUid;
                prev.ownerUid = user.uid;
                return prev;
            });
        }
    }, [fantasySessions, siteData, user, fantasyTeams]);

    React.useEffect(() => {
        if (players && teams) {
            const tempPlayersInSeason: Player[] = players.filter((player) => {
                return teams.some((team) => team.players.includes(player.uid));
            });
            setPlayersInSeason(tempPlayersInSeason);
        }
    }, [players, teams]);

    React.useEffect(() => {
        const name = newFantasyTeam.teamName.trim();
        setIsNameValid(!!name);

        // TODO: Should instead ensure each position is filled
        if (Object.values(selectedPlayers).length === 7 && newFantasyTeam.teamName !== '') {
            setSubmitButtonDisabled(false);
        } else setSubmitButtonDisabled(true);
    }, [newFantasyTeam, selectedPlayers]);

    React.useEffect(() => {
        if (Object.values(selectedPlayers).length === 7) {
            setUpdateButtonDisabled(false);
        } else setUpdateButtonDisabled(true);
    }, [selectedPlayers]);

    const handleCloseAlert = () => {
        setCustomAlertState((prev) => ({ ...prev, open: false }));
    };

    const handlePlayerChange = (position: string, playerId: string) => {
        setSelectedPlayers((prev) => ({ ...prev, [position]: playerId }));
    };

    const handleAddFantasyTeam = async (e: React.FormEvent<HTMLFormElement>): Promise<void> => {
        e.preventDefault();
        if (!selectedPlayers || !players || !currentFantasySession) return;
        try {
            setAddButtonIsProcessing(true);
            const payload: FantasyTeam = {
                ...newFantasyTeam,
                weeks: [
                    {
                        weekNumber: currentFantasySession.weekNumber,
                        fantasySessionUid: currentFantasySession.uid || '',
                        players:
                            (Object.values(selectedPlayers).map(
                                (spName) => players.find((player) => player.name === spName)?.uid
                            ) as string[]) || [],
                    },
                ],
            };
            const action = await dispatch(addFantasyTeamAsync(payload));
            const data: AddFantasySessionServerResponse = action.payload as AddFantasySessionServerResponse;
            if (data.success) {
                setNewFantasyTeam(initialNewFantasyTeamState);
                setSelectedPlayers({});
                setCustomAlertState((prev) => {
                    prev = { ...prev };
                    prev.open = true;
                    prev.message = data.message;
                    prev.severity = 'success';
                    return prev;
                });
            }
        } catch (error: any) {
            console.error('Error adding fantasy team:', error.message);
            setCustomAlertState((prev) => {
                prev = { ...prev };
                prev.open = true;
                prev.message = error.message;
                prev.severity = 'error';
                return prev;
            });
        } finally {
            setAddButtonIsProcessing(false);
        }
    };

    const updateFantasyTeam = async (e: React.FormEvent<HTMLFormElement>): Promise<void> => {
        e.preventDefault();
        if (!selectedPlayers || !players || !fantasyTeam || !currentFantasySession) return;
        try {
            setAddButtonIsProcessing(true);

            const payload: { fantasyTeamUid: string; fantasySessionUid: string; players: string[] } = {
                fantasySessionUid: currentFantasySession.uid,
                fantasyTeamUid: fantasyTeam.uid,
                players:
                    (Object.values(selectedPlayers).map(
                        (spName) => players.find((player) => player.name === spName)?.uid
                    ) as string[]) || [],
            };

            const action = await dispatch(updateFantasyTeamWeekAsync(payload));
            const data: UpdateFantasyTeamWeekServerResponse = action.payload as UpdateFantasyTeamWeekServerResponse;
            if (data.success) {
                setSelectedPlayers({});
                setCustomAlertState((prev) => {
                    prev = { ...prev };
                    prev.open = true;
                    prev.message = data.message;
                    prev.severity = 'success';
                    return prev;
                });
            }
        } catch (error: any) {
            console.error('Error adding fantasy team:', error.message);
            setCustomAlertState((prev) => {
                prev = { ...prev };
                prev.open = true;
                prev.message = error.message;
                prev.severity = 'error';
                return prev;
            });
        } finally {
            setAddButtonIsProcessing(false);
            setUserCanCreateTeam(false);
            setShouldUpdateAlreadyCreatedFantasyTeam(false);
        }
    };

    const AddFantasyTeamComponent = (): JSX.Element => {
        return (
            <Grid item xs={12}>
                <form onSubmit={handleAddFantasyTeam}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                required
                                error={!isNameValid}
                                size='small'
                                label='Team Name'
                                value={newFantasyTeam.teamName}
                                onChange={(e: any) =>
                                    setNewFantasyTeam({
                                        ...newFantasyTeam,
                                        teamName: e.target.value,
                                    })
                                }
                            />
                        </Grid>
                        {positions.map((position) => (
                            <Grid item xs={12} sm={6} md={3} key={position}>
                                <FormControl fullWidth>
                                    <InputLabel>{position}</InputLabel>
                                    <Select
                                        value={selectedPlayers[position] || ''}
                                        onChange={(e) => handlePlayerChange(position, e.target.value)}
                                        size='small'
                                    >
                                        {playersInSeason
                                            .filter((player) => player.position === position)
                                            .map((player) => (
                                                <MenuItem key={player.uid} value={player.name}>
                                                    {player.name}
                                                </MenuItem>
                                            ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                        ))}
                        <Grid item xs={12}>
                            <LoadingButton
                                disabled={submitButtonDisabled}
                                isProcessing={addButtonIsProcessing}
                                type='submit'
                                text='Add Fantasy Team'
                            />
                        </Grid>
                    </Grid>
                </form>
            </Grid>
        );
    };

    const UpdateFantasyTeamComponent = (): JSX.Element => {
        return (
            <Grid item xs={12}>
                <form onSubmit={updateFantasyTeam}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                size='small'
                                label='Team Name'
                                value={fantasyTeam?.teamName}
                                disabled
                            />
                        </Grid>
                        {positions.map((position) => (
                            <Grid item xs={12} sm={6} md={3} key={position}>
                                <FormControl fullWidth>
                                    <InputLabel>{position}</InputLabel>
                                    <Select
                                        value={selectedPlayers[position] || ''}
                                        onChange={(e) => handlePlayerChange(position, e.target.value)}
                                        size='small'
                                    >
                                        {playersInSeason
                                            .filter((player) => player.position === position)
                                            .map((player) => (
                                                <MenuItem key={player.uid} value={player.name}>
                                                    {player.name}
                                                </MenuItem>
                                            ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                        ))}
                        <Grid item xs={12}>
                            <LoadingButton
                                disabled={updateButtonDisabled}
                                isProcessing={addButtonIsProcessing}
                                type='submit'
                                text='Update Fantasy Team'
                            />
                        </Grid>
                    </Grid>
                </form>
            </Grid>
        );
    };

    React.useEffect(() => {
        console.log('teamCreationOpen', teamCreationOpen);
    }, [teamCreationOpen]);

    return (
        <Container maxWidth='xl'>
            {loading ? (
                <LoadingOverlay message='Loading Fantasy.' />
            ) : (
                <>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Typography variant='h4'>Fantasy</Typography>
                        </Grid>

                        {userCanCreateTeam && teamCreationOpen ? (
                            AddFantasyTeamComponent()
                        ) : shouldUpdateAlreadyCreatedFantasyTeam && teamCreationOpen ? (
                            UpdateFantasyTeamComponent()
                        ) : (
                            <Grid item xs={12}>
                                <Typography variant='caption'>
                                    You cannot create a team. You either already created one for the current week or the
                                    league is not in season.
                                </Typography>
                            </Grid>
                        )}
                        <Grid item xs={12}>
                            <Divider light />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant='h5'>
                                <b>{fantasyTeam?.teamName}</b>
                            </Typography>
                            <Grid container spacing={1}>
                                {filteredFantasyTeams?.length === 0 ? (
                                    <Typography>No past teams available.</Typography>
                                ) : (
                                    filteredFantasyTeams?.map((team, oi: number) =>
                                        team.weeks.map((week, ii: number) => {
                                            const totalPoints = week.players.reduce((acc, playerUid) => {
                                                const player = players?.find((p) => p.uid === playerUid);
                                                const session = fantasySessions?.find(
                                                    (fs) => fs.uid === week.fantasySessionUid
                                                );

                                                if (player && session) {
                                                    const points = calculatePointsWithinTimeframe(
                                                        session.startDate || '',
                                                        session.endDate || '',
                                                        siteData?.seasonUid || '',
                                                        matchDays || [],
                                                        player
                                                    );
                                                    return acc + points;
                                                }
                                                return acc;
                                            }, 0);
                                            return (
                                                <Grid item xs={12} sm={12} md={4} lg={3} xl={2} key={`${oi}-${ii}`}>
                                                    <Card>
                                                        <CardContent>
                                                            <Typography variant='h6'>
                                                                <b>Week {week.weekNumber}</b>
                                                            </Typography>
                                                            {week.players.map((player, iii: number) => {
                                                                return (
                                                                    <>
                                                                        <Typography variant='body1'>
                                                                            {iii + 1}.{' '}
                                                                            <b>
                                                                                {
                                                                                    players?.find(
                                                                                        (p) => p.uid === player
                                                                                    )?.position
                                                                                }{' '}
                                                                            </b>
                                                                            {
                                                                                players?.find((p) => p.uid === player)
                                                                                    ?.name
                                                                            }{' '}
                                                                            -{' '}
                                                                            <b>
                                                                                {calculatePointsWithinTimeframe(
                                                                                    fantasySessions?.find(
                                                                                        (fs) =>
                                                                                            fs.uid ===
                                                                                            week.fantasySessionUid
                                                                                    )?.startDate || '',
                                                                                    fantasySessions?.find(
                                                                                        (fs) =>
                                                                                            fs.uid ===
                                                                                            week.fantasySessionUid
                                                                                    )?.endDate || '',
                                                                                    siteData?.seasonUid || '',
                                                                                    matchDays || [],
                                                                                    players?.find(
                                                                                        (p) => p.uid === player
                                                                                    )
                                                                                )}
                                                                            </b>
                                                                        </Typography>
                                                                    </>
                                                                );
                                                            })}
                                                            <br />
                                                            <Divider light />
                                                            <br />
                                                            <Typography variant='body1'>
                                                                Total Points: <b>{totalPoints}</b>
                                                            </Typography>
                                                        </CardContent>
                                                    </Card>
                                                    <br />
                                                </Grid>
                                            );
                                        })
                                    )
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                    <CustomAlert {...customAlertState} />
                </>
            )}
        </Container>
    );
};
