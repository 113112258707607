import { Grid, Typography } from '@mui/material';
import heroImage1 from '../assets/artwork/artwork_1.png';
import heroImage2 from '../assets/artwork/artwork_2.png';
import heroImage3 from '../assets/artwork/artwork_3.png';
import heroImage4 from '../assets/artwork/artwork_4.jpg';
import heroImage5 from '../assets/artwork/artwork_5.jpg';
import heroImage6 from '../assets/artwork/artwork_6.jpg';
import heroImage7 from '../assets/artwork/artwork_7.jpg';
import heroImage8 from '../assets/artwork/artwork_8.jpg';
import heroImage9 from '../assets/artwork/artwork_9.jpg';
import { SwiperCarousel } from '../utils/renderers/SwiperCarousel';

import '../styles/HeroSection.css';

const images = [
    heroImage1,
    heroImage2,
    heroImage3,
    heroImage4,
    heroImage5,
    heroImage6,
    heroImage7,
    heroImage8,
    heroImage9,
];

export const HeroSection = (): JSX.Element => {
    return (
        <div style={{ position: 'relative', overflow: 'hidden' }}>
            <div
                style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    zIndex: -1,
                    background: 'linear-gradient(135deg, black, #333333, black)',
                    backgroundSize: '500% 500%',
                    animation: 'gradientShift 7s ease infinite',
                }}
            >
                <div
                    style={{
                        position: 'absolute',
                        top: '15%',
                        left: '20%',
                        width: 0,
                        height: 0,
                        borderLeft: '20px solid transparent',
                        borderRight: '20px solid transparent',
                        borderBottom: '40px solid #1a1a1a',
                        transform: 'rotate(15deg)',
                        opacity: 0.8,
                    }}
                />
                <div
                    style={{
                        position: 'absolute',
                        top: '40%',
                        left: '60%',
                        width: 0,
                        height: 0,
                        borderLeft: '25px solid transparent',
                        borderRight: '25px solid transparent',
                        borderBottom: '50px solid #333333',
                        transform: 'rotate(-20deg)',
                        opacity: 0.8,
                    }}
                />
                <div
                    style={{
                        position: 'absolute',
                        top: '65%',
                        left: '35%',
                        width: 0,
                        height: 0,
                        borderLeft: '15px solid transparent',
                        borderRight: '15px solid transparent',
                        borderBottom: '30px solid #1a1a1a',
                        transform: 'rotate(30deg)',
                        opacity: 0.8,
                    }}
                />
                <div
                    style={{
                        position: 'absolute',
                        top: '80%',
                        left: '75%',
                        width: 0,
                        height: 0,
                        borderLeft: '18px solid transparent',
                        borderRight: '18px solid transparent',
                        borderBottom: '36px solid #333333',
                        transform: 'rotate(45deg)',
                        opacity: 0.8,
                    }}
                />
                <div
                    style={{
                        position: 'absolute',
                        top: '50%',
                        left: '15%',
                        width: 0,
                        height: 0,
                        borderLeft: '22px solid transparent',
                        borderRight: '22px solid transparent',
                        borderBottom: '44px solid #1a1a1a',
                        transform: 'rotate(-10deg)',
                        opacity: 0.8,
                    }}
                />
                <div
                    style={{
                        position: 'absolute',
                        top: '10%',
                        left: '80%',
                        width: 0,
                        height: 0,
                        borderLeft: '20px solid transparent',
                        borderRight: '20px solid transparent',
                        borderBottom: '40px solid #333333',
                        transform: 'rotate(-5deg)',
                        opacity: 0.8,
                    }}
                />
                <div
                    style={{
                        position: 'absolute',
                        top: '30%',
                        left: '10%',
                        width: 0,
                        height: 0,
                        borderLeft: '16px solid transparent',
                        borderRight: '16px solid transparent',
                        borderBottom: '32px solid #1a1a1a',
                        transform: 'rotate(25deg)',
                        opacity: 0.8,
                    }}
                />
                <div
                    style={{
                        position: 'absolute',
                        top: '75%',
                        left: '50%',
                        width: 0,
                        height: 0,
                        borderLeft: '19px solid transparent',
                        borderRight: '19px solid transparent',
                        borderBottom: '38px solid #333333',
                        transform: 'rotate(-35deg)',
                        opacity: 0.8,
                    }}
                />
                <div
                    style={{
                        position: 'absolute',
                        top: '90%',
                        left: '10%',
                        width: 0,
                        height: 0,
                        borderLeft: '24px solid transparent',
                        borderRight: '24px solid transparent',
                        borderBottom: '48px solid #1a1a1a',
                        transform: 'rotate(15deg)',
                        opacity: 0.8,
                    }}
                />
                {/* Additional Triangles for Right/Bottom */}
                <div
                    style={{
                        position: 'absolute',
                        bottom: '15%',
                        right: '20%',
                        width: 0,
                        height: 0,
                        borderLeft: '20px solid transparent',
                        borderRight: '20px solid transparent',
                        borderBottom: '40px solid #1a1a1a',
                        transform: 'rotate(-10deg)',
                        opacity: 0.8,
                    }}
                />
                <div
                    style={{
                        position: 'absolute',
                        bottom: '30%',
                        right: '40%',
                        width: 0,
                        height: 0,
                        borderLeft: '25px solid transparent',
                        borderRight: '25px solid transparent',
                        borderBottom: '50px solid #333333',
                        transform: 'rotate(20deg)',
                        opacity: 0.8,
                    }}
                />
                <div
                    style={{
                        position: 'absolute',
                        bottom: '10%',
                        right: '30%',
                        width: 0,
                        height: 0,
                        borderLeft: '22px solid transparent',
                        borderRight: '22px solid transparent',
                        borderBottom: '44px solid #1a1a1a',
                        transform: 'rotate(-30deg)',
                        opacity: 0.8,
                    }}
                />
                <div
                    style={{
                        position: 'absolute',
                        bottom: '25%',
                        right: '10%',
                        width: 0,
                        height: 0,
                        borderLeft: '18px solid transparent',
                        borderRight: '18px solid transparent',
                        borderBottom: '36px solid #333333',
                        transform: 'rotate(45deg)',
                        opacity: 0.8,
                    }}
                />
                <div
                    style={{
                        position: 'absolute',
                        bottom: '5%',
                        right: '60%',
                        width: 0,
                        height: 0,
                        borderLeft: '24px solid transparent',
                        borderRight: '24px solid transparent',
                        borderBottom: '48px solid #1a1a1a',
                        transform: 'rotate(15deg)',
                        opacity: 0.8,
                    }}
                />
                <svg
                    viewBox='0 0 1440 320'
                    style={{
                        position: 'absolute',
                        bottom: 0,
                        left: 0,
                        width: '100%',
                        height: 'auto',
                    }}
                >
                    <path
                        fill='#1a1a1a'
                        fillOpacity='1'
                        d='M0,224L48,192C96,160,192,96,288,74.7C384,53,480,75,576,96C672,117,768,139,864,154.7C960,171,1056,181,1152,192C1248,203,1344,213,1392,218.7L1440,224L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z'
                    ></path>
                </svg>
            </div>
            <Grid container style={{ color: 'white', padding: '24px' }} spacing={{ xs: 1, sm: 1, md: 3, lg: 4, xl: 5 }}>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <SwiperCarousel pagination={false} slidesPerView={1} spaceBetween={1} breakpoints={{}}>
                        {images.map((image, index: number) => {
                            return (
                                <img
                                    key={`hero-${index + 1}`}
                                    style={{ width: '100%', height: 'auto', aspectRatio: '16/9', objectFit: 'cover' }}
                                    alt={`hero-${index + 1}`}
                                    src={image}
                                />
                            );
                        })}
                    </SwiperCarousel>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'flex-start',
                            justifyContent: 'center',
                            flexDirection: 'column',
                            textAlign: 'left',
                            height: '100%',
                        }}
                    >
                        <Typography variant='h4'>
                            NYC's Premier Draft Soccer League: Where Friends Become Legends
                        </Typography>
                        <Typography variant='body1'>
                            Welcome to NYC's only 7v7 draft soccer league, a place where every match is a blend of
                            fierce competition and genuine camaraderie. Our league isn’t just about winning—it's about
                            creating lasting memories with friends old and new. Each season, team managers draft players
                            to form unique lineups, ensuring a fresh and dynamic experience every time you step on the
                            field.
                            <br />
                            <br />
                            Whether you're chasing victory or just here for the love of the game, you'll find yourself
                            surrounded by a supportive community that shares your passion. From the first whistle to the
                            final goal, our league offers the perfect mix of high-energy action and friendly rivalry.
                            <br />
                            <br />
                            Join us, and become part of NYC’s most exciting and welcoming soccer experience. The game is
                            better when played together, and here, friendships are as important as the goals scored.
                        </Typography>
                    </div>
                </Grid>
            </Grid>
        </div>
    );
};
