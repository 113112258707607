import React from 'react';
import {
    Button,
    Card,
    CardContent,
    Container,
    Divider,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    Typography,
} from '@mui/material';
import { LoadingOverlay } from '../../../components/LoadingOverlay';
import { useAppSelector } from '../../../store/store';
import { CustomMaterialTable } from '../../../components/CustomMaterialTable';
import {
    formatMatchDays,
    calculateD3BarChartDataWinsLossesDraws,
    transformMatchDaysToMultiLineData,
    gameHasOccured,
} from '../../../utils/helpers';
import { FormmattedMatchDay, Player, Season, Team } from '../../../types';
import { useNavigate, useParams } from 'react-router-dom';
import D3BarChart from '../../../components/D3/d3BarChart';
import D3Legend from '../../../components/D3/d3Legend';
import D3MultiLineChart from '../../../components/D3/d3MultiLineChart';
import { D3MultiLineChartLegend } from '../../../components/D3/d3LegendMultiLineChart';

export const PublicTeamDetails = (): JSX.Element => {
    const navigate = useNavigate();

    const teams = useAppSelector((state) => state.team.teams);
    const seasons = useAppSelector((state) => state.season.seasons);
    const matchDays = useAppSelector((state) => state.matchDay.matchDays);
    const players = useAppSelector((state) => state.player.players);

    const { teamUid } = useParams<{ teamUid: string }>();
    const [loading, setLoading] = React.useState(true);
    const [team, setTeam] = React.useState<Team>();
    const [selectedSeason, setSelectedSeaon] = React.useState<Season | 'all'>('all');
    const [selectedTimeGranularity, setSelectedTimeGranularity] = React.useState<'year' | 'month'>('year');
    const [tableData, setTableData] = React.useState<FormmattedMatchDay[]>([]);
    const [currentPlayers, setCurrentPlayers] = React.useState<Player[]>([]);
    const [generalStatsBarChartData, setGeneralStatsBarChartData] = React.useState<{ name: string; value: number }[]>(
        []
    );
    const [performanceDataMultiLineChart, setPerformanceDataMultiLineChart] = React.useState<
        { date: string; [key: string]: any }[]
    >([]);

    React.useEffect(() => {
        if (teams && teamUid && seasons && matchDays && players) {
            setLoading(false);
            const tempTeam: Team | undefined = teams.find((team) => team.uid === teamUid);
            const tempCurrentPlayers: Player[] = players.filter((player) => tempTeam?.players.includes(player.uid));
            setTeam(tempTeam);
            setCurrentPlayers(tempCurrentPlayers);
        } else setLoading(true);
    }, [teams, teamUid, seasons, matchDays, players]);

    React.useEffect(() => {
        if (selectedSeason && matchDays && seasons && teams && teamUid && selectedTimeGranularity) {
            let tempFormattedMatchdays: FormmattedMatchDay[] = [];
            if (selectedSeason === 'all') {
                tempFormattedMatchdays = formatMatchDays(
                    matchDays.filter((md) => md.teamAUid === teamUid || md.teamBUid === teamUid),
                    teams,
                    seasons
                ).sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
            } else {
                tempFormattedMatchdays = formatMatchDays(
                    matchDays.filter(
                        (md) =>
                            md.seasonUid === selectedSeason.uid && (md.teamAUid === teamUid || md.teamBUid === teamUid)
                    ),
                    teams,
                    seasons
                ).sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
            }
            setTableData(tempFormattedMatchdays);
            const barChartData = calculateD3BarChartDataWinsLossesDraws(
                matchDays
                    .filter((md) => md.teamAUid === teamUid || md.teamBUid === teamUid)
                    .filter((md) => gameHasOccured(md.date)),
                selectedSeason,
                teamUid
            );
            setGeneralStatsBarChartData(barChartData);

            const multiLineChartData = transformMatchDaysToMultiLineData(
                matchDays
                    .filter((md) => md.teamAUid === teamUid || md.teamBUid === teamUid)
                    .filter((md) => gameHasOccured(md.date)),
                selectedSeason,
                teamUid,
                selectedTimeGranularity
            ).sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
            setPerformanceDataMultiLineChart(multiLineChartData);
        }
    }, [selectedSeason, matchDays, teams, seasons, teamUid, selectedTimeGranularity]);

    React.useEffect(() => {
        console.log('performanceDataMultiLineChart', performanceDataMultiLineChart);
    }, [performanceDataMultiLineChart]);

    return (
        <Container maxWidth='xl'>
            {loading ? (
                <LoadingOverlay message='Loading team details.' />
            ) : (
                <>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Typography variant='h4'>{team?.name}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={4} md={4} lg={2} xl={2}>
                            <FormControl fullWidth style={{ maxHeight: 200 }}>
                                <InputLabel>Season Number</InputLabel>
                                <Select
                                    fullWidth
                                    size='small'
                                    label='Season Number'
                                    style={{ maxHeight: 200 }}
                                    value={
                                        selectedSeason === 'all' ? 'all' : String(selectedSeason?.seasonNumber) || null
                                    }
                                    onChange={(e) => {
                                        if (e.target.value === 'all') setSelectedSeaon(e.target.value);
                                        const foundSeason: Season | undefined = seasons?.find(
                                            (season) => String(season.seasonNumber) === String(e.target.value)
                                        );
                                        if (foundSeason) setSelectedSeaon(foundSeason);
                                    }}
                                    MenuProps={{
                                        PaperProps: {
                                            style: {
                                                maxHeight: 200,
                                            },
                                        },
                                    }}
                                >
                                    <MenuItem value={'all'}>All</MenuItem>
                                    {seasons
                                        ?.slice()
                                        .sort((a, b) => a.seasonNumber - b.seasonNumber)
                                        ?.map((season, index: number) => {
                                            return (
                                                <MenuItem key={index} value={season.seasonNumber}>
                                                    {season.seasonNumber}
                                                </MenuItem>
                                            );
                                        })}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <Divider light />
                        </Grid>
                        <Grid item xs={12}>
                            <CustomMaterialTable
                                data={tableData || []}
                                ignoredKeys={[]}
                                // path='/teams'
                                keysOrder={[
                                    'date',
                                    'teamAName',
                                    'teamBName',
                                    'teamAGoals',
                                    'teamBGoals',
                                    'seasonNumber',
                                ]}
                                exportDisabled
                                enableRowSelection={false}
                            />
                        </Grid>
                        {currentPlayers.length > 0 && (
                            <Grid item xs={12}>
                                <Typography variant='h5'>Current Players</Typography>
                                <Grid container spacing={1}>
                                    {currentPlayers.map((player, index: number) => {
                                        return (
                                            <Grid
                                                key={index}
                                                item
                                                xs={6}
                                                sm={6}
                                                md={6}
                                                lg={3}
                                                xl={3}
                                                sx={{ padding: 0 }}
                                            >
                                                <Button
                                                    sx={{ width: '100%', p: 0 }}
                                                    onClick={() => navigate(`/players/${player.uid}`)}
                                                >
                                                    <Card sx={{ width: '100%' }}>
                                                        <CardContent>
                                                            <img
                                                                src={player.image}
                                                                alt={player.name}
                                                                style={{ width: 'fit-content' }}
                                                                height={80}
                                                            />
                                                            <Typography variant='subtitle2'>{player.name}</Typography>
                                                        </CardContent>
                                                    </Card>
                                                </Button>
                                            </Grid>
                                        );
                                    })}
                                </Grid>
                            </Grid>
                        )}
                        <Grid item xs={12}>
                            <Typography variant='h5'>Team Data</Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={6} xl={4}>
                            <Card variant='outlined'>
                                <CardContent>
                                    <Typography variant='overline'>Overall Stats</Typography>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                            <D3BarChart data={generalStatsBarChartData} />
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                            <D3Legend data={generalStatsBarChartData} />
                                        </Grid>
                                    </Grid>
                                    <div
                                        style={{ display: 'flex', gap: '24px', justifyContent: 'space-between' }}
                                    ></div>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={6} xl={4}>
                            <Card variant='outlined'>
                                <CardContent>
                                    <Grid container spacing={1}>
                                        <Grid item xs={6}>
                                            <Typography variant='overline'>Performance</Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Select
                                                fullWidth
                                                size='small'
                                                label='Time Granularity'
                                                style={{ maxHeight: 200 }}
                                                value={selectedTimeGranularity}
                                                onChange={(e) =>
                                                    setSelectedTimeGranularity(e.target.value as 'year' | 'month')
                                                }
                                                MenuProps={{
                                                    PaperProps: {
                                                        style: {
                                                            maxHeight: 200,
                                                        },
                                                    },
                                                }}
                                            >
                                                <MenuItem value={'year'}>Year</MenuItem>
                                                <MenuItem value={'month'}>Month</MenuItem>
                                            </Select>
                                        </Grid>
                                    </Grid>

                                    <Grid container spacing={3}>
                                        <Grid item xs={12}></Grid>
                                        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                            <D3MultiLineChart
                                                data={performanceDataMultiLineChart}
                                                metrics={['wins', 'draws', 'losses']}
                                                granularity={selectedTimeGranularity}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                            <D3MultiLineChartLegend metrics={['wins', 'draws', 'losses']} />
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </>
            )}
        </Container>
    );
};
