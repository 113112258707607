import { configureStore } from '@reduxjs/toolkit';
import { useDispatch, useSelector, TypedUseSelectorHook } from 'react-redux';
import { PlayerSlice } from './features/playersSlice';
import { TeamSlice } from './features/teamSlice';
import { NewsSlice } from './features/newsSlice';
import { SeasonSlice } from './features/seasonSlice';
import { SiteDataSlice } from './features/siteDataSlice';
import { MatchDaysSlice } from './features/matchDaysSlice';
import { UserSlice } from './features/usersSlice';
import { VotingSessionsSlice } from './features/votingSessionsSlice';
import { FantasySessionsSlice } from './features/fantasySessionsSlice';
import { FantasyTeams } from './features/fantasyTeamsSlice';

export const store = configureStore({
    reducer: {
        player: PlayerSlice.reducer,
        team: TeamSlice.reducer,
        news: NewsSlice.reducer,
        season: SeasonSlice.reducer,
        siteData: SiteDataSlice.reducer,
        matchDay: MatchDaysSlice.reducer,
        user: UserSlice.reducer,
        votingSession: VotingSessionsSlice.reducer,
        fantasySession: FantasySessionsSlice.reducer,
        fantasyTeam: FantasyTeams.reducer,
    },
});

export const useAppDispatch: () => typeof store.dispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<ReturnType<typeof store.getState>> = useSelector;
export const useAppStore = store;
