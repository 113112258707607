import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FantasySession } from '../../types';

interface FantasySessionsState {
    fantasySessions: null | FantasySession[];
}

const initialState: FantasySessionsState = {
    fantasySessions: null,
};

export const FantasySessionsSlice = createSlice({
    name: 'fantasySessions',
    initialState,
    reducers: {
        setFantasySessions: (state, action: PayloadAction<FantasySession[]>) => {
            state.fantasySessions = action.payload;
        },
    },
});

export default FantasySessionsSlice.reducer;
export const { setFantasySessions } = FantasySessionsSlice.actions;
