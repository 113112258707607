import React, { useState, useRef, SyntheticEvent } from 'react';
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import Cropper, { Area } from 'react-easy-crop';
import LinearProgress from '@mui/material/LinearProgress';
import Slider from '@mui/material/Slider';

import { getCroppedImg } from '../utils/cropImage'; // Assuming this is in a separate file
import { Button, Card, CardContent, Grid, Typography } from '@mui/material';
import ImageIcon from '@mui/icons-material/Image';
import UploadIcon from '@mui/icons-material/Upload';

interface ImageUploaderProps {
    endpoint: string;
    uid: string;
    updateDownloadUrl: (newDownloadUrl: string) => void;
    aspectRatio: number;
    imageWidth: number;
    imageHeight: number;
}

const validImageTypes: string[] = ['image/jpeg', 'image/png', 'image/gif'];

export const ImageUploader: React.FC<ImageUploaderProps> = ({
    endpoint,
    uid,
    updateDownloadUrl,
    aspectRatio,
    imageWidth,
    imageHeight,
}) => {
    const [selectedImage, setSelectedImage] = useState<File | null>(null);
    const [uploadProgress, setUploadProgress] = useState<number>(0);
    const [crop, setCrop] = useState<{ x: number; y: number }>({ x: 0, y: 0 });
    const [zoom, setZoom] = useState<number>(1);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area | null>(null);
    const [isImageUploadProcessing, setIsImageUploadProcessing] = useState<boolean>(false);
    const [imageObjectUrl, setImageObjectUrl] = useState<string | null>(null);
    const [dropzoneActive, setDropZoneActive] = useState<boolean>(false);
    const [fileUploadedSucces, setFileUploadedSuccess] = useState<boolean>(false);

    const fileInputRef: any = useRef();
    const storage = getStorage();

    const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0];
        if (file) {
            setSelectedImage(file);
            setImageObjectUrl(URL.createObjectURL(file));
        }
    };

    const handleCropComplete = (croppedArea: Area, croppedAreaPixels: Area) => {
        setCroppedAreaPixels(croppedAreaPixels);
    };

    const handleZoomChange = (event: Event, value: number | number[], activeThumb: number) => {
        setZoom(Array.isArray(value) ? value[0] : value);
    };

    const handleDrop = (e: any): void => {
        e.preventDefault();
        const droppedFile = e.dataTransfer.files[0];

        if (droppedFile && isValidImageType(droppedFile.type)) {
            setSelectedImage(droppedFile);
            setDropZoneActive(true);
            const reader = new FileReader();
            reader.onload = () => {
                setImageObjectUrl(reader.result as string);
            };
            reader.readAsDataURL(droppedFile);
        } else clearFiles();
    };

    const handleDragOver = (e: SyntheticEvent): void => {
        e.preventDefault();
        setDropZoneActive(true);
    };

    const triggerFilePicker = (): void => {
        document.getElementById('file-picker')?.click();
    };

    const clearFileInput = (ctrl: HTMLInputElement): void => {
        try {
            // @ts-ignore
            ctrl.value = null;
        } catch (ex) {}
        if (ctrl.value) {
            // @ts-ignore
            ctrl.parentNode.replaceChild(ctrl.cloneNode(true), ctrl);
        }
    };

    const clearFiles = (): void => {
        setSelectedImage(null);
        setDropZoneActive(false);
        setFileUploadedSuccess(false);
        const fileInput = document.getElementById('file-picker') as HTMLInputElement;
        fileInput.disabled = true;

        // Clear the file input value
        clearFileInput(fileInput);

        // Enable the file input after a short delay
        setTimeout(() => {
            fileInput.disabled = false;
        }, 100);
    };

    const handleUpload = async () => {
        if (selectedImage && croppedAreaPixels) {
            setIsImageUploadProcessing(true);

            try {
                // Crop the image and get a data URL for the cropped image.
                const croppedImage = await getCroppedImg(
                    imageObjectUrl || '',
                    {
                        x: croppedAreaPixels.x,
                        y: croppedAreaPixels.y,
                        width: croppedAreaPixels.width,
                        height: croppedAreaPixels.height,
                    },
                    imageWidth,
                    imageHeight
                );

                // Convert the data URL to a Blob.
                const blob = await fetch(croppedImage).then((res) => res.blob());
                const file = new File([blob], `${uid}.png`, { type: 'image/png' });

                const storageRef = ref(storage, `${endpoint}/${uid}.png`);
                const uploadTask = uploadBytesResumable(storageRef, file);

                uploadTask.on(
                    'state_changed',
                    (snapshot) => {
                        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                        setUploadProgress(progress);
                    },
                    (error) => {
                        console.error('Error uploading image:', error);
                        setIsImageUploadProcessing(false);
                    },
                    async () => {
                        const newDownloadURL = await getDownloadURL(uploadTask.snapshot.ref);
                        updateDownloadUrl(newDownloadURL);
                        try {
                            fileInputRef.current.value = '';
                            setSelectedImage(null);
                            setUploadProgress(0);
                            setCrop({ x: 0, y: 0 });
                            setZoom(1);
                            setIsImageUploadProcessing(false);
                        } catch (error) {
                            setIsImageUploadProcessing(false);
                        }
                    }
                );
            } catch (error) {
                console.error('Error cropping image:', error);
                setIsImageUploadProcessing(false);
            }
        }
    };

    const isValidImageType = (fileType: string): boolean => {
        return validImageTypes.includes(fileType);
    };

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <Card
                    id='dropzone-parent-container'
                    className={`${dropzoneActive ? 'active' : 'disabled'} ${fileUploadedSucces ? 'success' : ''}`}
                >
                    <CardContent>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <div
                                    id='dropzone'
                                    className={dropzoneActive ? 'active' : 'disabled'}
                                    onDragOver={(e) => handleDragOver(e)}
                                    onDragLeave={() => setDropZoneActive(false)}
                                    onDrop={(e) => handleDrop(e)}
                                    onClick={dropzoneActive ? () => {} : triggerFilePicker}
                                    style={{
                                        cursor: dropzoneActive ? 'default' : 'pointer',
                                    }}
                                    data-testid='dropzone'
                                >
                                    <Grid container spacing={1} style={{ textAlign: 'center' }}>
                                        <Grid item xs={12}>
                                            <UploadIcon color='primary' />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant='caption' sx={{ width: '50%', textAlign: 'center' }}>
                                                Select image
                                            </Typography>
                                        </Grid>

                                        {/* Input for selecting image */}
                                        <input
                                            id='file-picker'
                                            style={{ display: 'none' }}
                                            type='file'
                                            accept='image/*'
                                            onChange={handleImageChange}
                                            ref={fileInputRef}
                                        />
                                        {selectedImage ? (
                                            <Grid item xs={12}>
                                                <Typography
                                                    variant='subtitle2'
                                                    sx={{ textAlign: 'center' }}
                                                    color={'default'}
                                                    className='file-upload-text'
                                                    style={{ minHeight: '0px' }}
                                                >
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            padding: 10,
                                                            wordBreak: 'break-word',
                                                        }}
                                                    >
                                                        <ImageIcon />
                                                        {selectedImage.name}
                                                    </div>
                                                    <div className='x-file' onClick={clearFiles}>
                                                        x
                                                    </div>
                                                </Typography>
                                            </Grid>
                                        ) : null}
                                    </Grid>
                                </div>
                            </Grid>
                            {selectedImage && (
                                <Grid item xs={12}>
                                    <div style={{ position: 'relative', width: '300px', height: '400px' }}>
                                        <Cropper
                                            image={imageObjectUrl || ''}
                                            crop={crop}
                                            zoom={zoom}
                                            aspect={aspectRatio} // You can adjust the aspect ratio as needed
                                            onCropChange={setCrop}
                                            onZoomChange={setZoom}
                                            onCropComplete={handleCropComplete}
                                        />
                                    </div>
                                </Grid>
                            )}
                            {selectedImage && (
                                <Grid item xs={12}>
                                    <div style={{ marginTop: '10px' }}>
                                        <Slider
                                            value={zoom}
                                            min={1}
                                            max={3}
                                            step={0.1}
                                            valueLabelDisplay='auto'
                                            onChange={handleZoomChange}
                                        />
                                    </div>
                                </Grid>
                            )}
                            {isImageUploadProcessing && (
                                <Grid item xs={12}>
                                    <LinearProgress variant='determinate' value={uploadProgress} />
                                </Grid>
                            )}
                            <Grid item xs={12}>
                                {/* Upload button */}
                                <Button
                                    variant='contained'
                                    onClick={handleUpload}
                                    disabled={!fileInputRef?.current?.value || isImageUploadProcessing}
                                >
                                    Upload Image
                                </Button>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
};
