import React from 'react';
import { AddTeamServerResponse, CustomAlertProps, Player, Team, UpdatePlayerServerResponse } from '../../../types';
import {
    Autocomplete,
    // Autocomplete,
    Box,
    Button,
    Card,
    CardContent,
    Container,
    Grid,
    Modal,
    TextField,
    Typography,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { initialAlertState, initialNewTeamState } from '../../../utils/consts';
import { LoadingOverlay } from '../../../components/LoadingOverlay';
import { modalStyle } from '../../../styles/theme';
import { CustomAlert } from '../../../components/CustomAlert';
import { CustomMaterialTable } from '../../../components/CustomMaterialTable';
import { useAppDispatch, useAppSelector } from '../../../store/store';
import { addTeam as addTeamAsync, updateTeam as updatePlayerAsync } from '../../../utils/actions';
import { LoadingButton } from '../../../components/LoadingButton';

export const Teams = () => {
    const dispatch = useAppDispatch();
    const teams = useAppSelector((state) => state.team.teams);
    const players = useAppSelector((state) => state.player.players);

    const [customAlertState, setCustomAlertState] = React.useState<CustomAlertProps>({
        ...initialAlertState,
        closeAlert: () => handleCloseAlert(),
    });
    const [playersWithNoTeam, setPlayersWithNoTeam] = React.useState<Player[]>([]);
    const [newTeam, setNewTeam] = React.useState<Team>(initialNewTeamState);
    const [loading, setLoading] = React.useState<boolean>(false);
    const [addButtonIsProcessing, setAddButtonIsProcessing] = React.useState<boolean>(false);
    const [isNameValid, setIsNameValid] = React.useState<boolean>(true);
    const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false);
    const [submitButtonDisabled, setSubmitButtonDisabled] = React.useState<boolean>(true);

    React.useEffect(() => {
        if (teams && players) {
            const playersWithTeamUids: string[] = teams.map((team) => team.players).flat();
            const tempPlayersWithNoTeam: Player[] = players.filter(
                (player) => !playersWithTeamUids.includes(player.uid)
            );
            setPlayersWithNoTeam(tempPlayersWithNoTeam);
            setLoading(false);
        }
    }, [teams, players]);

    React.useEffect(() => {
        const name = newTeam.name.trim();

        setIsNameValid(!!name);

        if (name !== '') {
            setSubmitButtonDisabled(false);
        } else setSubmitButtonDisabled(true);
    }, [newTeam]);

    const handleModalClose = (): void => {
        setNewTeam(initialNewTeamState);
        setIsModalOpen(false);
    };

    const handleCloseAlert = () => {
        setCustomAlertState((prev) => {
            prev = { ...prev };
            prev.open = false;
            return prev;
        });
    };

    const handleAddTeam = async (e: React.FormEvent<HTMLFormElement>): Promise<void> => {
        e.preventDefault();
        try {
            setAddButtonIsProcessing(true);
            const action = await dispatch(addTeamAsync(newTeam));
            const data: AddTeamServerResponse = action.payload as AddTeamServerResponse;
            if (data.success) {
                setNewTeam(initialNewTeamState);
                setCustomAlertState((prev) => {
                    prev = { ...prev };
                    prev.open = true;
                    prev.message = data.message;
                    prev.severity = 'success';
                    return prev;
                });
                setIsModalOpen(false);
            }
        } catch (error: any) {
            console.error('Error adding team:', error.message);
            setCustomAlertState((prev) => {
                prev = { ...prev };
                prev.open = true;
                prev.message = error.message;
                prev.severity = 'error';
                return prev;
            });
        } finally {
            setAddButtonIsProcessing(false);
        }
    };

    const handleUpdateTeam = async (teamData: Team) => {
        try {
            const completeTeamData = { ...teams?.find((team) => team.uid === teamData.uid), ...teamData };
            const action = await dispatch(updatePlayerAsync({ teamId: teamData.uid, team: completeTeamData }));
            const data: UpdatePlayerServerResponse = action.payload as UpdatePlayerServerResponse;
            if (data.success) {
                setCustomAlertState((prev) => {
                    prev = { ...prev };
                    prev.open = true;
                    prev.message = data.message;
                    prev.severity = 'success';
                    return prev;
                });
            }
        } catch (error: any) {
            console.error('Error updating team:', error.message);
            setCustomAlertState((prev) => {
                prev = { ...prev };
                prev.open = true;
                prev.message = error.message;
                prev.severity = 'error';
                return prev;
            });
        }
        return true;
    };

    const CreateTeamComponent = (): JSX.Element => {
        return (
            <Grid item xs={12}>
                <Typography variant='h6'>Create Team</Typography>
                <Card>
                    <CardContent>
                        <form onSubmit={handleAddTeam}>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        required
                                        error={!isNameValid}
                                        size='small'
                                        label='Team Name'
                                        value={newTeam.name}
                                        onChange={(e: any) => setNewTeam({ ...newTeam, name: e.target.value })}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Autocomplete
                                        multiple
                                        id='directors-autocomplete'
                                        options={playersWithNoTeam || []}
                                        getOptionLabel={(option: Player) => option.name || ''}
                                        // @ts-ignore
                                        value={newTeam.players.map((playerUid) =>
                                            players?.find((player) => player.uid === playerUid)
                                        )}
                                        onChange={(_, newValues) =>
                                            setNewTeam({
                                                ...newTeam,
                                                players: newValues.map((player) =>
                                                    typeof player === 'string' ? player : player.uid
                                                ),
                                            })
                                        }
                                        renderInput={(params) => (
                                            <TextField {...params} label='Players' size='small' fullWidth />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <LoadingButton
                                        disabled={submitButtonDisabled}
                                        isProcessing={addButtonIsProcessing}
                                        type='submit'
                                        text='Add Team'
                                    />
                                </Grid>
                            </Grid>
                        </form>
                    </CardContent>
                </Card>
            </Grid>
        );
    };

    return (
        <Container maxWidth='xl'>
            {loading ? (
                <LoadingOverlay message='Loading teams.' />
            ) : (
                <>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Typography variant='h4'>Teams</Typography>
                            <CustomMaterialTable
                                data={teams || []}
                                ignoredKeys={[]}
                                path='/admin-dashboard/teams'
                                keysOrder={['name', 'players', 'uid']}
                                updateData={handleUpdateTeam}
                                exportDisabled
                                enableRowSelection={false}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                variant='contained'
                                startIcon={<AddIcon />}
                                onClick={() => {
                                    setIsModalOpen(true);
                                }}
                            >
                                Create Team
                            </Button>
                        </Grid>
                    </Grid>
                    <Modal open={isModalOpen} onClose={handleModalClose}>
                        <Box sx={modalStyle}>{CreateTeamComponent()}</Box>
                    </Modal>
                    <CustomAlert {...customAlertState} />
                </>
            )}
        </Container>
    );
};
