import React from 'react';
import { Container, Divider, FormControl, Grid, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import { LoadingOverlay } from '../../../components/LoadingOverlay';
import { CustomAlertProps, MatchDay, Player, Team, UpdatePlayerStatsForMatchDayServerResponse } from '../../../types';
import { useAppDispatch, useAppSelector } from '../../../store/store';
import { formatDate } from '../../../utils/helpers';
import { PlayerStatsMaterialTable } from '../../../components/PlayerStatsMaterialTable';
import { updatePlayerStatsForMatch as updatePlayerStatsForMatchAsync } from '../../../utils/actions';
import { initialAlertState } from '../../../utils/consts';
import { CustomAlert } from '../../../components/CustomAlert';

export const Matches = (): JSX.Element => {
    const dispatch = useAppDispatch();

    const [loading, setLoading] = React.useState<boolean>(false);
    const [selectedMatchDayUid, setSelectedMatchDayUid] = React.useState<string>();
    const [matchDayOptions, setMatchDayOptions] = React.useState<MatchDay[]>([]);
    const [playersInMatch, setPlayersInMatch] = React.useState<Player[]>([]);
    const [customAlertState, setCustomAlertState] = React.useState<CustomAlertProps>({
        ...initialAlertState,
        closeAlert: () => handleCloseAlert(),
    });

    const matchDays = useAppSelector((state) => state.matchDay.matchDays);
    const siteData = useAppSelector((state) => state.siteData.siteData);
    const teams = useAppSelector((state) => state.team.teams);
    const players = useAppSelector((state) => state.player.players);

    React.useEffect(() => {
        if (matchDays && siteData && teams && players) {
            setLoading(false);
        } else setLoading(true);
    }, [matchDays, siteData, teams, players]);

    React.useEffect(() => {
        if (teams && players && selectedMatchDayUid && matchDays) {
            const tempTeamsInSelectedMatchUids: string[] = [
                matchDays?.find((matchDay) => matchDay.uid === selectedMatchDayUid)?.teamAUid || '',
                matchDays?.find((matchDay) => matchDay.uid === selectedMatchDayUid)?.teamBUid || '',
            ];
            const tempSelectedMatchTeams: Team[] = teams.filter((team) =>
                tempTeamsInSelectedMatchUids.includes(team.uid)
            );
            const tempSelectedMatchPlayerUids: string[] = tempSelectedMatchTeams.map((team) => team.players).flat();
            const tempSelectedMatchPlayers: Player[] | any = tempSelectedMatchPlayerUids
                .map((uid) => {
                    const foundPlayer = players?.find((player) => player.uid === uid) || undefined;
                    return {
                        ...foundPlayer,
                        stats: {
                            goals: foundPlayer?.stats
                                .find((season) => season.seasonUid === siteData?.seasonUid)
                                ?.matchDays.find((matchDay) => matchDay.matchId === selectedMatchDayUid)?.goals,
                            assists: foundPlayer?.stats
                                .find((season) => season.seasonUid === siteData?.seasonUid)
                                ?.matchDays.find((matchDay) => matchDay.matchId === selectedMatchDayUid)?.assists,
                            nutMegs: foundPlayer?.stats
                                .find((season) => season.seasonUid === siteData?.seasonUid)
                                ?.matchDays.find((matchDay) => matchDay.matchId === selectedMatchDayUid)?.nutMegs,
                            blocks: foundPlayer?.stats
                                .find((season) => season.seasonUid === siteData?.seasonUid)
                                ?.matchDays.find((matchDay) => matchDay.matchId === selectedMatchDayUid)?.blocks,
                        },
                    };
                })
                .filter((player) => player !== undefined);
            setPlayersInMatch(tempSelectedMatchPlayers);
        }
    }, [players, selectedMatchDayUid, teams, matchDays, siteData]);

    React.useEffect(() => {
        if (matchDays && siteData) {
            const currentSeasonMatchDays: MatchDay[] = matchDays
                .filter((matchDay) => matchDay.seasonUid === siteData.seasonUid)
                .sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
            setMatchDayOptions(currentSeasonMatchDays);
        }
    }, [matchDays, siteData]);

    const handleCloseAlert = () => {
        setCustomAlertState((prev) => {
            prev = { ...prev };
            prev.open = false;
            return prev;
        });
    };

    const handleUpdatePlayerData = async (playerData: {
        assists: number;
        blocks: number;
        goals: number;
        id: string;
        nutMegs: number;
        playerName: string;
        playerUid: string;
        mp: number;
    }) => {
        if (siteData?.seasonUid && selectedMatchDayUid) {
            try {
                const action = await dispatch(
                    updatePlayerStatsForMatchAsync({
                        playerUid: playerData.playerUid,
                        matchDayUid: selectedMatchDayUid,
                        seasonUid: siteData.seasonUid,
                        playerStats: {
                            goals: playerData.goals,
                            nutMegs: playerData.nutMegs,
                            blocks: playerData.blocks,
                            assists: playerData.assists,
                            mp: playerData.mp,
                        },
                    })
                );
                const data: UpdatePlayerStatsForMatchDayServerResponse =
                    action.payload as UpdatePlayerStatsForMatchDayServerResponse;
                if (data.success) {
                    setCustomAlertState((prev) => {
                        prev = { ...prev };
                        prev.open = true;
                        prev.message = data.message;
                        prev.severity = 'success';
                        return prev;
                    });
                }
            } catch (error: any) {
                console.error('Error updating matchDay:', error.message);
                setCustomAlertState((prev) => {
                    prev = { ...prev };
                    prev.open = true;
                    prev.message = error.message;
                    prev.severity = 'error';
                    return prev;
                });
            }
            return true;
        }
    };

    return (
        <Container maxWidth='xl'>
            {loading ? (
                <LoadingOverlay message='Loading stats.' />
            ) : (
                <>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Typography variant='h4'>Matches</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <InputLabel>Match Day</InputLabel>
                                <Select
                                    fullWidth
                                    size='small'
                                    label='Match day'
                                    value={selectedMatchDayUid}
                                    onChange={(e) => {
                                        setSelectedMatchDayUid(e.target.value);
                                    }}
                                >
                                    {matchDayOptions.map((matchDay) => (
                                        <MenuItem value={matchDay.uid}>
                                            {teams?.find((team) => team.uid === matchDay.teamAUid)?.name} vs.{' '}
                                            {teams?.find((team) => team.uid === matchDay.teamBUid)?.name} -{' '}
                                            <b>{formatDate(matchDay.date)}</b>
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <Divider />
                        </Grid>

                        <Grid item xs={12}>
                            <Typography variant='h6'>Player Stats</Typography>
                            <PlayerStatsMaterialTable
                                teams={teams || []}
                                playersInMatch={playersInMatch}
                                updateRowFromBackend={(playerData) => handleUpdatePlayerData(playerData)}
                                enableEditing={false}
                                hidePlayerUid={true}
                            />
                        </Grid>
                    </Grid>
                    <CustomAlert {...customAlertState} />
                </>
            )}
        </Container>
    );
};
