import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Card, CardContent, Container, Divider } from '@mui/material';
import { UserAuth } from '../../context/AuthContext';

export const Login = (): JSX.Element => {
    const { signIn } = UserAuth();
    const navigate = useNavigate();
    const [email, setEmail] = React.useState<string>('');
    const [password, setPassword] = React.useState<string>('');
    const [buttonDisabled, setButtonDisabled] = React.useState<boolean>(true);
    const [showPassword, setShowPassword] = React.useState(false);

    React.useEffect(() => {
        if (email && password) {
            setButtonDisabled(false);
        } else setButtonDisabled(true);
    }, [email, password]);

    const login = () => {
        signIn(email, password)
            .then(() => {
                navigate('/');
            })
            .catch((err: Error) => {
                alert(`There was an error logging in: ${err}`);
            });
    };

    return (
        <Container maxWidth='sm'>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant='h5'>Please Login.</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Card>
                        <CardContent>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <TextField
                                        size='small'
                                        label='Email'
                                        variant='filled'
                                        fullWidth
                                        type='email'
                                        onChange={(e: any) => setEmail(e.target.value as string)}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        size='small'
                                        fullWidth
                                        label='Password'
                                        variant='filled'
                                        type={showPassword ? 'text' : 'password'}
                                        onChange={(e) => setPassword(e.target.value)}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position='end'>
                                                    <IconButton
                                                        edge='end'
                                                        aria-label='toggle password visibility'
                                                        onClick={() => setShowPassword(!showPassword)}
                                                    >
                                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                    }}
                                >
                                    <Link to='/forgot-password'>
                                        <Typography>Forgot Password</Typography>
                                    </Link>
                                    <Link to='/signup'>
                                        <Typography>Sign Up</Typography>
                                    </Link>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12}>
                    <Divider light />
                </Grid>
                <Grid item xs={12} style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Button variant='contained' disabled={buttonDisabled} onClick={login}>
                        Login
                    </Button>
                </Grid>
            </Grid>
        </Container>
    );
};
