import React from 'react';
import { MatchDay, Team } from '../types';
import { SwiperCarousel } from '../utils/renderers/SwiperCarousel';
import { Card, CardContent, Grid, Typography, Skeleton } from '@mui/material';
import { formatDate, gameHasOccured } from '../utils/helpers';

interface MatchesCarouselProps {
    matchDays: MatchDay[] | null;
    teams: Team[] | null;
}

export const MatchesCarousel = ({ matchDays, teams }: MatchesCarouselProps): JSX.Element => {
    const [filteredMatchdays, setFilteredMatchdays] = React.useState<MatchDay[]>([]);
    const [dataLoaded, setDataLoaded] = React.useState<boolean>(false);

    React.useEffect(() => {
        if (matchDays && teams) {
            const sortedMatchDays: MatchDay[] = matchDays.sort(
                (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()
            );
            setFilteredMatchdays(sortedMatchDays);
            setDataLoaded(true);
        }
    }, [matchDays, teams]);

    return !dataLoaded ? (
        <Skeleton />
    ) : (
        <SwiperCarousel pagination={false} slidesPerView={1.1} spaceBetween={5}>
            {filteredMatchdays.map((match) => {
                return (
                    <Card key={match.uid}>
                        <CardContent style={{ padding: 0, borderRadius: 0 }}>
                            <Grid container spacing={0}>
                                <Grid
                                    item
                                    xs={12}
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        background: '#373737',
                                        padding: '6px 12px',
                                        color: 'white',
                                    }}
                                >
                                    <Typography variant='overline' style={{ background: '' }}>
                                        <b>{formatDate(match.date).split('@')[0]}</b>
                                    </Typography>
                                    <Typography variant='overline'>
                                        <b>{formatDate(match.date).split('@')[1]}</b>
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'space-around',
                                            padding: 6,
                                        }}
                                    >
                                        <Typography variant='overline'>
                                            <b>
                                                {teams &&
                                                    teams.find((team) => team.uid === match.teamAUid)?.name.slice(0, 3)}
                                            </b>
                                        </Typography>
                                        <img
                                            src={
                                                (teams &&
                                                    teams.find((team) => {
                                                        return match.teamAUid === team.uid;
                                                    })?.image) ||
                                                ''
                                            }
                                            width={20}
                                            alt={match.teamAUid}
                                        />
                                        <Typography variant='h6'>
                                            <b>{gameHasOccured(match.date) ? match.teamAGoals : ''}</b>
                                        </Typography>
                                        <Typography variant='overline'>
                                            <b>{gameHasOccured(match.date) ? '' : 'VS'} </b>
                                        </Typography>
                                        <Typography variant='h6'>
                                            <b>{gameHasOccured(match.date) ? match.teamBGoals : ''}</b>
                                        </Typography>
                                        <img
                                            src={
                                                (teams &&
                                                    teams.find((team) => {
                                                        return match.teamBUid === team.uid;
                                                    })?.image) ||
                                                ''
                                            }
                                            width={20}
                                            alt={match.teamBUid}
                                        />

                                        <Typography variant='overline'>
                                            <b>
                                                {teams &&
                                                    teams.find((team) => team.uid === match.teamBUid)?.name.slice(0, 3)}
                                            </b>
                                        </Typography>
                                    </div>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                );
            })}
        </SwiperCarousel>
    );
};
