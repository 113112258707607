import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { LeagueLeader, Player, Team } from '../../../types';
import { createLeagueLeadersTable } from '../../../utils/helpers';
import { Typography } from '@mui/material';
import { useAppSelector } from '../../../store/store';
import { useParams } from 'react-router-dom';

export const LeagueLeaders = (): JSX.Element => {
    const { attribute } = useParams<{ attribute: string }>();
    const [playersInSeason, setPlayersInSeason] = React.useState<Player[]>([]);
    const [tableData, setTableData] = React.useState<LeagueLeader[]>([]);

    const teams = useAppSelector((state) => state.team.teams);
    const players = useAppSelector((state) => state.player.players);
    const matchDays = useAppSelector((state) => state.matchDay.matchDays);
    const siteData = useAppSelector((state) => state.siteData.siteData);

    React.useEffect(() => {
        if (players && matchDays && siteData && teams) {
            const tempTeamsInSeason: Team[] = teams.filter((team) => {
                const teamUids: string[] = Array.from(
                    new Set(
                        matchDays
                            .filter((matchDay) => matchDay.seasonUid === siteData.seasonUid)
                            .map((matchDay) => [matchDay.teamAUid, matchDay.teamBUid])
                            .flat(1)
                    )
                );
                return teamUids.includes(team.uid);
            });
            const tempPlayersInSeason: Player[] = players.filter((player) => {
                const playerUidsInSeason: string[] = tempTeamsInSeason.map((team) => team.players).flat(1);
                return playerUidsInSeason.includes(player.uid);
            });
            setPlayersInSeason([...tempPlayersInSeason]);
        }
    }, [players, matchDays, siteData, teams]);

    React.useEffect(() => {
        if (playersInSeason.length > 0 && siteData && attribute && teams) {
            const tempTableData: LeagueLeader[] = createLeagueLeadersTable(
                playersInSeason,
                siteData.seasonUid,
                attribute,
                teams
            );
            setTableData(tempTableData);
        }
    }, [playersInSeason, siteData, attribute, teams]);

    return (
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} size='small' aria-label='a dense table'>
                <TableHead>
                    <TableRow>
                        <TableCell>Position</TableCell>
                        <TableCell align='left'>Player</TableCell>
                        <TableCell align='left'>Team</TableCell>
                        <TableCell align='left'>Country</TableCell>
                        <TableCell align='left'>{attribute}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {tableData.map((row) => (
                        <TableRow key={row.position} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell component='th' scope='row'>
                                {row.position}
                            </TableCell>
                            <TableCell align='left'>
                                <div style={{ display: 'flex', alignItems: 'center', gap: 12 }}>
                                    <img width={20} src={row.image} alt={row.name} />
                                    <Typography variant='caption'>{row.name}</Typography>
                                </div>
                            </TableCell>
                            <TableCell align='left'>
                                <div style={{ display: 'flex', alignItems: 'center', gap: 12 }}>
                                    <img width={20} src={row.team?.image} alt={row.team?.name} />

                                    <Typography variant='caption'>{row.team?.name}</Typography>
                                </div>
                            </TableCell>
                            <TableCell align='left'>
                                <div style={{ display: 'flex', alignItems: 'center', gap: 12 }}>
                                    <img
                                        loading='lazy'
                                        src={`https://flagcdn.com/80x60/${row?.country?.code.toLowerCase()}.png`}
                                        alt={`${row?.country?.code.toLowerCase() || ''}`}
                                        draggable='false'
                                        width={20}
                                    />
                                    <Typography variant='caption'>{row.country?.name}</Typography>
                                </div>
                            </TableCell>
                            <TableCell align='left'>
                                <Typography variant='caption'>{row.value}</Typography>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};
