import React from 'react';
import { AuthenticatedUser, CustomAlertProps, Player, UpdateUserServerResponse, UserRoles } from '../../../types';
import { Container, Grid, Typography } from '@mui/material';
import { initialAlertState } from '../../../utils/consts';
import { LoadingOverlay } from '../../../components/LoadingOverlay';
import { CustomAlert } from '../../../components/CustomAlert';
import { CustomMaterialTable } from '../../../components/CustomMaterialTable';
import { useAppDispatch, useAppSelector } from '../../../store/store';
import { updateUser as updateUserAsync } from '../../../utils/actions';
import { UserAuth } from '../../../context/AuthContext';

export const UserManager = () => {
    const { user } = UserAuth();
    const dispatch = useAppDispatch();
    const users = useAppSelector((state) => state.user.users);
    const players = useAppSelector((state) => state.player.players);

    const [unusedPlayers, setUnusedPlayers] = React.useState<Player[]>([]);
    const [customAlertState, setCustomAlertState] = React.useState<CustomAlertProps>({
        ...initialAlertState,
        closeAlert: () => handleCloseAlert(),
    });
    const [loading, setLoading] = React.useState<boolean>(true);

    React.useEffect(() => {
        if (users && players) {
            setLoading(false);
            const usedPlayerUids: string[] = users.map((user) => user.playerUid);
            const tempUnusedPlayers: Player[] = players.filter((player) => !usedPlayerUids.includes(player.uid));
            setUnusedPlayers(tempUnusedPlayers);
        }
    }, [users, players]);

    const handleCloseAlert = () => {
        setCustomAlertState((prev) => {
            prev = { ...prev };
            prev.open = false;
            return prev;
        });
    };

    const handleUpdateUser = async (userData: AuthenticatedUser) => {
        try {
            const action = await dispatch(
                updateUserAsync({
                    userId: userData.uid,
                    user: { ...userData, email: users?.find((u) => u.uid === userData.uid)?.email || '' },
                })
            );
            const data: UpdateUserServerResponse = action.payload as UpdateUserServerResponse;
            if (data.success) {
                setCustomAlertState((prev) => {
                    prev = { ...prev };
                    prev.open = true;
                    prev.message = data.message;
                    prev.severity = 'success';
                    return prev;
                });
            }
        } catch (error: any) {
            console.error('Error updating user:', error.message);
            setCustomAlertState((prev) => {
                prev = { ...prev };
                prev.open = true;
                prev.message = error.message;
                prev.severity = 'error';
                return prev;
            });
        }
        return true;
    };

    return (
        <Container maxWidth='xl'>
            {loading ? (
                <LoadingOverlay message='Loading users.' />
            ) : (
                <>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Typography variant='h4'>Signed Up Users</Typography>
                            <CustomMaterialTable
                                // @ts-ignore
                                data={
                                    users?.filter(
                                        (dataUser) => user.email !== dataUser.email && dataUser.role !== UserRoles.ADMIN
                                    ) || []
                                }
                                ignoredKeys={[]}
                                path='/admin-dashboard/users'
                                keysOrder={['role', 'playerUid', 'email', 'uid']}
                                updateData={handleUpdateUser}
                                exportDisabled
                                enableRowSelection={false}
                                dropdownOptionsMap={{
                                    role: [UserRoles.ADMIN, UserRoles.PLAYER, UserRoles.USER],
                                    playerUid: unusedPlayers.map((player) => player.uid),
                                }}
                            />
                        </Grid>
                    </Grid>
                    <CustomAlert {...customAlertState} />
                </>
            )}
        </Container>
    );
};
