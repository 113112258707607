import { Card, CardContent, Typography, Grid } from '@mui/material';
import React from 'react';
import { MatchDay, Player, Team } from '../types';
import { calculatePlayerPoints } from '../utils/helpers';
import { SwiperCarousel } from '../utils/renderers/SwiperCarousel';
import nullAvatar from '../assets/null_avatar.png';
import positions from '../data/positions.json';

/**
 * Get the ISO week number of a date.
 * @param {Date} date - The date to get the week number for.
 * @return {number} - The ISO week number.
 */
const getWeekNumber = (date: Date): number => {
    const tempDate = new Date(date.getFullYear(), 0, 1);
    const dayOfWeek = tempDate.getDay() || 7; // ISO weeks start on Monday
    tempDate.setDate(tempDate.getDate() + 1 - dayOfWeek);
    const numberOfDays = Math.floor((date.getTime() - tempDate.getTime()) / (24 * 60 * 60 * 1000));
    return Math.ceil((numberOfDays + 1) / 7);
};

interface TOTWPRops {
    matchDays: MatchDay[] | null;
    players: Player[] | null;
    teams: Team[] | null;
}

// Function to get the start and end date of a week for a given year and week number
const getWeekDateRange = (year: number, weekNumber: number): string => {
    // Start of the week (Monday)
    const startOfYear = new Date(year, 0, 1);
    const dayOfWeek = startOfYear.getDay() || 7; // ISO weeks start on Monday
    const startOfWeek = new Date(startOfYear);
    startOfWeek.setDate(startOfYear.getDate() + (weekNumber - 1) * 7 - dayOfWeek + 1);

    // End of the week (Sunday)
    const endOfWeek = new Date(startOfWeek);
    endOfWeek.setDate(startOfWeek.getDate() + 6);

    // Format the dates
    const options: any = { month: 'short', day: 'numeric' };
    const startDate = startOfWeek.toLocaleDateString('en-US', options);
    const endDate = endOfWeek.toLocaleDateString('en-US', options);

    return `Week of ${startDate} - ${endDate}`;
};

const getCurrentWeek = (matchDays: MatchDay[]): number => {
    if (!matchDays.length) return 1;
    const today = new Date();
    const sortedMatchDays = [...matchDays].sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());
    const latestMatchDay = sortedMatchDays.find((match) => new Date(match.date) <= today);
    if (!latestMatchDay) return 1;
    const latestMatchDate = new Date(latestMatchDay.date);
    const weekNumber = getWeekNumber(latestMatchDate);
    return weekNumber;
};

export const TOTW = ({ matchDays, players, teams }: TOTWPRops): JSX.Element => {
    const [teamOfTheWeek, setTeamOfTheWeek] = React.useState<any>({});
    const [weekRangeText, setWeekRangeText] = React.useState<string>('');

    React.useEffect(() => {
        if (players && matchDays) {
            // Determine the current week number
            const currentWeek = getCurrentWeek(matchDays);
            // Calculate the week date range
            const year = new Date().getFullYear();
            const weekRange = getWeekDateRange(year, currentWeek);
            setWeekRangeText(weekRange);
            console.log('currentWeek', currentWeek);

            // Create a map from matchId to MatchDay
            const matchIdToDayMap = new Map<string, MatchDay>();
            matchDays.forEach((day) => matchIdToDayMap.set(day.uid, day));

            // Calculate points for each player in the current week
            const playersWithPoints = players.map((player) => {
                const playerStats = player.stats.find(
                    (stat) =>
                        stat.seasonUid ===
                        matchDays.find((day) => getWeekNumber(new Date(day.date)) === currentWeek)?.seasonUid
                );

                if (playerStats) {
                    const weeklyStats = playerStats.matchDays.filter((match) => {
                        const matchDay = matchIdToDayMap.get(match.matchId);
                        if (matchDay) {
                            const matchDate = new Date(matchDay.date);
                            const matchWeek = getWeekNumber(matchDate);
                            console.log('matchwweek', matchWeek);
                            return matchWeek === currentWeek;
                        }
                        return false;
                    });

                    const totalPoints = weeklyStats.reduce(
                        (total, match) =>
                            total +
                            calculatePlayerPoints({
                                goals: parseInt(match.goals as string),
                                assists: parseInt(match.assists as string),
                                nutMegs: parseInt(match.nutMegs as string),
                                blocks: parseInt(match.blocks as string),
                            }),
                        0
                    );
                    return { ...player, totalPoints };
                }

                return { ...player, totalPoints: 0 };
            });

            console.log('playersWithPoints', playersWithPoints);

            // Get the top player for each position
            const topPlayers: Record<string, Player | null> = {};

            positions.forEach((position) => {
                const playersForPosition = playersWithPoints.filter((player) => player.position === position);
                if (playersForPosition.length > 0) {
                    const topPlayer = playersForPosition.reduce(
                        (max, player) => (player.totalPoints > max.totalPoints ? player : max),
                        playersForPosition[0]
                    );
                    topPlayers[position] = topPlayer;
                }
            });

            setTeamOfTheWeek(topPlayers);
        }
    }, [matchDays, players]);

    return (
        <>
            <Typography variant='h4'>TOTW </Typography>
            <Card>
                <CardContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant='subtitle1'>{weekRangeText}</Typography>
                            <SwiperCarousel
                                paginationDotColor='black'
                                slidesPerView={1}
                                pagination={true}
                                breakpoints={{}}
                                delay={2000}
                            >
                                {positions.map((position) => {
                                    const player = teamOfTheWeek[position];
                                    return (
                                        <div key={position}>
                                            <br />
                                            <Typography variant='subtitle2' gutterBottom>
                                                <b>{position}</b>
                                            </Typography>
                                            {player ? (
                                                <Grid container spacing={1}>
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        style={{
                                                            display: 'flex',
                                                            flexDirection: 'row',
                                                            gap: 6,
                                                            alignItems: 'center',
                                                            // justifyContent: 'center',
                                                        }}
                                                    >
                                                        <Typography variant='body2'>{player.name} </Typography>
                                                        <div style={{ display: 'flex', alignItems: 'center', gap: 6 }}>
                                                            <img
                                                                src={
                                                                    teams?.find((team) =>
                                                                        team.players.includes(player.uid)
                                                                    )?.image || ''
                                                                }
                                                                width={20}
                                                                alt={`${player?.country?.code.toLowerCase() || ''}`}
                                                            />
                                                            <img
                                                                width={20}
                                                                loading='lazy'
                                                                src={`https://flagcdn.com/80x60/${player?.country?.code.toLowerCase()}.png`}
                                                                alt={`${player?.country?.code.toLowerCase() || ''}`}
                                                                draggable='false'
                                                            />
                                                        </div>
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        style={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            gap: '6px',
                                                            // justifyContent: 'center',
                                                        }}
                                                    ></Grid>
                                                    <Grid item xs={12}>
                                                        <Typography variant='body2'>
                                                            <b>Points:</b> {player.totalPoints}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <img
                                                            alt={player.name}
                                                            style={{ borderRadius: 10 }}
                                                            src={player.image || nullAvatar}
                                                            width={100}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            ) : (
                                                <Typography variant='body2'>No player for this position</Typography>
                                            )}
                                        </div>
                                    );
                                })}
                            </SwiperCarousel>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </>
    );
};
