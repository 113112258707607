import React from 'react';
import ReactCardFlip from 'react-card-flip';
import { Button, Card, CardContent, Divider, Grid } from '@mui/material';

interface FlipCardProps {
    front: JSX.Element;
    back: JSX.Element;
    frontButtonText: string;
    backButtonText: string;
    flipEnabled: boolean;
}

export const FlipCard = (props: FlipCardProps): JSX.Element => {
    const [isFlipped, setIsFlipped] = React.useState<boolean>(false);
    return (
        <ReactCardFlip isFlipped={isFlipped} flipDirection='horizontal'>
            {/* front */}
            <Card>
                <CardContent>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            {props.front}
                        </Grid>
                        <Grid item xs={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                disabled={!props.flipEnabled}
                                fullWidth
                                variant='contained'
                                onClick={() => setIsFlipped(!isFlipped)}
                            >
                                {props.frontButtonText}
                            </Button>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>

            {/* back */}
            <Card>
                <CardContent>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            {props.back}
                        </Grid>
                        <Grid item xs={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                disabled={!props.flipEnabled}
                                fullWidth
                                variant='contained'
                                onClick={() => setIsFlipped(!isFlipped)}
                            >
                                {props.backButtonText}
                            </Button>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </ReactCardFlip>
    );
};
