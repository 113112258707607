import {
    AuthenticatedUser,
    CustomAlertProps,
    FantasySession,
    FantasyTeam,
    News,
    Player,
    SeasonPayload,
    Team,
    UserRoles,
    VotingSession,
} from '../types';

export const initialAuthenticatedUserData: AuthenticatedUser = {
    uid: '',
    role: UserRoles.USER,
    email: '',
    playerUid: '',
};

export const initialAlertState: CustomAlertProps = {
    open: false,
    severity: 'information',
    message: 'Initial State.',
    closeAlert: () => {},
};

export const initialNewPlayerState: Player = {
    name: '',
    email: '',
    uid: '',
    image: '',
    country: {
        name: '',
        code: '',
    },
    position: '',
    stats: [],
    cardStyle: '',
    FIFAStats: {
        overall: 60,
        pace: 60,
        defense: 60,
        dribbling: 60,
        physical: 60,
        pass: 60,
        shot: 60,
    },
};

export const initialNewTeamState: Team = {
    name: '',
    image: '',
    uid: '',
    players: [],
    color: '',
};

export const initialNewNewsState: News = {
    text: '',
    uid: '',
    date: '',
};

export const initialNewSeasonState: SeasonPayload = {
    matches: [],
    teams: [],
    date: '',
};

export const initialNewVotingSessionState: VotingSession = {
    startDate: '',
    endDate: '',
    seasonUid: '',
    uid: '',
    status: 'closed',
    playersWhoVoted: [],
    playersWhoGotVotedOn: [],
};

export const initialNewFantasyTeamState: FantasyTeam = {
    teamName: '',
    ownerUid: '',
    uid: '',
    seasonUid: '',
    weeks: [],
};

export const initialnewFantasySessionState: FantasySession = {
    startDate: '',
    endDate: '',
    seasonUid: '',
    uid: '',
    status: 'open',
    weekNumber: 1,
    teamCreationStatus: 'closed',
};
