import { IconButton, Tooltip } from '@mui/material';
import { MRT_TableOptions, MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import { Player, Team } from '../types';
import React from 'react';
import { Box } from '@mui/system';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

interface TableData {
    id: string;
    playerName: string;
    playerUid: string;
    goals: string;
    assists: string;
    nutMegs: string;
    blocks: string;
}

interface TableProps {
    teams: Team[];
    playersInMatch: Player[];
    updateRowFromBackend?: (playerData: any) => void;
    enableEditing?: boolean;
    hidePlayerUid?: boolean;
}

interface TableData {
    id: string;
    playerName: string;
    playerUid: string;
    goals: string;
    assists: string;
    nutMegs: string;
    blocks: string;
    mp: string;
}

export const PlayerStatsMaterialTable = ({
    teams,
    playersInMatch,
    updateRowFromBackend,
    enableEditing = true,
    hidePlayerUid = false,
}: TableProps) => {
    const [tableData, setTableData] = React.useState<TableData[]>([]);

    React.useEffect(() => {
        if (playersInMatch.length > 0) {
            const tempTableData: TableData[] = playersInMatch.map((player: any, index: number) => {
                return {
                    id: index.toString(),
                    playerName: player.name,
                    playerUid: player.uid,
                    goals: player.stats.goals || '0',
                    assists: player.stats.assists || '0',
                    nutMegs: player.stats.nutMegs || '0',
                    blocks: player.stats.blocks || '0',
                    mp: player.stats.mp || '0',
                };
            });
            setTableData(tempTableData);
        }
    }, [playersInMatch]);

    const columns = [
        {
            accessorKey: 'id',
            header: 'Id',
            enableEditing: false,
            size: 80,
        },
        {
            accessorKey: 'playerName',
            header: 'Player Name',
            enableEditing: false,
            size: 80,
        },
        {
            accessorKey: 'playerUid',
            header: 'Player UID',
            enableEditing: false,

            size: 80,
        },
        {
            accessorKey: 'goals',
            header: 'Goals',
            enableEditing: true,
            size: 80,
        },
        {
            accessorKey: 'assists',
            header: 'Assists',
            enableEditing: true,
            size: 80,
        },
        {
            accessorKey: 'nutMegs',
            header: 'Nutmegs',
            enableEditing: true,
            size: 80,
        },
        {
            accessorKey: 'blocks',
            header: 'Blocks',
            enableEditing: true,
            size: 80,
        },
        {
            accessorKey: 'mp',
            header: 'Match Played',
            enableEditing: true,
            size: 80,
        },
    ];

    const handleSavePlayerStats: MRT_TableOptions<TableData>['onEditingRowSave'] = async ({ values, table }) => {
        console.log('values', values);
        if (updateRowFromBackend && teams) {
            updateRowFromBackend(values);
        }
        table.setEditingRow(null);
    };

    const table = useMaterialReactTable({
        // @ts-ignore
        columns,
        data: tableData,
        enableRowSelection: false,
        columnFilterDisplayMode: 'popover',
        paginationDisplayMode: 'pages',
        enableFullScreenToggle: false,
        enableDensityToggle: false,
        initialState: {
            density: 'compact',
            columnPinning: { right: ['Details'] },
            columnVisibility: { playerUid: !hidePlayerUid },
        },
        positionToolbarAlertBanner: 'bottom',
        enableColumnPinning: true,
        getRowId: (row) => row.id,
        // createDisplayMode: 'modal',
        editDisplayMode: 'row',
        onEditingRowSave: handleSavePlayerStats,
        enableEditing,

        muiTopToolbarProps: {
            sx: {
                background: 'white',
            },
        },
        muiBottomToolbarProps: {
            sx: {
                background: 'white',
            },
        },
        muiTableProps: {
            sx: {
                backgroundColor: 'white !important',
            },
        },
        muiTableBodyCellProps: {
            sx: {
                background: 'white',
            },
        },
        muiTableHeadCellProps: {
            sx: {
                background: 'white',
            },
        },
        renderRowActions: ({ row, table }) => (
            <Box sx={{ display: 'flex', gap: '1rem' }}>
                <Tooltip title='Edit'>
                    <IconButton onClick={() => table.setEditingRow(row)}>
                        <EditIcon />
                    </IconButton>
                </Tooltip>
                <Tooltip title='Delete'>
                    <IconButton color='error' disabled onClick={() => {}}>
                        <DeleteIcon />
                    </IconButton>
                </Tooltip>
            </Box>
        ),
        // renderTopToolbarCustomActions: ({ table }) => (
        //     <Button
        //         variant='contained'
        //         onClick={() => {
        //             table.setCreatingRow(true); //simplest way to open the create row modal with no default values
        //             //or you can pass in a row object to set default values with the `createRow` helper function
        //             // table.setCreatingRow(
        //             //   createRow(table, {
        //             //     //optionally pass in default values for the new row, useful for nested data or other complex scenarios
        //             //   }),
        //             // );
        //         }}
        //     >
        //         Create New Match
        //     </Button>
        // ),
    });
    return <MaterialReactTable table={table} />;
};
