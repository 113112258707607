import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { MatchDay, Standing, Team } from '../types';
import { calculateStandings } from '../utils/helpers';
import { Card, Skeleton, Typography } from '@mui/material';

interface StandingsProps {
    teams: Team[] | null;
    matchDays: MatchDay[] | null;
}

export const Standings = ({ teams, matchDays }: StandingsProps): JSX.Element => {
    const [tableData, setTableData] = React.useState<Standing[]>([]);
    const [dataLoaded, setDataLoaded] = React.useState<boolean>(false);

    React.useEffect(() => {
        if (teams && matchDays) {
            const tempTableData: Standing[] = calculateStandings(matchDays, teams);
            setTableData(tempTableData);
            setDataLoaded(true);
        } else setDataLoaded(false);
    }, [teams, matchDays]);

    return !dataLoaded ? (
        <Skeleton />
    ) : (
        <TableContainer component={Card}>
            <Table sx={{ minWidth: 650 }} size='small' aria-label='a dense table'>
                <TableHead>
                    <TableRow>
                        <TableCell>Position</TableCell>
                        <TableCell align='left'>Team</TableCell>
                        <TableCell align='left'>Points</TableCell>
                        <TableCell align='left'>Pld</TableCell>
                        <TableCell align='left'>W</TableCell>
                        <TableCell align='left'>D</TableCell>
                        <TableCell align='left'>L</TableCell>
                        <TableCell align='left'>GF</TableCell>
                        <TableCell align='left'>GA</TableCell>
                        <TableCell align='left'>GD</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {tableData.map((row, index: number) => (
                        <TableRow
                            key={index}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            style={{
                                background: index === 0 ? row.teamColor : '',
                            }}
                        >
                            <TableCell component='th' scope='row'>
                                {row.position}
                            </TableCell>
                            <TableCell style={{ display: 'flex', alignItems: 'center', gap: 12 }}>
                                <img
                                    width={30}
                                    src={(teams && teams.find((team) => team.uid === row.teamUid)?.image) || ''}
                                    alt={row.team}
                                />{' '}
                                <Typography variant='caption'>{row.team}</Typography>
                            </TableCell>
                            <TableCell align='left'>
                                <Typography variant='caption'>{row.points}</Typography>
                            </TableCell>
                            <TableCell align='left'>
                                <Typography variant='caption'>{row.pld}</Typography>
                            </TableCell>
                            <TableCell align='left'>
                                <Typography variant='caption'>{row.w}</Typography>
                            </TableCell>
                            <TableCell align='left'>
                                <Typography variant='caption'>{row.d}</Typography>
                            </TableCell>
                            <TableCell align='left'>
                                <Typography variant='caption'>{row.l}</Typography>
                            </TableCell>
                            <TableCell align='left'>
                                <Typography variant='caption'>{row.gf}</Typography>
                            </TableCell>
                            <TableCell align='left'>
                                <Typography variant='caption'>{row.ga}</Typography>
                            </TableCell>
                            <TableCell align='left'>
                                <Typography variant='caption'>{row.gd}</Typography>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};
