import React from 'react';
import {
    AddPlayerServerResponse,
    CustomAlertProps,
    FIFAStats,
    Player,
    UpdatePlayerServerResponse,
} from '../../../types';
import {
    Autocomplete,
    Box,
    Button,
    Card,
    CardContent,
    Container,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Modal,
    Select,
    TextField,
    Typography,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { initialAlertState, initialNewPlayerState } from '../../../utils/consts';
import { LoadingOverlay } from '../../../components/LoadingOverlay';
import { modalStyle } from '../../../styles/theme';
import { CustomAlert } from '../../../components/CustomAlert';
import { CustomMaterialTable } from '../../../components/CustomMaterialTable';
import { useAppDispatch, useAppSelector } from '../../../store/store';
import { addPlayer as addPlayerAsync, updatePlayer as updatePlayerAsync } from '../../../utils/actions';
import { emailTest } from '../../../utils/regex';
import { LoadingButton } from '../../../components/LoadingButton';
import countries from '../../../data/countries.json';
import positions from '../../../data/positions.json';
import cardStyles from '../../../data/cardStyles.json';

export interface FormattedTableData {
    name: string;
    email: string;
    uid: string;
    image: string;
    country: string;
    position: string;
    cardStyle: string;
    FIFAStats: FIFAStats;
    stats: {
        seasonUid: string;
        matchDays: {
            goals: number;
            nutMegs: number;
            blocks: number;
            assists: number;
            matchId: string;
            mp: number;
        }[];
    }[];
    canVote: boolean;
    hasVoted: boolean;
}

export const Players = () => {
    const dispatch = useAppDispatch();
    const players = useAppSelector((state) => state.player.players);

    const [customAlertState, setCustomAlertState] = React.useState<CustomAlertProps>({
        ...initialAlertState,
        closeAlert: () => handleCloseAlert(),
    });
    const [newPlayer, setNewPlayer] = React.useState<Player>(initialNewPlayerState);
    const [loading, setLoading] = React.useState<boolean>(false);
    const [addButtonIsProcessing, setAddButtonIsProcessing] = React.useState<boolean>(false);
    const [isNameValid, setIsNameValid] = React.useState<boolean>(true);
    const [isEmailValid, setIsEmailValid] = React.useState<boolean>(true);
    const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false);
    const [submitButtonDisabled, setSubmitButtonDisabled] = React.useState<boolean>(true);

    React.useEffect(() => {
        if (players) {
            setLoading(false);
        }
    }, [players]);

    React.useEffect(() => {
        const name = newPlayer.name.trim();
        const emailValid = emailTest(newPlayer.email.trim());
        const position: boolean = !!newPlayer.position;
        const country: boolean = !!newPlayer.country;

        setIsNameValid(!!name);
        setIsEmailValid(emailValid);

        if (name !== '' && emailValid && position && country) {
            setSubmitButtonDisabled(false);
        } else setSubmitButtonDisabled(true);
    }, [newPlayer]);

    const handleModalClose = (): void => {
        setNewPlayer(initialNewPlayerState);
        setIsModalOpen(false);
    };

    const handleCloseAlert = () => {
        setCustomAlertState((prev) => {
            prev = { ...prev };
            prev.open = false;
            return prev;
        });
    };

    const handleAddPlayer = async (e: React.FormEvent<HTMLFormElement>): Promise<void> => {
        e.preventDefault();

        if (players) {
            const currentPlayersEmails: string[] = players.map((player) => player.email);
            if (currentPlayersEmails.includes(newPlayer.email)) {
                window.alert('This player email already exists. Player not added.');
                return;
            }
        }

        try {
            setAddButtonIsProcessing(true);
            const action = await dispatch(addPlayerAsync(newPlayer));
            const data: AddPlayerServerResponse = action.payload as AddPlayerServerResponse;
            if (data.success) {
                setNewPlayer(initialNewPlayerState);
                setCustomAlertState((prev) => {
                    prev = { ...prev };
                    prev.open = true;
                    prev.message = data.message;
                    prev.severity = 'success';
                    return prev;
                });
                setIsModalOpen(false);
            }
        } catch (error: any) {
            console.error('Error adding player:', error.message);
            setCustomAlertState((prev) => {
                prev = { ...prev };
                prev.open = true;
                prev.message = error.message;
                prev.severity = 'error';
                return prev;
            });
        } finally {
            setAddButtonIsProcessing(false);
        }
    };

    const handleUpdatePlayer = async (playerData: FormattedTableData) => {
        try {
            const countryCode: string = playerData.country.split(' - ')[1];
            const completePlayerData: Player = {
                ...players?.find((player) => player.uid === playerData.uid),
                ...playerData,
                country: countries.find((country) => country.code === countryCode) || { name: '', code: '' },
            };
            const action = await dispatch(updatePlayerAsync({ playerId: playerData.uid, player: completePlayerData }));
            const data: UpdatePlayerServerResponse = action.payload as UpdatePlayerServerResponse;
            if (data.success) {
                setCustomAlertState((prev) => {
                    prev = { ...prev };
                    prev.open = true;
                    prev.message = data.message;
                    prev.severity = 'success';
                    return prev;
                });
            }
        } catch (error: any) {
            console.error('Error updating player:', error.message);
            setCustomAlertState((prev) => {
                prev = { ...prev };
                prev.open = true;
                prev.message = error.message;
                prev.severity = 'error';
                return prev;
            });
        }
        return true;
    };

    const CreatePlayerComponent = (): JSX.Element => {
        return (
            <Grid item xs={12}>
                <Typography variant='h6'>Create Player</Typography>
                <Card>
                    <CardContent>
                        <form onSubmit={handleAddPlayer}>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        required
                                        error={!isNameValid}
                                        size='small'
                                        label='Player Name'
                                        value={newPlayer.name}
                                        onChange={(e: any) => setNewPlayer({ ...newPlayer, name: e.target.value })}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        required
                                        error={!isEmailValid}
                                        size='small'
                                        label='Email'
                                        type='email'
                                        value={newPlayer.email}
                                        onChange={(e) =>
                                            setNewPlayer({ ...newPlayer, email: e.target.value.toLocaleLowerCase() })
                                        }
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl fullWidth style={{ maxHeight: 200 }}>
                                        <InputLabel>Card Style</InputLabel>
                                        <Select
                                            fullWidth
                                            size='small'
                                            label='Card Style'
                                            style={{ maxHeight: 200 }}
                                            value={newPlayer.cardStyle}
                                            onChange={(e) => {
                                                setNewPlayer({
                                                    ...newPlayer,
                                                    cardStyle: e.target.value,
                                                });
                                            }}
                                            MenuProps={{
                                                PaperProps: {
                                                    style: {
                                                        maxHeight: 200,
                                                    },
                                                },
                                            }}
                                        >
                                            {cardStyles.map((cardStyle, index: number) => {
                                                return (
                                                    <MenuItem key={index} value={cardStyle}>
                                                        {cardStyle}
                                                    </MenuItem>
                                                );
                                            })}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl fullWidth>
                                        <InputLabel>Position</InputLabel>
                                        <Select
                                            fullWidth
                                            size='small'
                                            label='Position'
                                            value={newPlayer.position}
                                            onChange={(e) => {
                                                setNewPlayer({
                                                    ...newPlayer,
                                                    position: e.target.value,
                                                });
                                            }}
                                        >
                                            {positions.map((position, index: number) => {
                                                return (
                                                    <MenuItem key={index} value={position}>
                                                        {position}
                                                    </MenuItem>
                                                );
                                            })}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <Autocomplete
                                        fullWidth
                                        id='country-select-demo'
                                        sx={{ width: 300 }}
                                        options={countries}
                                        onChange={(event: any, newValue: any) => {
                                            setNewPlayer({
                                                ...newPlayer,
                                                country: newValue,
                                            });
                                        }}
                                        autoHighlight
                                        getOptionLabel={(option) => option.name}
                                        renderOption={(props, option) => (
                                            <Box component='li' sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                                <img
                                                    loading='lazy'
                                                    width='20'
                                                    srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                                                    src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                                                    alt=''
                                                />
                                                {option.name} ({option.code})
                                            </Box>
                                        )}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label='Choose a country'
                                                inputProps={{
                                                    ...params.inputProps,
                                                    autoComplete: 'new-password', // disable autocomplete and autofill
                                                }}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <LoadingButton
                                        disabled={submitButtonDisabled}
                                        isProcessing={addButtonIsProcessing}
                                        type='submit'
                                        text='Add Player'
                                    />
                                </Grid>
                            </Grid>
                        </form>
                    </CardContent>
                </Card>
            </Grid>
        );
    };

    return (
        <Container maxWidth='xl'>
            {loading ? (
                <LoadingOverlay message='Loading players.' />
            ) : (
                <>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Typography variant='h4'>Players</Typography>
                            <CustomMaterialTable
                                // @ts-ignore
                                data={
                                    players?.map((player) => {
                                        return {
                                            ...player,
                                            country: player.country.name,
                                        };
                                    }) || []
                                }
                                ignoredKeys={[]}
                                path='/admin-dashboard/players'
                                keysOrder={['name', 'email', 'position', 'country', 'role', 'uid']}
                                updateData={handleUpdatePlayer}
                                exportDisabled
                                enableRowSelection={false}
                                dropdownOptionsMap={{
                                    position: positions,
                                    country: countries.map((country) => `${country.name} - ${country.code}`),
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                variant='contained'
                                startIcon={<AddIcon />}
                                onClick={() => {
                                    setIsModalOpen(true);
                                }}
                            >
                                Create Player
                            </Button>
                        </Grid>
                    </Grid>
                    <Modal open={isModalOpen} onClose={handleModalClose}>
                        <Box sx={modalStyle}>{CreatePlayerComponent()}</Box>
                    </Modal>
                    <CustomAlert {...customAlertState} />
                </>
            )}
        </Container>
    );
};
