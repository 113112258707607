interface Crop {
    x: number;
    y: number;
    width: number;
    height: number;
}

/**
 * Crops image.
 * @param {string} imageSrc - The source of the image.
 * @param {Crop} pixelCrop - The source of the image.
 * @return {Promise} - The image.
 */
export const getCroppedImg = (imageSrc: string, pixelCrop: Crop, width: number, height: number): Promise<string> => {
    return new Promise((resolve, reject) => {
        const image = new Image();
        image.src = imageSrc;
        image.onload = () => {
            const canvas = document.createElement('canvas');
            canvas.width = width;
            canvas.height = height;
            const ctx = canvas.getContext('2d');

            if (ctx) {
                ctx.drawImage(image, pixelCrop.x, pixelCrop.y, pixelCrop.width, pixelCrop.height, 0, 0, width, height);

                resolve(canvas.toDataURL('image/png'));
            } else {
                reject('Could not create canvas context');
            }
        };
        image.onerror = (ev) => reject(ev);
    });
};
