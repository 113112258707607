import React from 'react';
import { AddNewsServerResponse, CustomAlertProps, News, UpdateNewsServerResponse } from '../../../types';
import {
    // Autocomplete,
    Box,
    Button,
    Card,
    CardContent,
    Container,
    Grid,
    Modal,
    TextField,
    Typography,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { initialAlertState, initialNewNewsState } from '../../../utils/consts';
import { LoadingOverlay } from '../../../components/LoadingOverlay';
import { modalStyle } from '../../../styles/theme';
import { CustomAlert } from '../../../components/CustomAlert';
import { useAppDispatch, useAppSelector } from '../../../store/store';
import { addNews as addNewsAsync, updateNews as updateNewsAsync } from '../../../utils/actions';
import { LoadingButton } from '../../../components/LoadingButton';
import { CustomMaterialTable } from '../../../components/CustomMaterialTable';

export const NewsRoute = () => {
    const dispatch = useAppDispatch();
    const news = useAppSelector((state) => state.news.news);

    const [customAlertState, setCustomAlertState] = React.useState<CustomAlertProps>({
        ...initialAlertState,
        closeAlert: () => handleCloseAlert(),
    });
    const [newNews, setNewNews] = React.useState<News>(initialNewNewsState);
    const [loading, setLoading] = React.useState<boolean>(false);
    const [addButtonIsProcessing, setAddButtonIsProcessing] = React.useState<boolean>(false);
    const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false);
    const [submitButtonDisabled, setSubmitButtonDisabled] = React.useState<boolean>(true);

    React.useEffect(() => {
        if (news) {
            setLoading(false);
        }
    }, [news]);

    React.useEffect(() => {
        const text = newNews.text.trim();
        if (text !== '') {
            setSubmitButtonDisabled(false);
        } else setSubmitButtonDisabled(true);
    }, [newNews]);

    const handleModalClose = (): void => {
        setNewNews(initialNewNewsState);
        setIsModalOpen(false);
    };

    const handleCloseAlert = () => {
        setCustomAlertState((prev) => {
            prev = { ...prev };
            prev.open = false;
            return prev;
        });
    };

    const handleAddNews = async (e: React.FormEvent<HTMLFormElement>): Promise<void> => {
        e.preventDefault();
        try {
            setAddButtonIsProcessing(true);
            const action = await dispatch(addNewsAsync({ ...newNews, date: new Date().toISOString() }));
            const data: AddNewsServerResponse = action.payload as AddNewsServerResponse;
            if (data.success) {
                setNewNews(initialNewNewsState);
                setCustomAlertState((prev) => {
                    prev = { ...prev };
                    prev.open = true;
                    prev.message = data.message;
                    prev.severity = 'success';
                    return prev;
                });
                setIsModalOpen(false);
            }
        } catch (error: any) {
            console.error('Error adding news:', error.message);
            setCustomAlertState((prev) => {
                prev = { ...prev };
                prev.open = true;
                prev.message = error.message;
                prev.severity = 'error';
                return prev;
            });
        } finally {
            setAddButtonIsProcessing(false);
        }
    };

    const handleUpdateNews = async (newsData: News) => {
        try {
            const completeNewsData = { ...news?.find((team) => team.uid === newsData.uid), ...newsData };
            const action = await dispatch(updateNewsAsync({ newsId: newsData.uid, news: completeNewsData }));
            const data: UpdateNewsServerResponse = action.payload as UpdateNewsServerResponse;
            if (data.success) {
                setCustomAlertState((prev) => {
                    prev = { ...prev };
                    prev.open = true;
                    prev.message = data.message;
                    prev.severity = 'success';
                    return prev;
                });
            }
        } catch (error: any) {
            console.error('Error updating news:', error.message);
            setCustomAlertState((prev) => {
                prev = { ...prev };
                prev.open = true;
                prev.message = error.message;
                prev.severity = 'error';
                return prev;
            });
        }
        return true;
    };

    const CreateNewsComponent = (): JSX.Element => {
        return (
            <Grid item xs={12}>
                <Typography variant='h6'>Create News</Typography>
                <Card>
                    <CardContent>
                        <form onSubmit={handleAddNews}>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        required
                                        size='small'
                                        label='News'
                                        value={newNews.text}
                                        onChange={(e: any) => setNewNews({ ...newNews, text: e.target.value })}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <LoadingButton
                                        disabled={submitButtonDisabled}
                                        isProcessing={addButtonIsProcessing}
                                        type='submit'
                                        text='Add News'
                                    />
                                </Grid>
                            </Grid>
                        </form>
                    </CardContent>
                </Card>
            </Grid>
        );
    };

    return (
        <Container maxWidth='xl'>
            {loading ? (
                <LoadingOverlay message='Loading news.' />
            ) : (
                <>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Typography variant='h4'>News</Typography>
                            <CustomMaterialTable
                                data={news || []}
                                ignoredKeys={[]}
                                path='/admin-dashboard/news'
                                keysOrder={['date', 'text', 'uid']}
                                updateData={handleUpdateNews}
                                exportDisabled
                                enableRowSelection={false}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                variant='contained'
                                startIcon={<AddIcon />}
                                onClick={() => {
                                    setIsModalOpen(true);
                                }}
                            >
                                Create News
                            </Button>
                        </Grid>
                    </Grid>
                    <Modal open={isModalOpen} onClose={handleModalClose}>
                        <Box sx={modalStyle}>{CreateNewsComponent()}</Box>
                    </Modal>
                    <CustomAlert {...customAlertState} />
                </>
            )}
        </Container>
    );
};
