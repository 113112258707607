import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

export const Footer = ({ theme }: any): JSX.Element => {
    const style = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textlAlign: 'center',
        marginTop: '5%',
        marginBottom: '6%',
    };
    return (
        <Grid container spacing={3} style={style}>
            <Grid item xs={12} style={{ textAlign: 'center' }}>
                <Typography variant='caption' color={'text.secondary'} style={{ fontSize: '11px' }}>
                    Copyright &copy; 2025 - Built by <a href='mailto: deanriversdeveloper@gmail.com'>Dean Rivers</a>.
                </Typography>
            </Grid>
        </Grid>
    );
};
