import React, { useEffect, useState } from 'react';
import Lottie from 'lottie-react';
import Data from '../assets/bodymovin/data.json';

import '../styles/LoadingScreen.css';

export const LoadingScreen = ({ onFinish }: any) => {
    const [fadeOut, setFadeOut] = useState<boolean>(false);
    const [removeElement, setRemoveElement] = useState<boolean>(false);

    useEffect(() => {
        // Simulate loading time
        const timeout = setTimeout(() => {
            setFadeOut(true);
            setTimeout(() => {
                setRemoveElement(true);
            }, 1000);
        }, 4000);
        return () => clearTimeout(timeout);
    }, []);

    const handleAnimationFinish = () => {
        if (fadeOut) {
            onFinish();
        }
    };

    return (
        <div
            className={`loading-screen`}
            style={{
                transition: 'opacity 1s ease-in-out',
                opacity: fadeOut ? 0 : 1,
                display: `${removeElement ? 'none' : 'flex'}`,
            }}
        >
            <Lottie animationData={Data} onComplete={handleAnimationFinish} />
        </div>
    );
};
