import React from 'react';
import {
    Card,
    CardContent,
    Container,
    Divider,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    Typography,
} from '@mui/material';
import { LoadingOverlay } from '../../../components/LoadingOverlay';
import { useAppSelector } from '../../../store/store';
import { Player, Season } from '../../../types';
import { useParams } from 'react-router-dom';
import D3BarChart from '../../../components/D3/d3BarChart';
import D3Legend from '../../../components/D3/d3Legend';
import D3MultiLineChart from '../../../components/D3/d3MultiLineChart';
import { D3MultiLineChartLegend } from '../../../components/D3/d3LegendMultiLineChart';
import { transformPlayerStatsToMultiLineData } from '../../../utils/helpers';

interface PlayerStats {
    mp: number;
    goals: number;
    assists: number;
    blocks: number;
    nutMegs: number;
}

export const PublicPlayerDetails = (): JSX.Element => {
    const { playerUid } = useParams<{ playerUid: string }>();
    const [loading, setLoading] = React.useState(true);
    const [player, setPlayer] = React.useState<Player>();
    const [playerStats, setPlayerStats] = React.useState<PlayerStats>();
    const [filteredMatchDays, setFilteredMatchDays] = React.useState<any[]>([]);
    const [selectedSeason, setSelectedSeaon] = React.useState<Season | 'all'>('all');
    const [selectedTimeGranularity, setSelectedTimeGranularity] = React.useState<'year' | 'month'>('year');
    const [generalStatsBarChartData, setGeneralStatsBarChartData] = React.useState<{ name: string; value: number }[]>(
        []
    );
    const [performanceDataMultiLineChart, setPerformanceDataMultiLineChart] = React.useState<
        { date: string; [key: string]: any }[]
    >([]);
    const [playerAverageStats, setPlayerAverageStats] = React.useState<{
        goals: number;
        assists: number;
        nutMegs: number;
        blocks: number;
    }>({ goals: 0, assists: 0, nutMegs: 0, blocks: 0 });

    const teams = useAppSelector((state) => state.team.teams);
    const seasons = useAppSelector((state) => state.season.seasons);
    const matchDays = useAppSelector((state) => state.matchDay.matchDays);
    const players = useAppSelector((state) => state.player.players);

    React.useEffect(() => {
        if (teams && playerUid && seasons && matchDays && players) {
            setLoading(false);
            const tempPlayer: Player | undefined = players.find((player) => player.uid === playerUid);
            setPlayer(tempPlayer);
        } else setLoading(true);
    }, [teams, playerUid, seasons, matchDays, players]);

    React.useEffect(() => {
        if (selectedSeason && matchDays && seasons && teams && player) {
            setPerformanceDataMultiLineChart([]);
            console.log('matchDays', matchDays);
            console.log('player', player);
            const filteredPlayerStatsForCurrentSeason = player.stats.filter((season) => {
                if (selectedSeason === 'all') return season;
                return season.seasonUid === selectedSeason.uid;
            });
            const filteredMatchDays = filteredPlayerStatsForCurrentSeason.map((f) => f.matchDays).flat(1);
            console.log('filteredMatchDays', filteredMatchDays);
            const filteredMatchDayGoalsArr = filteredMatchDays.map((f) => parseInt(f.goals as string));
            const filteredMatchDayAssistsArr = filteredMatchDays.map((f) => parseInt(f.assists as string));
            const filteredMatchDayBlocksArr = filteredMatchDays.map((f) => parseInt(f.blocks as string));
            const filteredMatchDayNutMegsArr = filteredMatchDays.map((f) => parseInt(f.nutMegs as string));

            const tempPlayerStats: PlayerStats = {
                mp: filteredPlayerStatsForCurrentSeason.map((stat) => stat.matchDays).length,
                goals:
                    filteredMatchDayGoalsArr.length > 0
                        ? filteredMatchDayGoalsArr.reduce((prev, curr) => prev + curr)
                        : 0,
                assists:
                    filteredMatchDayAssistsArr.length > 0
                        ? filteredMatchDayAssistsArr.reduce((prev, curr) => prev + curr)
                        : 0,
                blocks:
                    filteredMatchDayBlocksArr.length > 0
                        ? filteredMatchDayBlocksArr.reduce((prev, curr) => prev + curr)
                        : 0,
                nutMegs:
                    filteredMatchDayNutMegsArr.length > 0
                        ? filteredMatchDayNutMegsArr.reduce((prev, curr) => prev + curr)
                        : 0,
            };
            setFilteredMatchDays(filteredMatchDays);
            setPlayerAverageStats({
                goals: Math.round((tempPlayerStats.goals / filteredMatchDays.length) * 10) / 10,
                assists: Math.round((tempPlayerStats.assists / filteredMatchDays.length) * 10) / 10,
                nutMegs: Math.round((tempPlayerStats.nutMegs / filteredMatchDays.length) * 10) / 10,
                blocks: Math.round((tempPlayerStats.blocks / filteredMatchDays.length) * 10) / 10,
            });
            setPlayerStats(tempPlayerStats);
        }
    }, [selectedSeason, matchDays, teams, seasons, player]);

    React.useEffect(() => {
        if (player && selectedSeason && selectedTimeGranularity && matchDays) {
            setPerformanceDataMultiLineChart(
                transformPlayerStatsToMultiLineData(matchDays, player, selectedSeason, selectedTimeGranularity).sort(
                    (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()
                )
            );
        }
    }, [player, selectedSeason, selectedTimeGranularity, matchDays]);

    React.useEffect(() => {
        if (playerStats) {
            let tempGeneralStatsBarChartData: { name: string; value: number }[] = [];
            for (const property in playerStats) {
                if (property !== 'mp') {
                    tempGeneralStatsBarChartData.push({
                        name: property,
                        // @ts-ignore
                        value: playerStats[property],
                    });
                }
            }
            setGeneralStatsBarChartData(tempGeneralStatsBarChartData);
        }
    }, [playerStats]);

    return (
        <Container maxWidth='xl'>
            {loading ? (
                <LoadingOverlay message='Loading player details.' />
            ) : (
                <>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Typography variant='h4'>{player?.name}</Typography>
                            <div style={{ display: 'flex', alignItems: 'center', gap: 6 }}>
                                <img
                                    width={20}
                                    loading='lazy'
                                    src={`https://flagcdn.com/80x60/${player?.country?.code.toLowerCase()}.png`}
                                    alt={`${player?.country?.code.toLowerCase() || ''}`}
                                    draggable='false'
                                />
                                <Typography variant='caption'>{player?.country.name}</Typography>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', gap: 6 }}>
                                <img
                                    width={20}
                                    loading='lazy'
                                    src={teams?.find((team) => team.players.includes(player?.uid || ''))?.image}
                                    alt={`${
                                        teams
                                            ?.find((team) => team.players.includes(player?.uid || ''))
                                            ?.name.toLowerCase() || ''
                                    }`}
                                    draggable='false'
                                />
                                <Typography variant='caption'>
                                    {teams?.find((team) => team.players.includes(player?.uid || ''))?.name ||
                                        'Undrafted'}
                                </Typography>
                            </div>
                        </Grid>
                        <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
                            <img
                                style={{
                                    background: '#eee',
                                    borderRadius: 20,
                                }}
                                width={250}
                                src={player?.image}
                                alt={player?.name}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Typography variant='h5'>Player Data</Typography>
                            <FormControl fullWidth style={{ maxHeight: 200 }}>
                                <InputLabel>Season Number</InputLabel>
                                <Select
                                    // disabled
                                    fullWidth
                                    size='small'
                                    label='Season'
                                    style={{ maxHeight: 200 }}
                                    value={
                                        selectedSeason === 'all' ? 'all' : String(selectedSeason?.seasonNumber) || null
                                    }
                                    onChange={(e) => {
                                        if (e.target.value === 'all') setSelectedSeaon(e.target.value);
                                        const foundSeason: Season | undefined = seasons?.find(
                                            (season) => String(season.seasonNumber) === String(e.target.value)
                                        );
                                        if (foundSeason) setSelectedSeaon(foundSeason);
                                    }}
                                    MenuProps={{
                                        PaperProps: {
                                            style: {
                                                maxHeight: 200,
                                            },
                                        },
                                    }}
                                >
                                    <MenuItem value={'all'}>All</MenuItem>
                                    {seasons
                                        ?.slice()
                                        .sort((a, b) => a.seasonNumber - b.seasonNumber)
                                        .map((season: Season, index: number) => {
                                            return (
                                                <MenuItem key={index} value={season.seasonNumber}>
                                                    {season.seasonNumber}
                                                </MenuItem>
                                            );
                                        })}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <Card>
                                <CardContent>
                                    <Typography variant='overline'>Averages</Typography>
                                    <br />
                                    <Typography variant='subtitle2'>
                                        Total games: <b>{filteredMatchDays.length}</b>
                                    </Typography>
                                    <Typography variant='subtitle2'>
                                        Goals per game: <b>{playerAverageStats.goals}</b>
                                    </Typography>
                                    <Typography variant='subtitle2'>
                                        Assists per game: <b>{playerAverageStats.assists}</b>
                                    </Typography>
                                    <Typography variant='subtitle2'>
                                        Nutmegs per game: <b>{playerAverageStats.nutMegs}</b>
                                    </Typography>
                                    <Typography variant='subtitle2'>
                                        Blocks per game: <b>{playerAverageStats.blocks}</b>
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12}>
                            <Divider light />
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} lg={6} xl={4}>
                            <Card variant='outlined'>
                                <CardContent>
                                    <Typography variant='overline'>Player Stats</Typography>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                            <D3BarChart data={generalStatsBarChartData} />
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                            <D3Legend data={generalStatsBarChartData} />
                                        </Grid>
                                    </Grid>
                                    <div
                                        style={{ display: 'flex', gap: '24px', justifyContent: 'space-between' }}
                                    ></div>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={6} xl={4}>
                            <Card variant='outlined'>
                                <CardContent>
                                    <Grid container spacing={1}>
                                        <Grid item xs={6}>
                                            <Typography variant='overline'>Performance</Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Select
                                                fullWidth
                                                size='small'
                                                label='Time Granularity'
                                                style={{ maxHeight: 200 }}
                                                value={selectedTimeGranularity}
                                                onChange={(e) =>
                                                    setSelectedTimeGranularity(e.target.value as 'year' | 'month')
                                                }
                                                MenuProps={{
                                                    PaperProps: {
                                                        style: {
                                                            maxHeight: 200,
                                                        },
                                                    },
                                                }}
                                            >
                                                <MenuItem value={'year'}>Year</MenuItem>
                                                <MenuItem value={'month'}>Month</MenuItem>
                                            </Select>
                                        </Grid>
                                    </Grid>

                                    <Grid container spacing={3}>
                                        <Grid item xs={12}></Grid>
                                        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                            <D3MultiLineChart
                                                data={performanceDataMultiLineChart}
                                                metrics={['goals', 'assists', 'nutMegs', 'blocks']}
                                                granularity={selectedTimeGranularity}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                            <D3MultiLineChartLegend
                                                metrics={['goals', 'assists', 'nutMegs', 'blocks']}
                                            />
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </>
            )}
        </Container>
    );
};
