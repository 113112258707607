import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Season } from '../../types';

interface SeasonState {
    seasons: null | Season[];
}

const initialState: SeasonState = {
    seasons: null,
};

export const SeasonSlice = createSlice({
    name: 'season',
    initialState,
    reducers: {
        setSeasons: (state, action: PayloadAction<Season[]>) => {
            state.seasons = action.payload;
        },
    },
});

export default SeasonSlice.reducer;
export const { setSeasons } = SeasonSlice.actions;
