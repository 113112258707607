import { NavLink } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useAppSelector } from '../store/store';

export const TeamsBar = (): JSX.Element => {
    const teams = useAppSelector((state) => state.team.teams);
    const [height, setHeight] = useState<number>(50); // Initial height
    const [imageOpacity, setImageOpacity] = useState<number>(1); // Initial image opacity
    const [lastScrollTop, setLastScrollTop] = useState<number>(0); // Last scroll position

    useEffect(() => {
        const handleScroll = () => {
            const currentScrollTop = window.scrollY;
            const newScrollDirection = currentScrollTop > lastScrollTop ? 'down' : 'up';

            // Calculate the new height and image opacity based on scroll position and direction
            const newHeight = newScrollDirection === 'down' ? Math.max(5, 50 - currentScrollTop / 5) : 50;
            const newImageOpacity = newScrollDirection === 'down' ? Math.max(0, 1 - currentScrollTop / 200) : 1;

            setHeight(newHeight);
            setImageOpacity(newImageOpacity);
            setLastScrollTop(currentScrollTop);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [lastScrollTop]);

    return (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                overflowX: 'scroll',
                height: `${height}px`, // Set the height dynamically
                transition: 'height 0.3s', // Add transition for smooth animation
            }}
        >
            <div style={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'space-between' }}>
                {teams?.map((team, index: number) => {
                    return (
                        <div
                            style={{
                                display: 'flex',
                                padding: '6px',
                                background: team.color,
                                justifyContent: 'center',
                                width: '100%',
                            }}
                            key={index}
                        >
                            <NavLink
                                key={index}
                                to={`/teams/${team.uid}`}
                                style={{ display: 'flex', alignItems: 'center' }}
                            >
                                <img
                                    key={index}
                                    alt={team.name}
                                    width={40}
                                    src={team.image}
                                    style={{ cursor: 'pointer', opacity: imageOpacity }}
                                />
                            </NavLink>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};
