import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MatchDay } from '../../types';

interface MatchDayState {
    matchDays: null | MatchDay[];
}

const initialState: MatchDayState = {
    matchDays: null,
};

export const MatchDaysSlice = createSlice({
    name: 'matchDays',
    initialState,
    reducers: {
        setMatchDays: (state, action: PayloadAction<MatchDay[]>) => {
            state.matchDays = action.payload;
        },
    },
});

export default MatchDaysSlice.reducer;
export const { setMatchDays } = MatchDaysSlice.actions;
