import React, { useEffect, useRef, useState } from 'react';
import * as d3 from 'd3';
import { d3GraphConfig, universalColorScheme, useResizeObserver } from '../../utils/d3';
import { axisStyle } from '../../styles/d3';

interface LineChartDataPoint {
    date: string;
    [key: string]: any;
}

type LineChartData = LineChartDataPoint[];

interface MultiLineChartProps {
    data: LineChartData;
    metrics: string[];
    yAxisTickFormat?: (value: number) => string;
    granularity: string;
}

const D3MultiLineChart: React.FC<MultiLineChartProps> = ({ data, metrics, yAxisTickFormat, granularity }) => {
    const svgRef = useRef<SVGSVGElement>(null);
    const wrapperRef = useRef<HTMLDivElement>(null);
    const dimensions: any = useResizeObserver(wrapperRef);
    const [tooltipText, setTooltipText] = useState<string | null>(null);
    const [tooltipPosition, setTooltipPosition] = useState<{ x: number; y: number }>({ x: 0, y: 0 });
    const tooltipOffset = { x: 12, y: -3 };
    const [tooltipVisible, setTooltipVisible] = useState<boolean>(false);

    useEffect(() => {
        if (!data || !svgRef.current || !dimensions) return;

        const width = dimensions.width;
        const height = dimensions.height;
        const margin = { top: 20, right: 20, bottom: 50, left: 40 };

        const svg = d3.select(svgRef.current);

        svg.attr('width', width).attr('height', height);

        // Define scales
        const xScale = d3
            .scaleTime()
            .domain(d3.extent(data, (d) => new Date(d.date)) as [Date, Date])
            .range([margin.left, width - margin.right]);

        const yMax = d3.max(data, (d) => d3.max(metrics, (metric) => d[metric])) || 0;
        const yScale = d3
            .scaleLinear()
            .domain([0, yMax])
            .range([height - margin.bottom, margin.top]);

        // Update axes with transitions
        const xAxis = svg.select('.x-axis');
        const yAxis = svg.select('.y-axis');

        if (xAxis.empty()) {
            svg.append('g')
                .attr('class', 'x-axis')
                .attr('transform', `translate(0,${height - margin.bottom})`)
                .style('color', axisStyle.textColor);
        }

        if (yAxis.empty()) {
            svg.append('g')
                .attr('class', 'y-axis')
                .attr('transform', `translate(${margin.left},0)`)
                .style('color', axisStyle.textColor);
        }

        xAxis
            .transition()
            .duration(750)
            .call(
                // @ts-ignore
                d3
                    .axisBottom(xScale)
                    .tickSizeOuter(0)
                    .tickSize(0)
                    .tickValues(data.map((d) => new Date(d.date)))
                    // @ts-ignore
                    .tickFormat(granularity === 'year' ? d3.utcFormat('%Y') : d3.utcFormat('%b %d, %Y'))
            );

        // Rotate x-axis text
        xAxis
            .selectAll('text')
            .style('font-family', axisStyle.fontFamily)
            .style('font-size', axisStyle.fontSize)
            .style('color', axisStyle.textColor)
            .attr('transform', 'rotate(30)')
            .attr('text-anchor', 'start');

        yAxis
            .transition()
            .duration(750)
            .call(
                // @ts-ignore
                d3
                    .axisLeft(yScale)
                    .ticks(yMax < 10 ? yMax : 10)
                    .tickSizeOuter(0)
                    .tickSize(0)
                    .tickFormat(d3.format('.0f'))
            );

        // Update lines with transitions
        const colorScale = d3.scaleOrdinal(universalColorScheme).domain(metrics);

        metrics.forEach((metric) => {
            const lineGenerator = d3
                .line<LineChartDataPoint>()
                .x((d) => xScale(new Date(d.date)))
                .y((d) => yScale(d[metric]));

            const line: any = svg.selectAll(`.line-${metric}`).data([data]);

            line.enter()
                .append('path')
                .attr('class', `line-${metric}`)
                .merge(line)
                .transition()
                .duration(750)
                .attr('fill', 'none')
                .attr('stroke', colorScale(metric))
                .attr('stroke-width', 2)
                .attr('d', lineGenerator);

            line.exit().remove();
        });

        // Tooltip logic
        svg.selectAll('.hover-line').remove();

        const hoverLineGroup = svg.append('g').attr('class', 'hover-line-group').style('display', 'none');

        const hoverCircles = metrics.map((metric) =>
            hoverLineGroup
                .append('circle')
                .attr('class', `hover-circle hover-circle-${metric}`)
                .attr('r', 4)
                .attr('stroke', colorScale(metric))
                .attr('fill', '#fff')
                .attr('stroke-width', 2)
                .style('opacity', 0)
        );

        const hoverLine = hoverLineGroup
            .append('line')
            .attr('class', 'hover-line')
            .attr('y1', margin.top)
            .attr('y2', height - margin.bottom)
            .attr('stroke', '#ddd')
            .attr('stroke-width', '1px');

        svg.append('rect')
            .attr('class', 'overlay')
            .attr('width', width - margin.left - margin.right)
            .attr('height', height - margin.top - margin.bottom)
            .attr('x', margin.left)
            .attr('y', margin.top)
            .style('opacity', 0)
            .on('mouseover', () => {
                hoverLineGroup.style('display', null);
            })
            .on('mouseout', () => {
                hoverLineGroup.style('display', 'none');
                setTooltipVisible(false);
            })
            .on('mousemove', function (event) {
                const mouseX = d3.pointer(event)[0];
                const xDate = xScale.invert(mouseX);
                const closestData = data.reduce((prev, curr) =>
                    Math.abs(new Date(curr.date).getTime() - xDate.getTime()) <
                    Math.abs(new Date(prev.date).getTime() - xDate.getTime())
                        ? curr
                        : prev
                );

                hoverLine.attr('x1', xScale(new Date(closestData.date))).attr('x2', xScale(new Date(closestData.date)));

                hoverCircles.forEach((circle, index) => {
                    const metric = metrics[index];
                    circle
                        .attr('cx', xScale(new Date(closestData.date)))
                        .attr('cy', yScale(closestData[metric]))
                        .style('opacity', 1);
                });

                const [cursorX, cursorY] = d3.pointer(event);
                const formatDate = granularity === 'year' ? d3.utcFormat('%Y') : d3.utcFormat('%a %b %d, %Y');

                setTooltipPosition({ x: cursorX, y: cursorY });
                setTooltipText(
                    `<b>${formatDate(new Date(closestData.date))}</b><br/>${metrics
                        .map((metric) => `${metric}: ${closestData[metric]}`)
                        .join('<br/>')}`
                );
                setTooltipVisible(true);
            });
    }, [data, dimensions, metrics, yAxisTickFormat, granularity]);

    return (
        <div
            ref={wrapperRef}
            style={{
                marginBottom: '2rem',
                maxHeight: d3GraphConfig.maxHeight,
                minHeight: d3GraphConfig.minHeight,
                position: 'relative',
            }}
        >
            <svg ref={svgRef}></svg>
            {tooltipVisible && (
                <div
                    style={{
                        position: 'absolute',
                        fontSize: d3GraphConfig.tooltipFontSize,
                        top: tooltipPosition.y + tooltipOffset.y,
                        left: tooltipPosition.x + tooltipOffset.x,
                        background: 'rgba(255, 255, 255, 0.9)',
                        padding: '0.5rem',
                        borderRadius: '4px',
                        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                        pointerEvents: 'none',
                    }}
                    dangerouslySetInnerHTML={{ __html: tooltipText || '' }}
                />
            )}
        </div>
    );
};

export default D3MultiLineChart;
