import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { VotingSession } from '../../types';

interface VotingSessionsState {
    votingSessions: null | VotingSession[];
}

const initialState: VotingSessionsState = {
    votingSessions: null,
};

export const VotingSessionsSlice = createSlice({
    name: 'votingSessions',
    initialState,
    reducers: {
        setVotingSessions: (state, action: PayloadAction<VotingSession[]>) => {
            state.votingSessions = action.payload;
        },
    },
});

export default VotingSessionsSlice.reducer;
export const { setVotingSessions } = VotingSessionsSlice.actions;
