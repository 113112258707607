import { Button, IconButton, TextField, Tooltip } from '@mui/material';
import { MRT_Row, MRT_TableOptions, MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import { MatchDay, Team } from '../types';
import React from 'react';
import { Box } from '@mui/system';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

interface TableProps {
    teams: Team[];
    teamsInSeason: string[];
    updateSeason: (matches: MatchDay[]) => void;
    initialMatchDays?: MatchDay[];
    deleteRowFromBackend?: (matchId: string) => void;
    updateRowFromBackend?: (matchData: MatchDay) => void;
}

interface TableData {
    teamA: string;
    teamAGoals: string;
    teamB: string;
    teamBGoals: string;
    date: string;
    id: string;
    matchId: string;
}

export const SeasonSchedulemMaterialTable = ({
    teams,
    teamsInSeason,
    updateSeason,
    initialMatchDays,
    deleteRowFromBackend,
    updateRowFromBackend,
}: TableProps) => {
    const [tableData, setTableData] = React.useState<TableData[]>([]);

    React.useEffect(() => {
        if (initialMatchDays) {
            const tempTableData: TableData[] = initialMatchDays
                .map((matchDay, index: number) => {
                    return {
                        teamA: teams.find((team) => team.uid === matchDay.teamAUid)?.name || '',
                        teamAGoals: matchDay.teamAGoals,
                        teamB: teams.find((team) => team.uid === matchDay.teamBUid)?.name || '',
                        teamBGoals: matchDay.teamBGoals,
                        date: matchDay.date,
                        id: index.toString(),
                        matchId: matchDay.uid,
                    };
                })
                .sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime())
                .map((matchDay, index: number) => ({ ...matchDay, id: index.toString() }));

            setTableData(tempTableData);
        }
    }, [initialMatchDays, teams]);

    React.useEffect(() => {
        const transformedData: MatchDay[] = tableData.map((data) => {
            return {
                uid: data.matchId || '',
                seasonUid: '',
                teamAUid: teams.find((team) => team.name === data.teamA)?.uid || '',
                teamBUid: teams.find((team) => team.name === data.teamB)?.uid || '',
                teamAGoals: data.teamAGoals,
                teamBGoals: data.teamBGoals,
                date: data.date !== '' ? new Date(data.date).toISOString() : '',
            };
        });
        updateSeason(transformedData);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tableData, teams]);

    const DateTimeEditor = ({ props }: any) => {
        // const editingRowId = table.getState().editingRow?.id;
        const formatDateForInput = (dateString: string) => {
            const date = new Date(dateString);
            const year = date.getFullYear();
            const month = (date.getMonth() + 1).toString().padStart(2, '0');
            const day = date.getDate().toString().padStart(2, '0');
            const hours = date.getHours().toString().padStart(2, '0');
            const minutes = date.getMinutes().toString().padStart(2, '0');
            return `${year}-${month}-${day}T${hours}:${minutes}`;
        };

        const handleDateUpdate = (value: string, index: number) => {
            const tempTableData = [...tableData];
            tempTableData[index].date = value;
            setTableData(tempTableData.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()));
        };

        return (
            <TextField
                type='datetime-local'
                value={formatDateForInput(tableData[props.row.index]?.date || '')}
                onChange={(e) => handleDateUpdate(e.target.value, props.row.index)}
                InputLabelProps={{
                    shrink: true,
                }}
                // disabled={props.row.id !== editingRowId}
                disabled={false}
            />
        );
    };

    const columns = [
        {
            accessorKey: 'id',
            header: 'Id',
            enableEditing: false,
            size: 80,
        },
        {
            accessorKey: 'matchId',
            header: 'MatchId',
            enableEditing: false,
            size: 80,
        },
        {
            accessorKey: 'teamA',
            header: 'Team A',
            editVariant: 'select',
            editSelectOptions: teams.filter((team) => teamsInSeason.includes(team.uid)).map((team) => team.name),
            size: 100,
        },
        {
            accessorKey: 'teamB',
            header: 'Team B',
            editVariant: 'select',
            editSelectOptions: teams.filter((team) => teamsInSeason.includes(team.uid)).map((team) => team.name),
            size: 100,
        },
        {
            accessorKey: 'teamAGoals',
            header: 'Team A Goals',
            size: 20,
        },
        {
            accessorKey: 'teamBGoals',
            header: 'Team B Goals',
            size: 20,
        },
        {
            accessorKey: 'date',
            header: 'Date',
            editVariant: 'custom',
            enableEditing: false,
            Cell: (props: any) => <DateTimeEditor props={props} isEditing={props.row.isEditing} />,
            // Cell: (props: any) => {
            //     if (column.accessorKey === 'date') {
            //         return <DateTimeEditor {...props} isEditing={props.row.isEditing} />;
            //     }
            //     return column.Cell(props);
            // },
            size: 200,
        },
    ];

    const handleCreateMatchDay: MRT_TableOptions<TableData>['onCreatingRowSave'] = async ({ values, table }) => {
        setTableData([...tableData, values].sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()));
        table.setCreatingRow(null);
    };

    const handleSaveMatchDay: MRT_TableOptions<TableData>['onEditingRowSave'] = async ({ values, table }) => {
        if (updateRowFromBackend && teams) {
            const transformedData: MatchDay = {
                uid: values.matchId,
                teamAUid: teams.find((team) => team.name === values.teamA)?.uid || '',
                teamAGoals: values.teamAGoals,
                teamBUid: teams.find((team) => team.name === values.teamB)?.uid || '',
                teamBGoals: values.teamBGoals,
                seasonUid: '',
                date: values.date,
            };
            updateRowFromBackend(transformedData);
        }
        table.setEditingRow(null);
    };

    const openDeleteConfirmModal = (row: MRT_Row<TableData>) => {
        if (window.confirm('Are you sure you want to delete this match?')) {
            const tempTableData = tableData;
            tempTableData.splice(row.index, 1);
            setTableData([...tempTableData]);
            if (row.original.matchId && deleteRowFromBackend) {
                deleteRowFromBackend(row.original.matchId);
            }
        }
    };

    const table = useMaterialReactTable({
        // @ts-ignore
        columns,
        data: tableData,
        enableRowSelection: false,
        columnFilterDisplayMode: 'popover',
        paginationDisplayMode: 'pages',
        enableFullScreenToggle: false,
        enableDensityToggle: false,
        initialState: { density: 'compact', columnPinning: { right: ['Details'] } },
        positionToolbarAlertBanner: 'bottom',
        enableColumnPinning: true,
        getRowId: (row) => row.id,
        createDisplayMode: 'modal',
        editDisplayMode: 'row',
        onEditingRowSave: handleSaveMatchDay,
        enableEditing: true,
        muiTopToolbarProps: {
            sx: {
                background: 'white',
            },
        },
        muiBottomToolbarProps: {
            sx: {
                background: 'white',
            },
        },
        muiTableProps: {
            sx: {
                backgroundColor: 'white !important',
            },
        },
        muiTableBodyCellProps: {
            sx: {
                background: 'white',
            },
        },
        muiTableHeadCellProps: {
            sx: {
                background: 'white',
            },
        },
        onCreatingRowSave: handleCreateMatchDay,
        renderRowActions: ({ row, table }) => (
            <Box sx={{ display: 'flex', gap: '1rem' }}>
                <Tooltip title='Edit'>
                    <IconButton onClick={() => table.setEditingRow(row)}>
                        <EditIcon />
                    </IconButton>
                </Tooltip>
                <Tooltip title='Delete'>
                    <IconButton color='error' onClick={() => openDeleteConfirmModal(row)}>
                        <DeleteIcon />
                    </IconButton>
                </Tooltip>
            </Box>
        ),
        renderTopToolbarCustomActions: ({ table }) => (
            <Button
                variant='contained'
                onClick={() => {
                    table.setCreatingRow(true); //simplest way to open the create row modal with no default values
                    //or you can pass in a row object to set default values with the `createRow` helper function
                    // table.setCreatingRow(
                    //   createRow(table, {
                    //     //optionally pass in default values for the new row, useful for nested data or other complex scenarios
                    //   }),
                    // );
                }}
            >
                Create New Match
            </Button>
        ),
    });
    return <MaterialReactTable table={table} />;
};
