import React from 'react';
import * as d3 from 'd3';
import ResizeObserver from 'resize-observer-polyfill';

export const d3GraphConfig = {
    maxHeight: 200,
    minHeight: 200,
    transitionDuration: 1500,
    tickSize: 5,
    tooltipFontSize: 12,
};

export const useResizeObserver = (ref: any) => {
    const [dimensions, setDimensions] = React.useState(null);

    React.useEffect(() => {
        const observeTarget = ref.current;
        const resizeObserver = new ResizeObserver((entries) => {
            entries.forEach((entry: any) => {
                setDimensions(entry.contentRect);
            });
        });
        resizeObserver.observe(observeTarget);
        return () => {
            resizeObserver.unobserve(observeTarget);
        };
    }, [ref]);

    return dimensions;
};

export const universalColorScheme = d3.schemeSet2;

export const createGaugeData = (data: any[], valueToExtract: string): { text: string; value: number }[] => {
    const countMap: Record<string, number> = {};

    data.forEach((item) => {
        const value = item[valueToExtract];
        countMap[value] = (countMap[value] || 0) + 1;
    });

    const formattedData: { text: string; value: number }[] = Object.entries(countMap).map(([text, value]) => ({
        text: text.toLocaleLowerCase(),
        value,
    }));

    return formattedData;
};
