import React from 'react';
import { emailTest } from '../../utils/regex';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Button, Card, CardContent, Container, TextField } from '@mui/material';
import { Link } from 'react-router-dom';
import { UserAuth } from '../../context/AuthContext';

export const ForgotPassword = (): JSX.Element => {
    const { resetPassword } = UserAuth();
    const [email, setEmail] = React.useState<string>('');
    const [emailSent, setEmailSent] = React.useState<boolean>(false);
    const [buttonDisabled, setButtonDisabled] = React.useState<boolean>(true);

    React.useEffect(() => {
        if (email && emailTest(email)) {
            setButtonDisabled(false);
        } else setButtonDisabled(true);
    }, [email]);

    const handleResetPassword = () => {
        resetPassword(email)
            .then(() => {
                setEmailSent(true);
            })
            .catch((error: Error) => {
                console.error('There was an error sending the password reset email:', error);
                alert('There was an error sending the password reset email');
            });
    };

    const EmailSentComponent = (): JSX.Element => {
        return (
            <Grid item xs={12}>
                <Typography variant='caption'>
                    If email exists in our database, you will receieve an email to reset password.
                </Typography>
            </Grid>
        );
    };

    return (
        <Container maxWidth='sm'>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant='h5'>Reset Password.</Typography>
                </Grid>
                {emailSent ? (
                    <EmailSentComponent />
                ) : (
                    <>
                        <Grid item xs={12}>
                            <Card>
                                <CardContent>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12}>
                                            <TextField
                                                size='small'
                                                fullWidth
                                                label='Email'
                                                variant='filled'
                                                type='email'
                                                onChange={(e: any) => setEmail(e.target.value as string)}
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                            }}
                                        >
                                            <Link to='/signup'>
                                                <Typography>Sign Up</Typography>
                                            </Link>
                                            <Link to='/login'>
                                                <Typography>Login</Typography>
                                            </Link>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12}>
                            <Button variant='contained' onClick={handleResetPassword} disabled={buttonDisabled}>
                                Send Reset Email
                            </Button>
                        </Grid>
                    </>
                )}
            </Grid>
        </Container>
    );
};
