import React from 'react';
import {
    AuthenticatedUser,
    CustomAlertProps,
    Player,
    PostVoteServerResponse,
    Team,
    Vote,
    VoteData,
    VotingSession,
} from '../../../types';
import { Button, Card, CardContent, Container, Grid, Modal, Typography } from '@mui/material';
import { initialAlertState } from '../../../utils/consts';
import { LoadingOverlay } from '../../../components/LoadingOverlay';
import { CustomAlert } from '../../../components/CustomAlert';
import { useAppSelector } from '../../../store/store';
import { UserAuth } from '../../../context/AuthContext';
import { VoteCard } from '../../../components/VoteCard';
import { useAppDispatch } from '../../../store/store';
import { postVote as postVoteAsync } from '../../../utils/actions';
import { Box } from '@mui/system';
import { LoadingButton } from '../../../components/LoadingButton';

const modalStyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '70%',
    maxWidth: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};

interface VoteDataInterface {
    [key: string]: VoteData;
}
type VoteDataAttribute = keyof VoteData;

export const Voting = () => {
    const { user } = UserAuth();
    const dispatch = useAppDispatch();
    const votingSessions = useAppSelector((state) => state.votingSession.votingSessions);
    const players = useAppSelector((state) => state.player.players);
    const matchDays = useAppSelector((state) => state.matchDay.matchDays);
    const teams = useAppSelector((state) => state.team.teams);
    const siteData = useAppSelector((state) => state.siteData.siteData);
    const users = useAppSelector((state) => state.user.users);

    const [customAlertState, setCustomAlertState] = React.useState<CustomAlertProps>({
        ...initialAlertState,
        closeAlert: () => handleCloseAlert(),
    });
    const [loading, setLoading] = React.useState<boolean>(false);
    const [canVote, setCanVote] = React.useState<boolean>(true);
    const [teamsInSeason, setTeamsInSeason] = React.useState<Team[]>([]);
    const [playersInSeason, setPlayersInSeason] = React.useState<Player[]>([]);
    const [player, setPlayer] = React.useState<Player | undefined>();
    const [voteCount, setVoteCount] = React.useState<number>(0);
    const [globalVoteData, setGlobalVoteData] = React.useState<VoteDataInterface>({});
    const [submitDisabled, setSubmitDisabled] = React.useState<boolean>(true);
    const [disableVoteButtons, setDisableVoteButtons] = React.useState<boolean>(false);
    const [currentVoteSession, setCurrentVoteSession] = React.useState<VotingSession>();
    const [showVoteSubmitModal, setShowVoteSubmitModal] = React.useState<boolean>(false);
    const [voteLimitReached, setVoteLimitReached] = React.useState<boolean>(false);
    const [addButtonIsProcessing, setAddButtonIsProcessing] = React.useState<boolean>(false);

    const voteMinimum: number = 1;
    const totalVotesAllowed: number = 20;

    React.useEffect(() => {
        if (votingSessions && players && siteData && teams && user && matchDays && users) {
            console.log(
                'all players',
                players,
                players.map((player) => player.email)
            );
            const seasonUid: string = siteData.seasonUid;
            const tempUser: AuthenticatedUser | undefined = users.find((u) => u.uid === user.uid);
            const tempPlayer: Player | undefined = players.find((player) => player.uid === tempUser?.playerUid);
            const tempTeamsInSeason: Team[] = teams.filter((team) => {
                const teamUids: string[] = Array.from(
                    new Set(
                        matchDays
                            .filter((matchDay) => matchDay.seasonUid === seasonUid)
                            .map((matchDay) => [matchDay.teamAUid, matchDay.teamBUid])
                            .flat(1)
                    )
                );
                return teamUids.includes(team.uid);
            });
            const tempPlayersInSeason: Player[] = players
                .filter((player) => {
                    const playerUidsInSeason: string[] = tempTeamsInSeason.map((team) => team.players).flat(1);
                    return playerUidsInSeason.includes(player.uid);
                })
                .map((player) => {
                    return player;
                });

            setTeamsInSeason([...tempTeamsInSeason]);
            setPlayersInSeason([...tempPlayersInSeason]);
            setPlayer(tempPlayer);
            setLoading(false);
        } else setLoading(true);
    }, [votingSessions, players, teams, siteData, user, matchDays, users]);

    React.useEffect(() => {
        console.log(
            'playersInSeason',
            playersInSeason,
            playersInSeason.map((player) => player.email)
        );
    }, [playersInSeason]);

    React.useEffect(() => {
        console.log('can vote', canVote);
    }, [canVote]);

    React.useEffect(() => {
        if (voteCount >= voteMinimum) {
            setSubmitDisabled(false);
        } else setSubmitDisabled(true);

        if (voteCount >= totalVotesAllowed) {
            setDisableVoteButtons(true);
        } else setDisableVoteButtons(false);
    }, [voteCount, totalVotesAllowed]);

    React.useEffect(() => {
        setVoteLimitReached(voteCount >= totalVotesAllowed ? true : false);
        if (totalVotesAllowed - voteCount === 0) {
            setShowVoteSubmitModal(true);
        }
    }, [voteCount]);

    React.useEffect(() => {
        let tempVoteCount: number = 0;
        for (const property in globalVoteData) {
            tempVoteCount += Object.values(globalVoteData[property]).filter((value) => value !== '').length;
        }
        setVoteCount(tempVoteCount);
    }, [globalVoteData]);

    const handleVoteCardChange = React.useCallback((data: VoteData, uid: VoteDataAttribute) => {
        setGlobalVoteData((prev) => {
            prev = { ...prev };
            prev[uid] = data;
            return prev;
        });
    }, []);

    React.useEffect(() => {
        if (
            player &&
            teamsInSeason.length > 0 &&
            playersInSeason?.length > 0 &&
            matchDays &&
            siteData &&
            votingSessions
        ) {
            const filteredAndSortedVotingSessions = votingSessions.filter((vs) => vs.seasonUid === siteData.seasonUid);
            const tempCurrentVotingSession = filteredAndSortedVotingSessions.filter((vs) => vs.status === 'open')[0];
            setCurrentVoteSession(tempCurrentVotingSession);
        }
    }, [teamsInSeason, playersInSeason, player, matchDays, siteData, votingSessions]);

    React.useEffect(() => {
        if (playersInSeason.length > 0 && currentVoteSession && player) {
            const tempCanVote: boolean =
                playersInSeason.map((p) => p.uid).includes(player.uid) &&
                !currentVoteSession.playersWhoVoted.includes(player.uid);
            setCanVote(tempCanVote);
        } else setCanVote(false);
    }, [playersInSeason, currentVoteSession, player]);

    const handleCloseAlert = () => {
        setCustomAlertState((prev) => {
            prev = { ...prev };
            prev.open = false;
            return prev;
        });
    };

    const handleSubmitClicked = async () => {
        const confirmContinue: boolean = window.confirm(
            totalVotesAllowed - voteCount === 0
                ? 'You are about to submit your votes for this session. This cannot be undone. Are you sure you want to continue?'
                : `You have ${totalVotesAllowed - voteCount} vote(s) left. Are you sure you want to continue?`
        );
        if (currentVoteSession && currentVoteSession.seasonUid && confirmContinue && player) {
            let votes: VoteData[] = [];
            for (let [playerUid, playerVotes] of Object.entries(globalVoteData)) {
                if (
                    playerVotes.pace !== '' ||
                    playerVotes.shot !== '' ||
                    playerVotes.pass !== '' ||
                    playerVotes.dribbling !== '' ||
                    playerVotes.defense !== '' ||
                    playerVotes.physical !== ''
                ) {
                    votes.push({
                        pace: playerVotes.pace,
                        shot: playerVotes.shot,
                        pass: playerVotes.pass,
                        dribbling: playerVotes.dribbling,
                        defense: playerVotes.defense,
                        physical: playerVotes.physical,
                        playerVotedOnUid: playerUid,
                    });
                }
            }

            let formattedVoteData: Vote = {
                voterUid: player.uid,
                votes,
                votingSessionId: currentVoteSession.uid,
            };

            try {
                setAddButtonIsProcessing(true);
                const action = await dispatch(postVoteAsync(formattedVoteData));
                const data: PostVoteServerResponse = action.payload as PostVoteServerResponse;
                if (data.success) {
                    setCustomAlertState((prev) => {
                        prev = { ...prev };
                        prev.open = true;
                        prev.message = data.message;
                        prev.severity = 'success';
                        return prev;
                    });
                    setShowVoteSubmitModal(false);
                }
            } catch (error: any) {
                console.error('Error posting vote:', error.message);
                setCustomAlertState((prev) => {
                    prev = { ...prev };
                    prev.open = true;
                    prev.message = error.message;
                    prev.severity = 'error';
                    return prev;
                });
                return true;
            } finally {
                setAddButtonIsProcessing(false);
            }
        }
    };

    const handleModalClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        event?.preventDefault();
        setShowVoteSubmitModal(false);
    };

    return (
        <Container maxWidth='xl'>
            {loading ? (
                <LoadingOverlay message='Loading data.' />
            ) : (
                <>
                    {!showVoteSubmitModal && voteLimitReached && canVote ? (
                        <Button
                            size='small'
                            color='primary'
                            variant='contained'
                            style={{ position: 'fixed', top: '20%', right: '5%', zIndex: 1000 }}
                            onClick={() => setShowVoteSubmitModal(true)}
                        >
                            Submit votes
                        </Button>
                    ) : null}
                    {canVote && (
                        <Modal
                            open={showVoteSubmitModal}
                            onClose={handleModalClose as any}
                            aria-labelledby='modal-modal-title'
                            aria-describedby='modal-modal-description'
                        >
                            <Box sx={modalStyle}>
                                <Typography id='modal-modal-title' variant='h6' component='h2'>
                                    Lock in this week's votes!
                                </Typography>
                                <LoadingButton
                                    disabled={submitDisabled}
                                    isProcessing={addButtonIsProcessing}
                                    type=''
                                    text='Submit Votes'
                                    onClick={handleSubmitClicked}
                                />
                            </Box>
                        </Modal>
                    )}

                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Typography variant='h4'>Voting</Typography>
                            <Typography variant='caption'>{currentVoteSession?.uid}</Typography>
                            {!canVote && (
                                <Typography variant='subtitle1'>
                                    You are not allowed to vote until the next session.
                                </Typography>
                            )}
                        </Grid>
                        {canVote &&
                            teamsInSeason &&
                            playersInSeason
                                ?.filter((p) => p.uid !== player?.uid)
                                .map((playerInSeason, index: number) => {
                                    return (
                                        <Grid item xs={12} sm={4} md={4} lg={3} xl={3} key={index}>
                                            <Card>
                                                <CardContent>
                                                    <VoteCard
                                                        uid={playerInSeason.uid}
                                                        name={playerInSeason.name}
                                                        email={playerInSeason.email}
                                                        image={playerInSeason.image}
                                                        teamName={
                                                            teamsInSeason.find((team) =>
                                                                team.players.includes(playerInSeason.uid)
                                                            )?.name || ''
                                                        }
                                                        teamImage={
                                                            teamsInSeason.find((team) =>
                                                                team.players.includes(playerInSeason.uid)
                                                            )?.image || ''
                                                        }
                                                        disabledVoteButtons={disableVoteButtons}
                                                        handleVoteCardChange={handleVoteCardChange}
                                                    />
                                                </CardContent>
                                            </Card>
                                        </Grid>
                                    );
                                })}
                        {canVote && (
                            <Grid item xs={12}>
                                <LoadingButton
                                    disabled={submitDisabled}
                                    isProcessing={addButtonIsProcessing}
                                    type=''
                                    text='Submit Votes'
                                    onClick={handleSubmitClicked}
                                />
                            </Grid>
                        )}
                        {canVote && (
                            <div
                                style={{
                                    position: 'fixed',
                                    bottom: '20px',
                                    right: '20px',
                                    backgroundColor: '#333',
                                    color: '#fff',
                                    padding: '10px 20px',
                                    borderRadius: '5px',
                                    zIndex: '999',
                                }}
                            >
                                You have {totalVotesAllowed - voteCount} vote(s) left.
                            </div>
                        )}
                    </Grid>
                    <CustomAlert {...customAlertState} />
                </>
            )}
        </Container>
    );
};
