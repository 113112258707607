import React from 'react';
import { useNavigate } from 'react-router-dom';
import { UserAuth } from '../context/AuthContext';

export const PublicRoute = ({ children }: any): JSX.Element => {
    const { user, isLoading } = UserAuth();
    const navigate = useNavigate();

    React.useEffect(() => {
        if (!isLoading && user) {
            navigate('/');
        }
    }, [user, navigate, isLoading]);

    return !user ? children : null;
};
