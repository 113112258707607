export const MUIProps: any = {
    gridSpacing: 3,
};

export const Colors: any = {
    light: {
        primary: '#000000',
        secondary: '#266f4d',
        background: '#F5F5F5',
        success: '#4CAF50',
        info: '#00a2ff',
        danger: '#FF5722',
        warning: '#FFC107',
        dark: '#0e1b20',
        light: '#aaa',
        muted: '#abafb3',
        border: '#DDDFE1',
        inverse: '#2F3D4A',
        shaft: '#333',
        dove_gray: '#d5d5d5',
        body_bg: '#f3f6f9',
        text_primary: 'black',
        paper: 'white',
        app_bar: 'white',
        ///////////////
        // Solid Color
        ///////////////
        white: '#fff',
        black: '#000',
    },
    dark: {
        primary: '#57Dcbe',
        secondary: '#f3f8e1',
        background: '#191919',
        success: '#4CAF50',
        info: '#00a2ff',
        danger: '#FF5722',
        warning: '#FFC107',
        dark: '#0e1b20',
        light: '#aaa',
        muted: '#abafb3',
        border: '#DDDFE1',
        inverse: '#2F3D4A',
        shaft: '#333',
        dove_gray: '#d5d5d5',
        body_bg: '#f3f6f9',
        text_primary: '#f3f8e1',
        paper: '#1d1d23',
        app_bar: 'rgb(29 29 35 / 70%)',
        ///////////////
        // Solid Color
        ///////////////
        white: '#fff',
        black: '#000',
    },
};

export const modalStyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 900,
    height: 600,
    overflowY: 'scroll',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    zIndex: 1000,
    borderRadius: 2,
};

export const seasonSchedulMocdalStyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 1100,
    height: 700,
    overflowY: 'scroll',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    zIndex: 1000,
    borderRadius: 2,
};

export const imageStyle = {
    borderRadius: '5px',
};
