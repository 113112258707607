import React from 'react';
import { CustomAlertProps, PostVotingSessionServerResponse, VotingSession } from '../../../types';
import {
    Button,
    Card,
    CardContent,
    Container,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Modal,
    Select,
    TextField,
    Typography,
} from '@mui/material';
import { initialAlertState, initialNewVotingSessionState } from '../../../utils/consts';
import { LoadingOverlay } from '../../../components/LoadingOverlay';
import { CustomAlert } from '../../../components/CustomAlert';
import { CustomMaterialTable } from '../../../components/CustomMaterialTable';
import { useAppDispatch, useAppSelector } from '../../../store/store';
import { formatDate } from '../../../utils/helpers';
import { postVotingSession as postVotingSessionAsync } from '../../../utils/actions';
import { LoadingButton } from '../../../components/LoadingButton';
import { Box } from '@mui/system';
import { modalStyle } from '../../../styles';
import AddIcon from '@mui/icons-material/Add';

export const VoteManager = () => {
    const dispatch = useAppDispatch();
    const votingSessions = useAppSelector((state) => state.votingSession.votingSessions);
    const siteData = useAppSelector((state) => state.siteData.siteData);
    const matchDays = useAppSelector((state) => state.matchDay.matchDays);

    const [formattedVotingSessions, setFormattedVotingSessions] = React.useState<VotingSession[]>([]);
    const [customAlertState, setCustomAlertState] = React.useState<CustomAlertProps>({
        ...initialAlertState,
        closeAlert: () => handleCloseAlert(),
    });
    const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false);
    const [loading, setLoading] = React.useState<boolean>(false);
    const [addButtonIsProcessing, setAddButtonIsProcessing] = React.useState<boolean>(false);
    const [newVotingSession, setNewVotingSession] = React.useState<VotingSession>(initialNewVotingSessionState);
    const [submitButtonDisabled, setSubmitButtonDisabled] = React.useState<boolean>(true);

    React.useEffect(() => {
        if (newVotingSession.startDate && newVotingSession.endDate) {
            setSubmitButtonDisabled(false);
        } else setSubmitButtonDisabled(true);
    }, [newVotingSession]);

    React.useEffect(() => {
        if (votingSessions && siteData && matchDays) {
            setLoading(false);
            const tempFormattedVotingSessions: VotingSession[] = votingSessions
                .filter((vs) => vs.seasonUid === siteData.seasonUid)
                .map((vs) => {
                    return {
                        ...vs,
                        startDate: formatDate(vs.startDate),
                        endDate: formatDate(vs.endDate),
                    };
                });
            setFormattedVotingSessions([...tempFormattedVotingSessions]);
        } else setLoading(true);
    }, [votingSessions, siteData, matchDays]);

    const handleCloseAlert = () => {
        setCustomAlertState((prev) => {
            prev = { ...prev };
            prev.open = false;
            return prev;
        });
    };

    const handleModalClose = (): void => {
        setNewVotingSession(initialNewVotingSessionState);
        setIsModalOpen(false);
    };

    const handleAddVotingSession = async (e: React.FormEvent<HTMLFormElement>): Promise<void> => {
        e.preventDefault();
        try {
            setAddButtonIsProcessing(true);
            const completeVotingSessionData: VotingSession = {
                ...newVotingSession,
                seasonUid: siteData?.seasonUid || '',
            };
            const action = await dispatch(postVotingSessionAsync(completeVotingSessionData));
            const data: PostVotingSessionServerResponse = action.payload as PostVotingSessionServerResponse;
            if (data.success) {
                setNewVotingSession(initialNewVotingSessionState);
                setCustomAlertState((prev) => {
                    prev = { ...prev };
                    prev.open = true;
                    prev.message = data.message;
                    prev.severity = 'success';
                    return prev;
                });
                setIsModalOpen(false);
            }
        } catch (error: any) {
            console.error('Error adding voting session:', error.message);
            setCustomAlertState((prev) => {
                prev = { ...prev };
                prev.open = true;
                prev.message = error.message;
                prev.severity = 'error';
                return prev;
            });
        } finally {
            setAddButtonIsProcessing(false);
        }
    };

    const CreateVotingSessionComponent = (): JSX.Element => {
        return (
            <Grid item xs={12}>
                <Typography variant='h6'>Create Voting Session</Typography>
                <Card>
                    <CardContent>
                        <form onSubmit={handleAddVotingSession}>
                            <Grid container spacing={3}>
                                <Grid item xs={12}></Grid>
                                <Grid item xs={12}>
                                    <FormControl fullWidth>
                                        <InputLabel>Position</InputLabel>
                                        <Select
                                            fullWidth
                                            size='small'
                                            label='Status'
                                            value={newVotingSession.status}
                                            onChange={(e) => {
                                                setNewVotingSession({
                                                    ...newVotingSession,
                                                    status: e.target.value as 'open' | 'closed',
                                                });
                                            }}
                                        >
                                            <MenuItem value='open'>Open</MenuItem>
                                            <MenuItem value='closed'>Closed</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant='subtitle2'>Start Date</Typography>
                                    <TextField
                                        type='datetime-local'
                                        value={newVotingSession.startDate}
                                        onChange={(e) => {
                                            setNewVotingSession({ ...newVotingSession, startDate: e.target.value });
                                        }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        disabled={false}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant='subtitle2'>End Date</Typography>
                                    <TextField
                                        type='datetime-local'
                                        value={newVotingSession.endDate}
                                        onChange={(e) => {
                                            setNewVotingSession({ ...newVotingSession, endDate: e.target.value });
                                        }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        disabled={false}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <LoadingButton
                                        disabled={submitButtonDisabled}
                                        isProcessing={addButtonIsProcessing}
                                        type='submit'
                                        text='Add Voting Session'
                                    />
                                </Grid>
                            </Grid>
                        </form>
                    </CardContent>
                </Card>
            </Grid>
        );
    };

    return (
        <Container maxWidth='xl'>
            {loading ? (
                <LoadingOverlay message='Loading vote sessions.' />
            ) : (
                <>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Typography variant='h4'>Voting Manager</Typography>
                            <CustomMaterialTable
                                data={formattedVotingSessions || []}
                                ignoredKeys={[]}
                                keysOrder={['startDate', 'endDate', 'status', 'uid']}
                                exportDisabled
                                path='/admin-dashboard/vote-manager'
                                enableRowSelection={false}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                variant='contained'
                                startIcon={<AddIcon />}
                                onClick={() => {
                                    setIsModalOpen(true);
                                }}
                            >
                                Create Voting Session
                            </Button>
                        </Grid>
                    </Grid>
                    <Modal open={isModalOpen} onClose={handleModalClose}>
                        <Box sx={modalStyle}>{CreateVotingSessionComponent()}</Box>
                    </Modal>
                    <CustomAlert {...customAlertState} />
                </>
            )}
        </Container>
    );
};
