import { useEffect, useState } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import { MatchDay, SiteData, Team } from '../types';
import { formatDate } from '../utils/helpers';
import { Card, CardContent, Skeleton } from '@mui/material';

interface ScheduleProps {
    siteData: SiteData | null;
    matchDays: MatchDay[] | null;
    teams: Team[] | null;
}

export const Schedule = ({ siteData, matchDays, teams }: ScheduleProps) => {
    const [currentSeasonSchedule, setCurrentSeasonSchedule] = useState<MatchDay[]>([]);
    const [dataLoaded, setDataLoaded] = useState<boolean>(false);

    useEffect(() => {
        // Filter match days for the current season
        if (matchDays && siteData && teams) {
            const filteredMatchDays = matchDays.filter((matchDay) => matchDay.seasonUid === siteData.seasonUid);
            // Sort match days by date
            filteredMatchDays.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
            setCurrentSeasonSchedule(filteredMatchDays);
            setDataLoaded(true);
        } else setDataLoaded(false);
    }, [matchDays, siteData, teams]);

    const renderSchedule = (): JSX.Element => {
        // Group match days by week
        const groupedByWeek: { [weekKey: string]: MatchDay[] } = {};

        currentSeasonSchedule.forEach((matchDay) => {
            const weekKey = getWeekKey(matchDay.date);
            if (!groupedByWeek[weekKey]) {
                groupedByWeek[weekKey] = [];
            }
            groupedByWeek[weekKey].push(matchDay);
        });

        return (
            <>
                {Object.entries(groupedByWeek).map(([weekKey, matchDays], weekIndex) => (
                    <Accordion key={weekIndex} elevation={0}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls={`panel${weekIndex + 1}-content`}
                            id={`panel${weekIndex + 1}-header`}
                        >
                            <Typography variant='caption'>
                                Week {weekIndex + 1} - {weekKey}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails sx={{ gap: 2, display: 'flex', flexDirection: 'column' }}>
                            {matchDays.map((matchDay, matchIndex) => {
                                const teamA = teams?.find((team) => team.uid === matchDay.teamAUid) as Team;
                                const teamB = teams?.find((team) => team.uid === matchDay.teamBUid) as Team;

                                const teamAGoals = parseInt(matchDay.teamAGoals);
                                const teamBGoals = parseInt(matchDay.teamBGoals);
                                const gameHasHappened =
                                    !isNaN(teamAGoals) && !isNaN(teamBGoals) && new Date(matchDay.date) < new Date();

                                return (
                                    <Card variant='outlined' key={matchIndex}>
                                        <CardContent>
                                            <Grid container spacing={3}>
                                                {/* Render each match day */}
                                                <Grid item xs={12}>
                                                    <Typography variant='overline'>
                                                        {formatDate(matchDay.date)}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <>
                                                        <div
                                                            style={{
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                justifyContent: 'space-between',
                                                            }}
                                                        >
                                                            <img
                                                                style={{ width: '30px', marginRight: '0' }}
                                                                src={teamA.image}
                                                                alt={teamA.name}
                                                            />
                                                            <Typography variant='caption'>
                                                                {teamA.name} -{' '}
                                                                <b>{gameHasHappened ? teamAGoals : '-'}</b>
                                                            </Typography>
                                                            <Stack direction='row' spacing={1}>
                                                                <Chip
                                                                    size='small'
                                                                    color={getChipColor(
                                                                        gameHasHappened,
                                                                        teamAGoals,
                                                                        teamBGoals
                                                                    )}
                                                                    label={getChipLabel(
                                                                        gameHasHappened,
                                                                        teamAGoals,
                                                                        teamBGoals
                                                                    )}
                                                                    variant='filled'
                                                                />
                                                            </Stack>
                                                        </div>
                                                        <div
                                                            style={{
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                justifyContent: 'space-between',
                                                            }}
                                                        >
                                                            <img
                                                                style={{ width: '30px', marginRight: '0' }}
                                                                src={teamB.image}
                                                                alt={teamB.name}
                                                            />
                                                            <Typography variant='caption'>
                                                                {teamB.name} -{' '}
                                                                <b>{gameHasHappened ? teamBGoals : '-'}</b>
                                                            </Typography>
                                                            <Stack direction='row' spacing={1}>
                                                                <Chip
                                                                    size='small'
                                                                    color={getChipColor(
                                                                        gameHasHappened,
                                                                        teamBGoals,
                                                                        teamAGoals
                                                                    )}
                                                                    label={getChipLabel(
                                                                        gameHasHappened,
                                                                        teamBGoals,
                                                                        teamAGoals
                                                                    )}
                                                                    variant='filled'
                                                                />
                                                            </Stack>
                                                        </div>
                                                    </>
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    </Card>
                                );
                            })}
                        </AccordionDetails>
                    </Accordion>
                ))}
            </>
        );
    };

    const getWeekKey = (date: string): string => {
        const matchDate = new Date(date);
        const matchDay = matchDate.getDate();
        const matchMonth = matchDate.getMonth() + 1;
        const matchYear = matchDate.getFullYear();
        return `${matchMonth}/${matchDay}/${matchYear}`;
    };

    const getChipColor = (
        gameHasHappened: boolean,
        teamGoals: number,
        opponentGoals: number
    ): 'success' | 'error' | 'default' => {
        if (!gameHasHappened) return 'default';
        if (teamGoals > opponentGoals) return 'success';
        if (teamGoals < opponentGoals) return 'error';
        return 'default';
    };

    const getChipLabel = (gameHasHappened: boolean, teamGoals: number, opponentGoals: number): string => {
        if (!gameHasHappened) return '-';
        if (teamGoals === opponentGoals) return 'DRAW';
        return teamGoals > opponentGoals ? 'WIN' : 'LOSS';
    };

    return <>{!dataLoaded ? <Skeleton /> : renderSchedule()}</>;
};
