import React from 'react';
import { CustomAlertProps, Player } from '../../../types';
import { Container, Grid, Typography } from '@mui/material';
import { initialAlertState } from '../../../utils/consts';
import { LoadingOverlay } from '../../../components/LoadingOverlay';
import { CustomAlert } from '../../../components/CustomAlert';
import { useAppSelector } from '../../../store/store';
import { FIFACard } from '../../../components/FIFACard';
import { FlipCard } from '../../../components/FlipCard';
import { sortPlayersByFIFAStatsOverall } from '../../../utils/helpers';

export const FIFAManager = () => {
    // const dispatch = useAppDispatch();
    const players = useAppSelector((state) => state.player.players);
    const teams = useAppSelector((state) => state.team.teams);

    const [customAlertState, setCustomAlertState] = React.useState<CustomAlertProps>({
        ...initialAlertState,
        closeAlert: () => handleCloseAlert(),
    });
    const [loading, setLoading] = React.useState<boolean>(false);
    const [sortedAndFilteredPlayers, setSortedAndFilteredPlayers] = React.useState<Player[]>([]);

    React.useEffect(() => {
        if (teams && players) {
            setLoading(false);
            const tempSortedAnfFilteredPlayers: Player[] = sortPlayersByFIFAStatsOverall([...players]);
            setSortedAndFilteredPlayers(tempSortedAnfFilteredPlayers);
        }
    }, [teams, players]);

    const handleCloseAlert = () => {
        setCustomAlertState((prev) => {
            prev = { ...prev };
            prev.open = false;
            return prev;
        });
    };

    // const handleUpdatePlayer = async (playerData: Player) => {
    //     try {
    //         const completePlayerData = { ...players?.find((player) => player.uid === playerData.uid), ...playerData };
    //         const action = await dispatch(updatePlayerAsync({ playerId: playerData.uid, player: completePlayerData }));
    //         const data: UpdatePlayerServerResponse = action.payload as UpdatePlayerServerResponse;
    //         if (data.success) {
    //             setCustomAlertState((prev) => {
    //                 prev = { ...prev };
    //                 prev.open = true;
    //                 prev.message = data.message;
    //                 prev.severity = 'success';
    //                 return prev;
    //             });
    //         }
    //     } catch (error: any) {
    //         console.error('Error updating player:', error.message);
    //         setCustomAlertState((prev) => {
    //             prev = { ...prev };
    //             prev.open = true;
    //             prev.message = error.message;
    //             prev.severity = 'error';
    //             return prev;
    //         });
    //     }
    //     return true;
    // };

    // const EditFifaStatsComponent = (
    //     props: FIFAStats & {
    //         cardStyle: string;
    //         name: string;
    //     }
    // ): JSX.Element => {
    //     return (
    //         <Grid container spacing={1}>
    //             <Grid item xs={12}>
    //                 <Typography></Typography>
    //             </Grid>
    //         </Grid>
    //     );
    // };

    return (
        <Container maxWidth='xl'>
            {loading ? (
                <LoadingOverlay message='Loading players.' />
            ) : (
                <>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Typography variant='h4'>FIFA Manager</Typography>
                        </Grid>
                        {sortedAndFilteredPlayers?.map((player, index: number) => {
                            return (
                                <Grid key={index} item xs={3}>
                                    <FlipCard
                                        front={
                                            <FIFACard
                                                player={player}
                                                teamImage={
                                                    teams?.find((team) => team.players.includes(player.uid))?.image
                                                }
                                                teamName={
                                                    teams?.find((team) => team.players.includes(player.uid))?.name
                                                }
                                            />
                                        }
                                        back={<></>}
                                        frontButtonText='Edit'
                                        backButtonText='Clear'
                                        flipEnabled={false}
                                    />
                                </Grid>
                            );
                        })}
                    </Grid>
                    <CustomAlert {...customAlertState} />
                </>
            )}
        </Container>
    );
};
