import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { emailTest, passwordTest } from '../../utils/regex';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { Card, CardContent, Container } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { UserAuth } from '../../context/AuthContext';
import { addUser } from '../../utils/api';
import { UserRoles } from '../../types';

export const Signup = (): JSX.Element => {
    const { createUser } = UserAuth();
    const navigate = useNavigate();
    const [email, setEmail] = React.useState<string>('');
    const [password, setPassword] = React.useState<string>('');
    const [emailValid, setEmailValid] = React.useState<boolean>(false);
    const [passwordValid, setPasswordValid] = React.useState<boolean>(false);
    const [buttonDisabled, setButtonDisabled] = React.useState<boolean>(true);
    const [showPassword, setShowPassword] = React.useState<boolean>(false);
    const [passwordChecks, setPasswordChecks] = React.useState({
        minLength: false,
        uppercase: false,
        lowercase: false,
        specialChar: false,
        number: false,
    });

    React.useEffect(() => {
        setPasswordChecks({
            minLength: password.length >= 8,
            uppercase: /[A-Z]/.test(password),
            lowercase: /[a-z]/.test(password),
            specialChar: /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/.test(password),
            number: /[0-9]/.test(password),
        });
    }, [password]);

    React.useEffect(() => {
        setPasswordValid(Object.values(passwordChecks).every(Boolean));
    }, [passwordChecks]);

    const register = async () => {
        try {
            const userCredential: any = await createUser(email, password);
            const user = userCredential.user;
            const uid = user.uid;
            const resemail = user.email || '';

            const newUser = {
                uid,
                email: resemail,
                role: UserRoles.USER,
            };

            await addUser(newUser);
            navigate('/');
        } catch (err) {
            alert(`There was an error creating user: ${err}`);
            console.error('There was an error creating user:', err);
        }
    };

    const PasswordCheckList = (): JSX.Element => (
        <ul>
            {Object.keys(passwordChecks).map((key, index) => (
                //@ts-ignore
                <li key={index} style={{ color: passwordChecks[key] ? 'green' : 'red' }}>
                    {key}
                </li>
            ))}
        </ul>
    );

    React.useEffect(() => {
        setEmailValid(emailTest(email));
        setPasswordValid(passwordTest(password));
    }, [email, password]);

    React.useEffect(() => {
        if (emailValid && passwordValid) {
            setButtonDisabled(false);
        } else setButtonDisabled(true);
    }, [emailValid, passwordValid]);

    return (
        <Container maxWidth='sm'>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant='h5'>Sign Up.</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Card>
                        <CardContent>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <TextField
                                        size='small'
                                        fullWidth
                                        label='Email'
                                        variant='filled'
                                        type='email'
                                        onChange={(e: any) => setEmail(e.target.value as string)}
                                    />
                                </Grid>
                                {password && (
                                    <Grid item xs={12}>
                                        <PasswordCheckList />
                                    </Grid>
                                )}
                                <Grid item xs={12}>
                                    <TextField
                                        size='small'
                                        fullWidth
                                        label='Password'
                                        variant='filled'
                                        type={showPassword ? 'text' : 'password'}
                                        onChange={(e) => setPassword(e.target.value)}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position='end'>
                                                    <IconButton
                                                        edge='end'
                                                        aria-label='toggle password visibility'
                                                        onClick={() => setShowPassword(!showPassword)}
                                                    >
                                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                    }}
                                >
                                    <Link to='/forgot-password'>
                                        <Typography>Forgot Password</Typography>
                                    </Link>
                                    <Link to='/login'>
                                        <Typography>Login</Typography>
                                    </Link>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12}>
                    <Button variant='contained' disabled={buttonDisabled} onClick={register}>
                        Sign up
                    </Button>
                </Grid>
            </Grid>
        </Container>
    );
};
