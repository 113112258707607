import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SiteData } from '../../types';

interface SiteDataState {
    siteData: null | SiteData;
}

const initialState: SiteDataState = {
    siteData: null,
};

export const SiteDataSlice = createSlice({
    name: 'siteData',
    initialState,
    reducers: {
        setSiteData: (state, action: PayloadAction<SiteData>) => {
            state.siteData = action.payload;
        },
    },
});

export default SiteDataSlice.reducer;
export const { setSiteData } = SiteDataSlice.actions;
