import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AuthenticatedUser } from '../../types';

interface TeamsState {
    users: null | AuthenticatedUser[];
}

const initialState: TeamsState = {
    users: null,
};

export const UserSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUsers: (state, action: PayloadAction<AuthenticatedUser[]>) => {
            state.users = action.payload;
        },
    },
});

export default UserSlice.reducer;
export const { setUsers } = UserSlice.actions;
