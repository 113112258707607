import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { News } from '../../types';

interface NewsState {
    news: null | News[];
}

const initialState: NewsState = {
    news: null,
};

export const NewsSlice = createSlice({
    name: 'news',
    initialState,
    reducers: {
        setNews: (state, action: PayloadAction<News[]>) => {
            state.news = action.payload;
        },
    },
});

export default NewsSlice.reducer;
export const { setNews } = NewsSlice.actions;
