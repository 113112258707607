import { Button, CircularProgress } from '@mui/material';

type ValidButtonColor = 'inherit' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning';

interface LoadingButtonProps {
    type: string;
    text: string;
    isProcessing: boolean;
    onClick?: any;
    disabled?: boolean;
    color?: ValidButtonColor | string;
}

export const LoadingButton = (props: LoadingButtonProps): JSX.Element => {
    const { text, type, isProcessing, onClick, disabled, color, ...otherProps } = props;
    const mappedColor: ValidButtonColor = color === 'custom' ? 'primary' : (color as ValidButtonColor);

    return (
        <Button
            type={type as any}
            variant={'contained'}
            disabled={disabled || isProcessing}
            endIcon={isProcessing ? <CircularProgress size={24} /> : <></>}
            onClick={onClick}
            color={mappedColor}
            {...otherProps}
        >
            {text}
        </Button>
    );
};
