import React from 'react';
import { Player, Team } from '../types';
import { Button, Card, CardContent, Grid, Skeleton, Typography } from '@mui/material';
import { getContrastColor, sortPlayersByAttribute } from '../utils/helpers';
import { NavLink } from 'react-router-dom';
import nullAvatar from '../assets/null_avatar.png';

interface LeagueLeadersComponentProps {
    players: Player[] | null;
    teams: Team[] | null;
    attributeToFilter: string;
    title: string;
    seasonUid: string;
}

interface Data {
    name: string;
    image: string;
    teamImage: string;
    value: number;
    color: string;
}

export const LeagueLeadersComponent = ({
    players,
    teams,
    attributeToFilter,
    title,
    seasonUid,
}: LeagueLeadersComponentProps) => {
    const [data, setData] = React.useState<Data[]>([]);
    const [loading, setLoading] = React.useState<boolean>(true);

    React.useEffect(() => {
        if (players && teams && attributeToFilter && title && seasonUid) {
            const sortedPlayers = sortPlayersByAttribute(players, seasonUid, attributeToFilter);
            const tempData: Data[] = sortedPlayers
                .map((player) => {
                    return {
                        ...player,
                        teamImage:
                            teams.find((team) => {
                                return team.players.includes(player.uid);
                            })?.image || '',
                        color:
                            teams.find((team) => {
                                return team.players.includes(player.uid);
                            })?.color || '',
                    };
                })
                .slice(0, 5);
            setData(tempData);
            setLoading(false);
        } else setLoading(true);
    }, [players, teams, attributeToFilter, title, seasonUid]);

    return loading ? (
        <Skeleton />
    ) : (
        <Card variant='outlined'>
            <CardContent>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Typography variant='overline'>
                            <b>{title}</b>
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            position: 'relative',
                        }}
                    >
                        {data.length > 0 && (
                            <>
                                <img
                                    alt={data[0].name}
                                    width={220}
                                    src={data[0].teamImage}
                                    style={{ position: 'absolute', zIndex: 1, objectFit: 'cover' }}
                                />
                                <img
                                    alt={data[0].name}
                                    width={150}
                                    src={data[0].image || nullAvatar}
                                    style={{ zIndex: 2, objectFit: 'cover' }}
                                />
                            </>
                        )}
                    </Grid>
                    {data.map((player, index) => (
                        <Grid
                            item
                            xs={12}
                            key={index}
                            style={{
                                // display: 'flex',
                                // justifyContent: 'space-between',
                                // alignItems: 'center',
                                background: index === 0 ? data[0].color : index % 2 === 0 ? '#eee' : '',
                                color: index === 0 ? getContrastColor(data[0].color) : 'black',
                                padding: 6,
                            }}
                        >
                            {' '}
                            <div>
                                <Grid container spacing={1}>
                                    <Grid item xs={1}>
                                        <Typography
                                            style={{
                                                display: 'flex',
                                                fontWeight: index === 0 ? 'bolder' : '',
                                                alignItems: 'center',
                                            }}
                                            variant='caption'
                                        >
                                            {`${index + 1}.`}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <img
                                            alt={player.name}
                                            width={20}
                                            src={player.teamImage}
                                            style={{ position: 'absolute', zIndex: 1, objectFit: 'cover' }}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Typography
                                            style={{
                                                display: 'flex',
                                                fontWeight: index === 0 ? 'bolder' : '',
                                                alignItems: 'center',
                                            }}
                                            variant='caption'
                                        >
                                            {`${player.name}`.toUpperCase()}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                fontWeight: index === 0 ? 'bolder' : '',
                                                justifyContent: 'flex-end',
                                            }}
                                            variant='caption'
                                        >
                                            <b>{`${player.value}`}</b>
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </div>
                        </Grid>
                    ))}
                    <Grid item xs={12}>
                        <NavLink to={`leagueLeaders/${attributeToFilter}`}>
                            <Button sx={{ fontWeight: 'bolder' }}>See more {'>'}</Button>
                        </NavLink>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};
