import { MaterialReactTable, useMaterialReactTable, type MRT_Row, createMRTColumnHelper } from 'material-react-table';
import { Box, Button } from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { mkConfig, generateCsv, download } from 'export-to-csv';
import { useNavigate } from 'react-router-dom';

export const columnHelper = createMRTColumnHelper<any>(); // Use 'any' type for dynamic columns

const csvConfig = mkConfig({
    fieldSeparator: ',',
    decimalSeparator: '.',
    useKeysAsHeaders: true,
});

type CustomMaterialTableProps<T> = {
    data: T[];
    ignoredKeys: (keyof T)[];
    path?: string;
    keysOrder?: string[];
    updateData?: (data: T) => void;
    exportDisabled?: boolean;
    enableRowSelection: boolean;
    dropdownOptionsMap?: any;
};

export const CustomMaterialTable = <T extends Record<string, any>>({
    data,
    ignoredKeys,
    path,
    keysOrder,
    updateData,
    exportDisabled,
    enableRowSelection,
    dropdownOptionsMap,
}: CustomMaterialTableProps<T>) => {
    const navigate = useNavigate();

    // Use keys from the first item in data to dynamically create columns
    const keysFromFirstItem = [...Object.keys(data[0] || {}).filter((key) => !ignoredKeys.includes(key))];
    const orderedKeys = keysOrder ? keysOrder.filter((key) => keysFromFirstItem.includes(key)) : keysFromFirstItem;
    const keysToEnableEditing = ['name', 'email', 'position', 'country', 'role', 'playerUid'];
    const dropdownKeys = Object.keys(dropdownOptionsMap || {});
    const columns = [
        ...orderedKeys.map((key, index) => {
            return {
                accessorKey: key,
                header: key,
                enableEditing: keysToEnableEditing.includes(key),
                editVariant: dropdownKeys.includes(key) ? 'select' : 'text',
                editSelectOptions: dropdownOptionsMap ? dropdownOptionsMap[key] : [],
                // Edit: (props: any) => {
                //     console.log(props);
                //     return <>{props.row.renderValue()}</>;
                //     // return <p>{cell.row.original[key]}</p>;
                //     // return <>{renderValue()}</>;
                // },
            };
        }),
    ];

    const handleExportRows = (rows: MRT_Row<T>[]) => {
        const rowData = rows.map((row) => row.original);
        const csv = generateCsv(csvConfig)(rowData);
        download(csvConfig)(csv);
    };

    const handleExportData = () => {
        const csv = generateCsv(csvConfig)(data);
        download(csvConfig)(csv);
    };

    const table = useMaterialReactTable({
        // @ts-ignore
        columns,
        data,
        enableRowSelection,
        columnFilterDisplayMode: 'popover',
        paginationDisplayMode: 'pages',
        enableFullScreenToggle: false,
        enableDensityToggle: false,
        initialState: { density: 'compact', columnPinning: { right: ['Details'] } },
        positionToolbarAlertBanner: 'bottom',
        enableColumnPinning: true,
        layoutMode: 'grid-no-grow',
        editDisplayMode: 'modal',
        enableEditing: updateData ? true : false,
        muiTopToolbarProps: {
            sx: {
                background: 'white',
            },
        },
        muiBottomToolbarProps: {
            sx: {
                background: 'white',
            },
        },
        muiTableProps: {
            sx: {
                backgroundColor: 'white !important',
            },
        },
        muiTableBodyCellProps: {
            sx: {
                background: 'white',
            },
        },
        muiTableHeadCellProps: {
            sx: {
                background: 'white',
            },
        },
        onEditingRowSave: ({ table, values }) => {
            if (updateData) {
                updateData(values as any);
            }
            table.setEditingRow(null); //exit editing mode
        },
        muiTableBodyRowProps: ({ row }) => ({
            onDoubleClick: (event) => {
                if (path) {
                    navigate(`${path}/${row.original.uid}`);
                }
            },
            sx: {
                cursor: path ? 'default' : 'default',
                background: 'white',
                fontWeight: row.getIsSelected() ? 'bolder' : 'bolder',
            },
        }),
        renderTopToolbarCustomActions: ({ table }) => {
            return !exportDisabled ? (
                <Box
                    sx={{
                        display: 'flex',
                        gap: '16px',
                        padding: '8px',
                        flexWrap: 'wrap',
                    }}
                >
                    <Button onClick={handleExportData} startIcon={<FileDownloadIcon />}>
                        Export All Data
                    </Button>
                    <Button
                        disabled={table.getPrePaginationRowModel().rows.length === 0}
                        // @ts-ignore
                        onClick={() => handleExportRows(table.getPrePaginationRowModel().rows)}
                        startIcon={<FileDownloadIcon />}
                    >
                        Export All Rows
                    </Button>
                    <Button
                        disabled={table.getRowModel().rows.length === 0}
                        // @ts-ignore
                        onClick={() => handleExportRows(table.getRowModel().rows)}
                        startIcon={<FileDownloadIcon />}
                    >
                        Export Page Rows
                    </Button>
                    <Button
                        disabled={!table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()}
                        // @ts-ignore
                        onClick={() => handleExportRows(table.getSelectedRowModel().rows)}
                        startIcon={<FileDownloadIcon />}
                    >
                        Export Selected Rows
                    </Button>
                </Box>
            ) : (
                <></>
            );
        },
    });
    return <MaterialReactTable table={table} />;
};
