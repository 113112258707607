import React from 'react';
import { Autocomplete, Button, Card, CardContent, Divider, Grid, TextField } from '@mui/material';
import { Container, Typography } from '@mui/material';
import { CustomAlertProps, Player, Team } from '../../../types';
import { updateTeam } from '../../../utils/api';
import { useParams } from 'react-router-dom';
import { CustomAlert } from '../../../components/CustomAlert';
import { LoadingOverlay } from '../../../components/LoadingOverlay';
import { ImageUploader } from '../../../components/ImageUploader';
import { initialAlertState, initialNewTeamState } from '../../../utils/consts';
import { fetchTeam as fetchTeamAsync } from '../../../utils/actions';
import { useAppSelector } from '../../../store/store';

export const TeamDetails = (): JSX.Element => {
    const { teamUid } = useParams<{ teamUid: string }>();
    const players = useAppSelector((state) => state.player.players);
    const teams = useAppSelector((state) => state.team.teams);

    const [loading, setLoading] = React.useState<boolean>(false);
    const [selectedToEditTeam, setSelectedToEditTeam] = React.useState<Team>(initialNewTeamState);
    const [editTeam, setEditTeam] = React.useState<Team>(initialNewTeamState);
    const [availablePlayers, setAvaialablePlayers] = React.useState<Player[]>([]);
    const [updateButtonDisabled, setUpdateButtonDisabled] = React.useState<boolean>(true);
    const [customAlertState, setCustomAlertState] = React.useState<CustomAlertProps>(initialAlertState);

    React.useEffect(() => {
        if (selectedToEditTeam) {
            setEditTeam(selectedToEditTeam);
        }
    }, [selectedToEditTeam]);

    React.useEffect(() => {
        if (teams && players) {
            const tempAvailablePlayers: Player[] = players.filter((player) => {
                const unavailablePlayerUids: string[] = teams.map((team) => team.players).flat(1);
                return !unavailablePlayerUids.includes(player.uid);
            });
            setAvaialablePlayers(tempAvailablePlayers);
        }
    }, [teams, players]);

    React.useEffect(() => {
        setUpdateButtonDisabled(false);
    }, []);

    React.useEffect(() => {
        if (selectedToEditTeam && editTeam) {
            const tempIsFormDirty = isFormDirty();
            console.log('tempIsFormDirty', tempIsFormDirty);
            // setUpdateButtonDisabled(!tempIsFormDirty);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedToEditTeam, editTeam]);

    React.useEffect(() => {
        if (teamUid) {
            const fetchData = async () => {
                try {
                    const teamData = await fetchTeamAsync(teamUid);
                    if (teamData.success) {
                        setSelectedToEditTeam(teamData.team);
                        setEditTeam({ ...editTeam } as any);
                        setCustomAlertState((prev) => {
                            prev = { ...prev };
                            prev.open = false;
                            prev.severity = 'warning';
                            prev.closeAlert = handleCloseAlert;
                            return prev;
                        });
                        setLoading(false);
                    }
                } catch (error: any) {
                    console.error('Error fetching data:', error);
                    setLoading(false);
                    setCustomAlertState((prev) => {
                        prev = { ...prev };
                        prev.open = true;
                        prev.severity = 'error';
                        prev.closeAlert = handleCloseAlert;
                        prev.message = error;
                        return prev;
                    });
                }
            };
            fetchData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [teamUid]);

    const isFormDirty = () => {
        return true;
        // const areChangesMade = Object.keys(selectedToEditTeam).some((key) => {
        //     const originalValue = selectedToEditTeam[key as keyof Player];
        //     const updatedValue = editPlayer[key as keyof Player];
        //     return originalValue !== updatedValue && updatedValue.trim() !== '';
        // });
        // return areChangesMade;
    };

    // const mergePlayer = (originalPlayer: Player, updatedPlayer: Player): Player => {
    //     const mergedPlayer = { ...originalPlayer };

    //     Object.keys(updatedPlayer).forEach((key) => {
    //         const originalValue = originalPlayer[key as keyof Player];
    //         const updatedValue = updatedPlayer[key as keyof Player];

    //         // Only update if the value has changed and is not an empty string
    //         if (originalValue !== updatedValue && updatedValue !== '') {
    //             mergedPlayer[key as keyof Player] = updatedValue;
    //         }
    //     });

    //     return mergedPlayer;
    // };

    const handleUpdateTeam = async (e: React.FormEvent<HTMLFormElement>): Promise<void> => {
        e.preventDefault();

        if (selectedToEditTeam && editTeam) {
            try {
                const mergedTeam = editTeam;
                const teamId = mergedTeam.uid;
                const data = await updateTeam(teamId, mergedTeam);
                if (data.success) {
                    setCustomAlertState((prev) => {
                        prev = { ...prev };
                        prev.open = true;
                        prev.message = data.message;
                        prev.severity = 'success';
                        return prev;
                    });
                    setEditTeam({ ...initialNewTeamState, image: mergedTeam.image });
                    setSelectedToEditTeam(mergedTeam);
                }
            } catch (error: any) {
                console.error('Error updating team:', error.message);
                setCustomAlertState((prev) => {
                    prev = { ...prev };
                    prev.open = true;
                    prev.message = error.message;
                    prev.severity = 'error';
                    return prev;
                });
            }
        }
    };

    const handleUpdateDownloadUrl = async (newDownloadUrl: string) => {
        if (selectedToEditTeam && editTeam) {
            try {
                const playerId = selectedToEditTeam.uid;
                const updatedData = {
                    ...selectedToEditTeam,
                    image: newDownloadUrl,
                };
                const data = await updateTeam(playerId, updatedData);
                if (data.success) {
                    setCustomAlertState((prev) => {
                        prev = { ...prev };
                        prev.open = true;
                        prev.message = data.message;
                        prev.severity = 'success';
                        return prev;
                    });
                    setEditTeam({
                        ...editTeam,
                        image: newDownloadUrl,
                    });
                    setSelectedToEditTeam({ ...selectedToEditTeam, image: newDownloadUrl });
                }
            } catch (error: any) {
                console.error('Error updating team image:', error.message);
                setCustomAlertState((prev) => {
                    prev = { ...prev };
                    prev.open = true;
                    prev.message = error.message;
                    prev.severity = 'error';
                    return prev;
                });
            }
        }
    };

    const handleCloseAlert = () => {
        setCustomAlertState((prev) => {
            prev = { ...prev };
            prev.open = false;
            return prev;
        });
    };

    return (
        <Container maxWidth='xl'>
            {loading ? (
                <LoadingOverlay message='Loading details.' />
            ) : (
                <>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Typography variant='h4'>Team Details</Typography>
                            <Card>
                                <CardContent>
                                    <form onSubmit={handleUpdateTeam}>
                                        <Grid container spacing={3}>
                                            <Grid
                                                item
                                                xs={12}
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'space-between',
                                                }}
                                            >
                                                <Typography variant='h6'>
                                                    <b>{selectedToEditTeam?.name}</b>
                                                </Typography>
                                                <Typography variant='subtitle2'>
                                                    <b>{selectedToEditTeam?.uid}</b>
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField
                                                    fullWidth
                                                    size='small'
                                                    label='Team Name'
                                                    value={editTeam?.name || ''}
                                                    placeholder={selectedToEditTeam?.name || ''}
                                                    onChange={(e: any) => {
                                                        setEditTeam({ ...editTeam, name: e.target.value });
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Autocomplete
                                                    multiple
                                                    id='update-directors-autocomplete'
                                                    options={availablePlayers || []}
                                                    getOptionLabel={(option: Player) => option?.name || ''}
                                                    value={
                                                        editTeam?.players
                                                            .map((playerUid) =>
                                                                players?.find((player) => player.uid === playerUid)
                                                            )
                                                            .filter((player) => player !== null) as Player[]
                                                    }
                                                    onChange={(_, newValues) =>
                                                        setEditTeam({
                                                            ...editTeam,
                                                            players: newValues.map((player) =>
                                                                typeof player === 'string' ? player : player.uid
                                                            ),
                                                        })
                                                    }
                                                    renderInput={(params) => (
                                                        <TextField {...params} label='Players' size='small' fullWidth />
                                                    )}
                                                />
                                            </Grid>

                                            <Grid item xs={12}>
                                                <Button
                                                    type='submit'
                                                    variant='contained'
                                                    disabled={updateButtonDisabled}
                                                >
                                                    Update Team
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </form>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant='h5'>Image</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <img alt={selectedToEditTeam.name} src={selectedToEditTeam.image} />
                        </Grid>
                        <Grid item xs={12}>
                            <ImageUploader
                                endpoint='teams'
                                uid={teamUid || ''}
                                updateDownloadUrl={handleUpdateDownloadUrl}
                                aspectRatio={1 / 1}
                                imageWidth={300}
                                imageHeight={300}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Divider />
                        </Grid>
                    </Grid>
                    <CustomAlert {...customAlertState} />
                </>
            )}
        </Container>
    );
};
