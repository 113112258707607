import React from 'react';
import { Button, Card, CardContent, Divider, Grid, TextField } from '@mui/material';
import { Container, Typography } from '@mui/material';
import { Player, CustomAlertProps } from '../../../types';
import { updatePlayer } from '../../../utils/api';
import { useParams } from 'react-router-dom';
import { CustomAlert } from '../../../components/CustomAlert';
import { LoadingOverlay } from '../../../components/LoadingOverlay';
import { ImageUploader } from '../../../components/ImageUploader';
import { initialAlertState, initialNewPlayerState } from '../../../utils/consts';
import { emailTest } from '../../../utils/regex';
import { fetchPlayer as fetchPlayerAsync } from '../../../utils/actions';

export const PlayerDetails = (): JSX.Element => {
    const { playerUid } = useParams<{ playerUid: string }>();

    const [loading, setLoading] = React.useState<boolean>(false);
    const [selectedToEditPlayer, setSelectedToEditPlayer] = React.useState<Player>(initialNewPlayerState);
    const [editPlayer, setEditPlayer] = React.useState<Player>(initialNewPlayerState);
    const [updateButtonDisabled, setUpdateButtonDisabled] = React.useState<boolean>(true);
    const [customAlertState, setCustomAlertState] = React.useState<CustomAlertProps>(initialAlertState);
    const [isEmailValid, setIsEmailValid] = React.useState<boolean>(true);

    React.useEffect(() => {
        setUpdateButtonDisabled(false);
    }, []);

    React.useEffect(() => {
        if (selectedToEditPlayer && editPlayer) {
            const tempIsFormDirty = isFormDirty();
            setUpdateButtonDisabled(!tempIsFormDirty);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedToEditPlayer, editPlayer]);

    React.useEffect(() => {
        if (playerUid) {
            const fetchData = async () => {
                try {
                    const playerData = await fetchPlayerAsync(playerUid);
                    if (playerData.success) {
                        setSelectedToEditPlayer(playerData.player);
                        setEditPlayer({ ...editPlayer } as any);
                        setCustomAlertState((prev) => {
                            prev = { ...prev };
                            prev.open = false;
                            prev.severity = 'warning';
                            prev.closeAlert = handleCloseAlert;
                            return prev;
                        });
                        setLoading(false);
                    }
                } catch (error: any) {
                    console.error('Error fetching data:', error);
                    setLoading(false);
                    setCustomAlertState((prev) => {
                        prev = { ...prev };
                        prev.open = true;
                        prev.severity = 'error';
                        prev.closeAlert = handleCloseAlert;
                        prev.message = error;
                        return prev;
                    });
                }
            };
            fetchData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [playerUid]);

    const isFormDirty = () => {
        return true;
        // const areChangesMade = Object.keys(selectedToEditPlayer).some((key) => {
        //     const originalValue = selectedToEditPlayer[key as keyof Player];
        //     const updatedValue = editPlayer[key as keyof Player];
        //     return originalValue !== updatedValue && updatedValue.trim() !== '';
        // });
        // return areChangesMade;
    };

    // const mergePlayer = (originalPlayer: Player, updatedPlayer: Player): Player => {
    //     const mergedPlayer = { ...originalPlayer };

    //     Object.keys(updatedPlayer).forEach((key) => {
    //         const originalValue = originalPlayer[key as keyof Player];
    //         const updatedValue = updatedPlayer[key as keyof Player];

    //         // Only update if the value has changed and is not an empty string
    //         if (originalValue !== updatedValue && updatedValue !== '') {
    //             mergedPlayer[key as keyof Player] = updatedValue;
    //         }
    //     });

    //     return mergedPlayer;
    // };

    const handleUpdateplayer = async (e: React.FormEvent<HTMLFormElement>): Promise<void> => {
        e.preventDefault();

        if (selectedToEditPlayer && editPlayer) {
            try {
                // const mergedPlayer = mergePlayer(selectedToEditPlayer, editPlayer);
                const mergedPlayer = editPlayer;
                const playerId = mergedPlayer.uid;
                const data = await updatePlayer(playerId, mergedPlayer);
                if (data.success) {
                    setCustomAlertState((prev) => {
                        prev = { ...prev };
                        prev.open = true;
                        prev.message = data.message;
                        prev.severity = 'success';
                        return prev;
                    });
                    setEditPlayer({ ...initialNewPlayerState, image: mergedPlayer.image });
                    setSelectedToEditPlayer(mergedPlayer);
                }
            } catch (error: any) {
                console.error('Error updating player:', error.message);
                setCustomAlertState((prev) => {
                    prev = { ...prev };
                    prev.open = true;
                    prev.message = error.message;
                    prev.severity = 'error';
                    return prev;
                });
            }
        }
    };

    const handleUpdateDownloadUrl = async (newDownloadUrl: string) => {
        if (selectedToEditPlayer && editPlayer) {
            try {
                const playerId = selectedToEditPlayer.uid;
                const updatedData = {
                    ...selectedToEditPlayer,
                    image: newDownloadUrl,
                };
                const data = await updatePlayer(playerId, updatedData);
                if (data.success) {
                    setCustomAlertState((prev) => {
                        prev = { ...prev };
                        prev.open = true;
                        prev.message = data.message;
                        prev.severity = 'success';
                        return prev;
                    });
                    setEditPlayer({
                        ...editPlayer,
                        image: newDownloadUrl,
                    });
                    setSelectedToEditPlayer({ ...selectedToEditPlayer, image: newDownloadUrl });
                }
            } catch (error: any) {
                console.error('Error updating player image:', error.message);
                setCustomAlertState((prev) => {
                    prev = { ...prev };
                    prev.open = true;
                    prev.message = error.message;
                    prev.severity = 'error';
                    return prev;
                });
            }
        }
    };

    const handleCloseAlert = () => {
        setCustomAlertState((prev) => {
            prev = { ...prev };
            prev.open = false;
            return prev;
        });
    };

    return (
        <Container maxWidth='xl'>
            {loading ? (
                <LoadingOverlay message='Loading details.' />
            ) : (
                <>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Typography variant='h4'>Player Details</Typography>
                            <Card>
                                <CardContent>
                                    <form onSubmit={handleUpdateplayer}>
                                        <Grid container spacing={3}>
                                            <Grid
                                                item
                                                xs={12}
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'space-between',
                                                }}
                                            >
                                                <Typography variant='h6'>
                                                    <b>{selectedToEditPlayer?.name}</b>
                                                </Typography>
                                                <Typography variant='subtitle2'>
                                                    <b>{selectedToEditPlayer?.uid}</b>
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField
                                                    fullWidth
                                                    size='small'
                                                    label='Player Name'
                                                    value={editPlayer?.name || ''}
                                                    placeholder={selectedToEditPlayer?.name || ''}
                                                    onChange={(e: any) => {
                                                        setEditPlayer({ ...editPlayer, name: e.target.value });
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField
                                                    fullWidth
                                                    size='small'
                                                    label='Email'
                                                    type='email'
                                                    value={editPlayer?.email || ''}
                                                    placeholder={selectedToEditPlayer?.email || ''}
                                                    error={!!editPlayer?.email && !isEmailValid}
                                                    onChange={(e) => {
                                                        const newEmail = e.target.value.trim();
                                                        setEditPlayer({ ...editPlayer, email: newEmail });
                                                        if (newEmail === '') {
                                                            setIsEmailValid(true);
                                                        } else {
                                                            setIsEmailValid(emailTest(newEmail));
                                                        }
                                                    }}
                                                />
                                            </Grid>

                                            <Grid item xs={12}>
                                                <Button
                                                    type='submit'
                                                    variant='contained'
                                                    disabled={true || updateButtonDisabled}
                                                >
                                                    Update Player
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </form>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant='h5'>Image</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <img alt={selectedToEditPlayer.name} src={selectedToEditPlayer.image} />
                        </Grid>
                        <Grid item xs={12}>
                            <ImageUploader
                                endpoint='players'
                                uid={playerUid || ''}
                                updateDownloadUrl={handleUpdateDownloadUrl}
                                aspectRatio={2 / 3}
                                imageWidth={200}
                                imageHeight={300}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Divider />
                        </Grid>
                    </Grid>
                    <CustomAlert {...customAlertState} />
                </>
            )}
        </Container>
    );
};
