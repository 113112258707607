import * as React from 'react';
import { NavLink, useNavigate, useLocation } from 'react-router-dom';
import { AppTheme } from './AppTheme';
import { styled, Theme, CSSObject } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import SportsIcon from '@mui/icons-material/Sports';
import PeopleIcon from '@mui/icons-material/People';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Fab from '@mui/material/Fab';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Star from '@mui/icons-material/Star';
import Fade from '@mui/material/Fade';
import { UserAuth } from '../context/AuthContext';
import { getUserByUID } from '../utils/api';
import { Footer } from './Footer';
import logo from '../assets/logo.png';
import { Avatar, Badge, Chip, Menu, MenuItem } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
// import NewspaperIcon from '@mui/icons-material/Newspaper';
import SportsSoccerIcon from '@mui/icons-material/SportsSoccer';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import VideogameAssetIcon from '@mui/icons-material/VideogameAsset';
import PersonIcon from '@mui/icons-material/Person';
import HowToVoteIcon from '@mui/icons-material/HowToVote';
import { PublicNavbar } from './PublicNavBar';
import { useAppSelector } from '../store/store';
// import { LoggedInUser } from '../types';
import packageJson from '../../package.json';

interface Route {
    path: string;
    text: string;
    icon: JSX.Element;
    roleAccess: string[];
}

const routes: Route[] = [
    {
        path: '/admin-dashboard/players',
        text: 'Players',
        icon: <PeopleIcon />,
        roleAccess: ['admin'],
    },
    {
        path: '/admin-dashboard/teams',
        text: 'Teams',
        icon: <SportsIcon />,
        roleAccess: ['admin'],
    },
    {
        path: '/admin-dashboard/seasons',
        text: 'Seasons',
        icon: <SportsSoccerIcon />,
        roleAccess: ['admin'],
    },
    {
        path: '/admin-dashboard/schedule',
        text: 'Schedule',
        icon: <CalendarMonthIcon />,
        roleAccess: ['admin'],
    },
    {
        path: '/admin-dashboard/player-stats',
        text: 'Manage Player Stats',
        icon: <LeaderboardIcon />,
        roleAccess: ['admin'],
    },
    {
        path: '/admin-dashboard/card-manager',
        text: 'Manage Player Cards',
        icon: <VideogameAssetIcon />,
        roleAccess: ['admin'],
    },
    {
        path: '/admin-dashboard/user-manager',
        text: 'Manage Users',
        icon: <PersonIcon />,
        roleAccess: ['admin'],
    },
    {
        path: '/admin-dashboard/vote-manager',
        text: 'Manage Voting',
        icon: <HowToVoteIcon />,
        roleAccess: ['admin'],
    },
    {
        path: '/admin-dashboard/fantasy-manager',
        text: 'Manage Fantasy',
        icon: <Star />,
        roleAccess: ['admin'],
    },
    {
        path: '/player-dashboard/profile',
        text: 'Profile',
        icon: <PersonIcon />,
        roleAccess: ['player'],
    },
    {
        path: '/player-dashboard/voting',
        text: 'Voting',
        icon: <HowToVoteIcon />,
        roleAccess: ['player'],
    },
    {
        path: '/user-dashboard/fantasy',
        text: 'Fantasy',
        icon: <Star />,
        roleAccess: ['player', 'user'],
    },
];

const drawerWidth = 240;

const linkStyle = {
    color: 'black',
    textDecoration: 'inherit',
};

const openedMixin = (theme: Theme): CSSObject => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: 0, // Set width to 0 when the drawer is closed
    [theme.breakpoints.up('sm')]: {
        width: 0, // Adjust width for larger screens if needed
    },
});
const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
}

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
        ...closedMixin(theme),
        '& .MuiDrawer-paper': closedMixin(theme),
    }),
}));

interface MiniDrawerProps {
    children: JSX.Element;
}

export default function MiniDrawer(props: MiniDrawerProps): JSX.Element {
    const { user, logout, deleteUser } = UserAuth();
    const siteData = useAppSelector((state) => state.siteData.siteData);
    const players = useAppSelector((state) => state.player.players);
    const users = useAppSelector((state) => state.user.users);
    // const votingSessions = useAppSelector((state) => state.votingSession.votingSessions);
    // const players = useAppSelector((state) => state.player.players);
    // const matchDays = useAppSelector((state) => state.matchDay.matchDays);
    // const teams = useAppSelector((state) => state.team.teams);
    // const users = useAppSelector((state) => state.user.users);

    const [role, setRole] = React.useState<string>('');
    // const [loggedInUser, setLoggedInUser] = React.useState<LoggedInUser>();
    const navigate = useNavigate();
    const location = useLocation();
    const { children } = props;
    // const theme = useTheme();
    const [open, setOpen] = React.useState(false);
    const [anchorElUserMenu, setAnchorElUserMenu] = React.useState<null | HTMLElement>(null);
    const [avatarImage, setAvatarImage] = React.useState<string>('');

    React.useEffect(() => {
        if (user && !user.isAnonymous) {
            const fetchUserData = async () => {
                const idToken = await user.getIdToken();
                getUserByUID(user.uid, idToken).then((data) => {
                    if (data) {
                        // const canVote: boolean = false;
                        return setRole(data.user.role);
                    }
                });
            };
            fetchUserData();
        }
    }, [user]);

    React.useEffect(() => {
        if (players && user && users) {
            const playersUid: string = users.find((u) => u.uid === user.uid)?.playerUid || '';
            const tempAvatarImage: string = players.find((player) => player.uid === playersUid)?.image || '';
            setAvatarImage(tempAvatarImage);
        }
    }, [players, user, users]);

    const handleDrawerOpen = () => {
        setOpen(!open);
    };

    interface Props {
        /**
         * Injected by the documentation to work in an iframe.
         * You won't need it on your project.
         */
        window?: () => Window;
        children: React.ReactElement;
    }

    function ScrollTop(props: Props) {
        const { children, window } = props;
        // Note that you normally won't need to set the window ref as useScrollTrigger
        // will default to window.
        // This is only being set here because the demo is in an iframe.
        const trigger = useScrollTrigger({
            target: window ? window() : undefined,
            disableHysteresis: true,
            threshold: 100,
        });

        const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
            const anchor = ((event.target as HTMLDivElement).ownerDocument || document).querySelector(
                '#back-to-top-anchor'
            );

            if (anchor) {
                anchor.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                });
            }
        };

        return (
            <Fade in={trigger} color='red'>
                <Box onClick={handleClick} role='presentation' sx={{ position: 'fixed', bottom: 16, right: 16 }}>
                    {children}
                </Box>
            </Fade>
        );
    }

    const handleLogout = () => {
        const proceed: boolean = window.confirm('Are you sure you want to log out?');
        if (proceed) {
            handleCloseUserMenu();
            logout()
                .then(() => {
                    if (user?.isAnonymous) {
                        // Check if the user is anonymous
                        deleteUser(user) // Delete the anonymous user
                            .then(() => {
                                navigate('/');
                            })
                            .catch((err: Error) => {
                                alert(`Error deleting anonymous user: ${err}`);
                            });
                    } else {
                        navigate('/');
                    }
                })
                .catch((err: Error) => {
                    alert(`There was an error logging out: ${err}`);
                });
        }
    };

    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUserMenu(event.currentTarget);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUserMenu(null);
    };

    return (
        <AppTheme>
            <>
                <Box sx={{ display: 'flex', background: '' }}>
                    <CssBaseline />

                    <AppBar position='fixed' open={user && open}>
                        {/* <TeamsBar /> */}
                        <Toolbar>
                            {user ? (
                                <IconButton
                                    color='primary'
                                    aria-label='open drawer'
                                    onClick={handleDrawerOpen}
                                    disabled={user ? false : true}
                                    edge='start'
                                    sx={{
                                        marginRight: 5,
                                        ...(open && { display: '' }),
                                    }}
                                >
                                    <MenuIcon />
                                </IconButton>
                            ) : null}

                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    width: '100%',
                                }}
                            >
                                <div style={{ color: 'black' }}>
                                    <Typography variant='subtitle2'>SEASON {siteData?.currentSeasonNumber}</Typography>
                                </div>
                                <div style={{ display: 'flex', alignItems: 'center', gap: 12 }}>
                                    <img
                                        width={90}
                                        src={logo}
                                        alt={logo}
                                        onClick={() => navigate('/')}
                                        style={{ cursor: 'pointer' }}
                                    />
                                </div>
                                <div>
                                    {user ? (
                                        <Box>
                                            <div
                                                onClick={handleOpenUserMenu}
                                                style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
                                            >
                                                <Avatar
                                                    alt='User Avatar'
                                                    src={avatarImage}
                                                    sizes='small'
                                                    sx={{ width: 30, height: '100%' }}
                                                />
                                                <Typography variant='subtitle2' sx={{ marginLeft: 1, color: 'black' }}>
                                                    {user.displayName || user.email.split('@')[0]}
                                                </Typography>
                                                <div
                                                    style={{
                                                        color: 'black',
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                    }}
                                                >
                                                    {Boolean(anchorElUserMenu) ? (
                                                        <ArrowDropUpIcon />
                                                    ) : (
                                                        <ArrowDropDownIcon />
                                                    )}
                                                </div>
                                            </div>

                                            <Menu
                                                id='user-menu'
                                                anchorEl={anchorElUserMenu}
                                                open={Boolean(anchorElUserMenu)}
                                                onClose={handleCloseUserMenu}
                                                anchorOrigin={{
                                                    vertical: 'bottom',
                                                    horizontal: 'right',
                                                }}
                                                transformOrigin={{
                                                    vertical: 'bottom',
                                                    horizontal: 'right',
                                                }}
                                                style={{ top: 32 }}
                                            >
                                                <MenuItem onClick={handleCloseUserMenu} disabled>
                                                    <Typography variant='body2' color='primary'>
                                                        Profile
                                                    </Typography>
                                                </MenuItem>
                                                {/* </NavLink> */}
                                                <MenuItem onClick={handleLogout}>
                                                    <Typography variant='body2' color='error'>
                                                        Logout
                                                    </Typography>
                                                </MenuItem>
                                            </Menu>
                                        </Box>
                                    ) : (
                                        <Box>
                                            <div style={{ color: 'black', display: 'flex', gap: '12px' }}>
                                                <NavLink to={'/login'}>
                                                    <Typography variant='subtitle2'>Login</Typography>
                                                </NavLink>{' '}
                                                <NavLink to={'/signup'}>
                                                    <Typography variant='subtitle2'>Sign Up</Typography>
                                                </NavLink>
                                            </div>
                                        </Box>
                                    )}
                                </div>
                            </div>
                        </Toolbar>
                    </AppBar>

                    {user ? (
                        <Drawer
                            variant='permanent'
                            open={open}
                            sx={{
                                '& .MuiDrawer-paper': {
                                    border: 'none', // Remove border
                                    boxShadow: 'none', // Optionally remove the shadow
                                },
                            }}
                        >
                            <DrawerHeader
                                className='drawer-header'
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    border: 'none',
                                    // background: '#ffc127',
                                }}
                            >
                                <Chip label={`v${packageJson.version}`} size='small' variant='outlined' />
                                {/* <Typography variant='caption'>v{packageJson.version}</Typography> */}
                            </DrawerHeader>
                            <List>
                                {routes.map((route, index: number) => {
                                    const borderLeftStyle =
                                        location.pathname === '/admin-dashboard' && route.path === '/admin-dashboard'
                                            ? '8px solid #ffc027'
                                            : location.pathname.startsWith(route.path) &&
                                              route.path !== '/admin-dashboard'
                                            ? '8px solid #ffc027'
                                            : '8px solid #fff';
                                    const backgroundStyle =
                                        location.pathname === '/admin-dashboard' && route.path === '/admin-dashboard'
                                            ? '#ffefc9'
                                            : location.pathname.startsWith(route.path) &&
                                              route.path !== '/admin-dashboard'
                                            ? '#ffefc9'
                                            : '';
                                    return (
                                        <ListItem
                                            key={index}
                                            disablePadding
                                            sx={{
                                                display: route.roleAccess.includes(role) ? 'block' : 'none',
                                                borderLeft: borderLeftStyle,
                                                background: backgroundStyle,
                                            }}
                                        >
                                            <NavLink to={route.path} style={linkStyle}>
                                                <ListItemButton
                                                    sx={{
                                                        minHeight: 48,
                                                        justifyContent: open ? 'initial' : 'center',
                                                        px: 2.5,
                                                    }}
                                                    className={`menu-item-button ${
                                                        location.pathname === '/admin-dashboard' &&
                                                        route.path === '/admin-dashboard'
                                                            ? 'active'
                                                            : location.pathname.startsWith(route.path) &&
                                                              route.path !== '/admin-dashboard'
                                                            ? 'active'
                                                            : ''
                                                    }`}
                                                    disableRipple
                                                >
                                                    <Badge
                                                        badgeContent={
                                                            route.path === '/player-dashboard/voting' && false ? 1 : 0
                                                        }
                                                        anchorOrigin={{
                                                            vertical: 'top',
                                                            horizontal: 'left',
                                                        }}
                                                        variant='dot'
                                                        color='error'
                                                    >
                                                        <ListItemIcon
                                                            sx={{
                                                                minWidth: 0,
                                                                mr: open ? 3 : 'auto',
                                                                justifyContent: 'center',
                                                            }}
                                                        >
                                                            {route.icon}
                                                        </ListItemIcon>
                                                    </Badge>
                                                    <ListItemText
                                                        primary={route.text}
                                                        sx={{
                                                            opacity: open ? 1 : 0,
                                                            fontSize: '6px',
                                                            color: 'black !important',
                                                        }}
                                                    />
                                                </ListItemButton>
                                            </NavLink>
                                        </ListItem>
                                    );
                                })}
                            </List>
                        </Drawer>
                    ) : null}

                    <div style={{ width: '100%' }}>
                        <span id='back-to-top-anchor'></span>
                        <DrawerHeader />

                        <PublicNavbar />

                        <Box component='main' sx={{ flexGrow: 1, p: 0, overflow: 'hidden', paddingTop: '140px' }}>
                            {children}
                            <ScrollTop {...props}>
                                <Fab color='primary' size='small' aria-label='scroll back to top'>
                                    <KeyboardArrowUpIcon />
                                </Fab>
                            </ScrollTop>
                            <div>
                                <Footer />
                            </div>
                        </Box>
                    </div>
                </Box>
            </>
        </AppTheme>
    );
}
