import React from 'react';
import {
    AddSeasonServerResponse,
    CustomAlertProps,
    GetSiteDataServerResponse,
    // Season,
    SeasonPayload,
    SiteData,
    Team,
    UpdateSiteDataServerResponse,
} from '../../../types';
import {
    Autocomplete,
    // Autocomplete,
    Box,
    Button,
    Card,
    CardContent,
    Container,
    Divider,
    Grid,
    MobileStepper,
    Modal,
    Step,
    StepLabel,
    Stepper,
    TextField,
    Typography,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { initialAlertState, initialNewSeasonState } from '../../../utils/consts';
import { LoadingOverlay } from '../../../components/LoadingOverlay';
import { seasonSchedulMocdalStyle } from '../../../styles/theme';
import { CustomAlert } from '../../../components/CustomAlert';
import { CustomMaterialTable } from '../../../components/CustomMaterialTable';
import { useAppDispatch, useAppSelector } from '../../../store/store';
import {
    addSeason as addSeasonAsync,
    updateSiteData as updateSiteDataAsync,
    fetchSiteData as fetchSiteDataAsync,
} from '../../../utils/actions';
import { LoadingButton } from '../../../components/LoadingButton';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import { SeasonSchedulemMaterialTable } from '../../../components/SeasonScheduleMaterialTable';

export const Seasons = () => {
    const dispatch = useAppDispatch();
    const teams = useAppSelector((state) => state.team.teams);
    const players = useAppSelector((state) => state.player.players);
    const seasons = useAppSelector((state) => state.season.seasons);
    const siteData = useAppSelector((state) => state.siteData.siteData);

    const [customAlertState, setCustomAlertState] = React.useState<CustomAlertProps>({
        ...initialAlertState,
        closeAlert: () => handleCloseAlert(),
    });
    const [newSeason, setNewSeason] = React.useState<SeasonPayload>(initialNewSeasonState);
    const [inSeason, setInSeason] = React.useState<boolean>(false);
    const [loading, setLoading] = React.useState<boolean>(false);
    const [addButtonIsProcessing, setAddButtonIsProcessing] = React.useState<boolean>(false);
    const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false);
    const [submitButtonDisabled, setSubmitButtonDisabled] = React.useState<boolean>(true);

    React.useEffect(() => {
        if (siteData) {
            setInSeason(siteData.inSeason);
        }
    }, [siteData]);

    React.useEffect(() => {
        if (teams && players && siteData) {
            setLoading(false);
        }
    }, [teams, players, siteData]);

    React.useEffect(() => {
        const numberOfTeams = newSeason.teams.length;
        const numberOfMatches = newSeason.matches.length;

        if (numberOfMatches > 0 && numberOfTeams > 0) {
            setSubmitButtonDisabled(false);
        } else setSubmitButtonDisabled(true);
    }, [newSeason]);

    const handleModalClose = (): void => {
        setNewSeason(initialNewSeasonState);
        setIsModalOpen(false);
    };

    const handleCloseAlert = () => {
        setCustomAlertState((prev) => {
            prev = { ...prev };
            prev.open = false;
            return prev;
        });
    };

    const handleAddSeason = async (e: React.FormEvent<HTMLFormElement>): Promise<void> => {
        e.preventDefault();
        try {
            setAddButtonIsProcessing(true);
            const action = await dispatch(addSeasonAsync({ ...newSeason, date: new Date().toISOString() }));
            const data: AddSeasonServerResponse = action.payload as AddSeasonServerResponse;
            if (data.success) {
                const getSiteDataAction = await dispatch(fetchSiteDataAsync());
                const siteDataData: GetSiteDataServerResponse = getSiteDataAction.payload as GetSiteDataServerResponse;
                if (siteDataData.success) {
                    setNewSeason(initialNewSeasonState);
                    setCustomAlertState((prev) => {
                        prev = { ...prev };
                        prev.open = true;
                        prev.message = data.message;
                        prev.severity = 'success';
                        return prev;
                    });
                    setIsModalOpen(false);
                }
            }
        } catch (error: any) {
            console.error('Error adding season:', error.message);
            setCustomAlertState((prev) => {
                prev = { ...prev };
                prev.open = true;
                prev.message = error.message;
                prev.severity = 'error';
                return prev;
            });
        } finally {
            setAddButtonIsProcessing(false);
        }
    };

    // const handleUpdateSeason = async (seasonData: Season) => {
    // try {
    //     const completeTeamData = { ...teams?.find((team) => team.uid === teamData.uid), ...teamData };
    //     const action = await dispatch(updatePlayerAsync({ teamId: teamData.uid, team: completeTeamData }));
    //     const data: UpdatePlayerServerResponse = action.payload as UpdatePlayerServerResponse;
    //     if (data.success) {
    //         setCustomAlertState((prev) => {
    //             prev = { ...prev };
    //             prev.open = true;
    //             prev.message = data.message;
    //             prev.severity = 'success';
    //             return prev;
    //         });
    //     }
    // } catch (error: any) {
    //     console.error('Error updating team:', error.message);
    //     setCustomAlertState((prev) => {
    //         prev = { ...prev };
    //         prev.open = true;
    //         prev.message = error.message;
    //         prev.severity = 'error';
    //         return prev;
    //     });
    // }
    // return true;
    // };

    const handleUpdateSiteData = async () => {
        try {
            const completedSiteData: SiteData = {
                ...siteData,
                inSeason: false,
                lastUpdated: new Date().toISOString(),
                seasonUid: siteData?.seasonUid || '',
                currentSeasonNumber: siteData?.currentSeasonNumber || 0,
                fantasySessionUid: siteData?.fantasySessionUid || '',
            };
            const action = await dispatch(updateSiteDataAsync({ siteData: completedSiteData }));
            const data: UpdateSiteDataServerResponse = action.payload as UpdateSiteDataServerResponse;
            if (data.success) {
                const getSiteDataAction = await dispatch(fetchSiteDataAsync());
                const siteDataData: GetSiteDataServerResponse = getSiteDataAction.payload as GetSiteDataServerResponse;
                if (siteDataData.success) {
                    setCustomAlertState((prev) => {
                        prev = { ...prev };
                        prev.open = true;
                        prev.message = data.message;
                        prev.severity = 'success';
                        return prev;
                    });
                }
            }
        } catch (error: any) {
            console.error('Error updating siteData:', error.message);
            setCustomAlertState((prev) => {
                prev = { ...prev };
                prev.open = true;
                prev.message = error.message;
                prev.severity = 'error';
                return prev;
            });
        }
        return true;
    };

    const CreateSeasonComponent = (): JSX.Element => {
        const steps = ['Add Teams', 'Create Schedule', 'Summary'];
        const [activeStep, setActiveStep] = React.useState<number>(0);

        return (
            <Grid item xs={12}>
                <Box sx={{ width: '100%' }}>
                    <Stepper activeStep={activeStep} alternativeLabel>
                        {steps.map((label) => (
                            <Step key={label}>
                                <StepLabel>{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                </Box>
                <br />
                <Divider />
                <br />
                <Card>
                    <CardContent>
                        <form onSubmit={handleAddSeason}>
                            <Grid container spacing={3}>
                                {activeStep === 0 && (
                                    <Grid container spacing={1} item xs={12}>
                                        <Grid item xs={12}>
                                            <Typography variant='h6'>Add Teams</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Autocomplete
                                                multiple
                                                id='teams-autocomplete'
                                                options={teams || []}
                                                getOptionLabel={(option: Team) => option.name || ''}
                                                value={
                                                    newSeason.teams
                                                        .map((teamUid) => teams?.find((team) => team.uid === teamUid))
                                                        .filter((team) => team !== null) as Team[]
                                                }
                                                onChange={(_, newValues) =>
                                                    setNewSeason({
                                                        ...newSeason,
                                                        teams: newValues.map((team) =>
                                                            typeof team === 'string' ? team : team.uid
                                                        ),
                                                    })
                                                }
                                                renderInput={(params) => (
                                                    <TextField {...params} label='Teams' size='small' fullWidth />
                                                )}
                                            />
                                        </Grid>
                                    </Grid>
                                )}
                                {activeStep === 1 && (
                                    <Grid container spacing={1} item xs={12}>
                                        <Grid item xs={12}>
                                            <Typography variant='h6'>Create Schedule</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <SeasonSchedulemMaterialTable
                                                teams={teams || []}
                                                teamsInSeason={newSeason.teams}
                                                updateSeason={(matches) => {
                                                    setNewSeason((prev) => {
                                                        prev = { ...prev };
                                                        prev.matches = matches;
                                                        return prev;
                                                    });
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                )}
                                {activeStep === 2 && (
                                    <Grid container spacing={1} item xs={12}>
                                        <Grid item xs={12}>
                                            <Typography variant='h6'>Summary</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Divider />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant='subtitle1'>
                                                Teams:{' '}
                                                <b>
                                                    {newSeason.teams.map((teamUid) => {
                                                        return (
                                                            <span>
                                                                {teams?.find((team) => team.uid === teamUid)?.name},{' '}
                                                            </span>
                                                        );
                                                    })}
                                                </b>
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant='subtitle1'>
                                                Number of Matches: <b>{newSeason.matches.length}</b>
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <LoadingButton
                                                disabled={submitButtonDisabled}
                                                isProcessing={addButtonIsProcessing}
                                                type='submit'
                                                text='Create Season'
                                            />
                                        </Grid>
                                    </Grid>
                                )}
                            </Grid>
                        </form>
                    </CardContent>
                </Card>
                <MobileStepper
                    variant='progress'
                    steps={3}
                    position='static'
                    activeStep={activeStep}
                    sx={{ flexGrow: 1, position: 'relative', bottom: 0, width: '100%', left: 0 }}
                    nextButton={
                        <Button
                            size='small'
                            onClick={() => setActiveStep((prev) => prev + 1)}
                            disabled={activeStep === 2}
                        >
                            Next
                            {<KeyboardArrowRight />}
                        </Button>
                    }
                    backButton={
                        <Button
                            size='small'
                            onClick={() => setActiveStep((prev) => prev - 1)}
                            disabled={activeStep === 0}
                        >
                            <KeyboardArrowLeft />
                            Back
                        </Button>
                    }
                />
            </Grid>
        );
    };

    return (
        <Container maxWidth='xl'>
            {loading ? (
                <LoadingOverlay message='Loading seasons.' />
            ) : (
                <>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Typography variant='h4'>Seasons</Typography>
                            <CustomMaterialTable
                                data={seasons?.slice().sort((a, b) => a.seasonNumber - b.seasonNumber) || []}
                                ignoredKeys={[]}
                                keysOrder={['seasonNumber', 'uid']}
                                exportDisabled
                                enableRowSelection={false}
                            />
                        </Grid>
                        <Grid item xs={12} style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Button
                                variant='contained'
                                startIcon={<AddIcon />}
                                disabled={inSeason}
                                onClick={() => {
                                    setIsModalOpen(true);
                                }}
                            >
                                Create Season
                            </Button>
                            <Button
                                variant='contained'
                                startIcon={<AddIcon />}
                                disabled={!inSeason}
                                color='error'
                                onClick={() => {
                                    const shouldContinue: boolean = window.confirm(
                                        'Are you sure you want to end the current season? This cannot be undone.'
                                    );
                                    if (shouldContinue) handleUpdateSiteData();
                                }}
                            >
                                End Current Season
                            </Button>
                        </Grid>
                    </Grid>
                    <Modal open={isModalOpen} onClose={handleModalClose}>
                        <Box sx={seasonSchedulMocdalStyle}>{CreateSeasonComponent()}</Box>
                    </Modal>
                    <CustomAlert {...customAlertState} />
                </>
            )}
        </Container>
    );
};
