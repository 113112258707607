import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Player } from '../../types';

interface PlayersState {
    players: null | Player[];
}

const initialState: PlayersState = {
    players: null,
};

export const PlayerSlice = createSlice({
    name: 'player',
    initialState,
    reducers: {
        setPlayers: (state, action: PayloadAction<Player[]>) => {
            state.players = action.payload;
        },
    },
});

export default PlayerSlice.reducer;
export const { setPlayers } = PlayerSlice.actions;
