import * as React from 'react';
import { Box, Typography } from '@mui/material';
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import { Player, MatchDay, PlayerLeaderBoard, Team } from '../types';
import { calculatePlayerLeaderboard } from '../utils/helpers';

interface GlobalPlayerLeaderboardProps {
    players: Player[] | null;
    matchDays: MatchDay[] | null;
    teams: Team[] | null;
}

interface TableData {
    rank: number;
    name: string;
    image: string;
    points: number;
    goals: number;
    assists: number;
    blocks: number;
    nutMegs: number;
}

export const GlobalPlayerLeaderBoardComponent = ({
    players,
    matchDays,
    teams,
}: GlobalPlayerLeaderboardProps): JSX.Element => {
    const [tableData, setTableData] = React.useState<TableData[]>([]);
    const [dataLoaded, setDataLoaded] = React.useState<boolean>(false);

    React.useEffect(() => {
        if (players && matchDays && teams) {
            const tempTableData: PlayerLeaderBoard[] = calculatePlayerLeaderboard(players).map((player, index) => ({
                rank: index + 1,
                position: player.position,
                name: player.name,
                image: player.image,
                points: player.points,
                goals: player.goals,
                assists: player.assists,
                blocks: player.blocks,
                nutMegs: player.nutMegs,
                country: player.country || '',
            }));
            setTableData(tempTableData);
            setDataLoaded(true);
        } else setDataLoaded(false);
    }, [players, matchDays, teams]);

    const columns = [
        {
            accessorKey: 'rank',
            header: 'Rank',
            size: 80,
        },
        {
            accessorKey: 'name',
            header: 'Player',
            size: 200,
            Cell: ({ row }: any) => {
                return (
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                        <img width={20} src={row.original.image} alt={row.original.image} />
                        <Typography variant='caption'>{row.original.name}</Typography>
                    </Box>
                );
            },
        },
        {
            accessorKey: 'position',
            header: 'Position',
            size: 80,
        },
        {
            accessorKey: 'country',
            header: 'Country',
            size: 200,
            Cell: ({ row }: any) => {
                return (
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                        <img
                            width={20}
                            src={`https://flagcdn.com/80x60/${row.original.country?.code?.toLowerCase()}.png`}
                            alt={row.original.country}
                        />
                        <Typography variant='caption'>{row.original.country?.name}</Typography>
                    </Box>
                );
            },
        },
        {
            accessorKey: 'points',
            header: 'Points',
            size: 80,
        },
        {
            accessorKey: 'goals',
            header: 'Goals',
            size: 80,
        },
        {
            accessorKey: 'assists',
            header: 'Assists',
            size: 80,
        },
        {
            accessorKey: 'nutMegs',
            header: 'NutMegs',
            size: 80,
        },
        {
            accessorKey: 'blocks',
            header: 'Blocks',
            size: 80,
        },
    ];

    const table = useMaterialReactTable({
        columns,
        data: tableData,
        enableRowSelection: false,
        columnFilterDisplayMode: 'popover',
        paginationDisplayMode: 'pages',
        enableFullScreenToggle: false,
        enableDensityToggle: false,
        initialState: {
            density: 'compact',
            columnPinning: { right: ['Details'] },
            pagination: { pageSize: 5, pageIndex: 0 },
        },
        positionToolbarAlertBanner: 'bottom',
        enableColumnPinning: true,
        state: {
            isLoading: !dataLoaded,
        },
        muiTopToolbarProps: {
            sx: {
                background: 'white',
            },
        },
        muiBottomToolbarProps: {
            sx: {
                background: 'white',
            },
        },
        muiTableProps: {
            sx: {
                backgroundColor: 'white !important',
            },
        },
        muiTableBodyCellProps: {
            sx: {
                background: 'white',
            },
        },
        muiTableHeadCellProps: {
            sx: {
                background: 'white',
            },
        },
        muiSkeletonProps: {
            animation: 'wave',
        },
    });

    return (
        <>
            {/* <Skeleton animation='wave' variant='rounded' width={'100%'} height={200} /> */}
            <MaterialReactTable table={table} />
        </>
    );
};
