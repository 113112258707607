import React from 'react';
import { AuthenticatedUser, CustomAlertProps, Player } from '../../../types';
import {
    Button,
    Card,
    CardContent,
    Container,
    Divider,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography,
} from '@mui/material';
import { initialAlertState, initialNewPlayerState } from '../../../utils/consts';
import { LoadingOverlay } from '../../../components/LoadingOverlay';
import { CustomAlert } from '../../../components/CustomAlert';
import { useAppSelector } from '../../../store/store';
import { UserAuth } from '../../../context/AuthContext';
import { fetchPlayer as fetchPlayerAsync } from '../../../utils/actions';
import { updatePlayer } from '../../../utils/api';
import { ImageUploader } from '../../../components/ImageUploader';
import { emailTest } from '../../../utils/regex';
import cardStyles from '../../../data/cardStyles.json';
import positions from '../../../data/positions.json';

export const PlayerProfile = () => {
    const { user } = UserAuth();
    const users = useAppSelector((state) => state.user.users);

    const [playerUid, setPlayerUid] = React.useState<string | undefined>();
    const [selectedToEditPlayer, setSelectedToEditPlayer] = React.useState<Player>(initialNewPlayerState);
    const [editPlayer, setEditPlayer] = React.useState<Player>(initialNewPlayerState);
    const [isEmailValid, setIsEmailValid] = React.useState<boolean>(true);
    const [updateButtonDisabled, setUpdateButtonDisabled] = React.useState<boolean>(true);

    const [customAlertState, setCustomAlertState] = React.useState<CustomAlertProps>({
        ...initialAlertState,
        closeAlert: () => handleCloseAlert(),
    });
    const [loading, setLoading] = React.useState<boolean>(false);

    React.useEffect(() => {
        if (playerUid) {
            const fetchData = async () => {
                try {
                    const playerData = await fetchPlayerAsync(playerUid);
                    if (playerData.success) {
                        setSelectedToEditPlayer(playerData.player);
                        setEditPlayer({ ...editPlayer } as any);
                        setCustomAlertState((prev) => {
                            prev = { ...prev };
                            prev.open = false;
                            prev.severity = 'warning';
                            prev.closeAlert = handleCloseAlert;
                            return prev;
                        });
                        setLoading(false);
                    }
                } catch (error: any) {
                    console.error('Error fetching data:', error);
                    setLoading(false);
                    setCustomAlertState((prev) => {
                        prev = { ...prev };
                        prev.open = true;
                        prev.severity = 'error';
                        prev.closeAlert = handleCloseAlert;
                        prev.message = error;
                        return prev;
                    });
                }
            };
            fetchData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [playerUid]);

    React.useEffect(() => {
        if (user && users) {
            const foundUserInCollection: AuthenticatedUser | undefined = users.find((u) => user.uid === u.uid);
            if (foundUserInCollection && foundUserInCollection.playerUid) {
                setPlayerUid(foundUserInCollection.playerUid);
            }
            setLoading(false);
        }
    }, [user, users]);

    React.useEffect(() => {
        if (playerUid) {
            const fetchData = async () => {
                try {
                    const playerData = await fetchPlayerAsync(playerUid);
                    if (playerData.success) {
                        setSelectedToEditPlayer(playerData.player);
                        setEditPlayer({ ...editPlayer } as any);
                        setCustomAlertState((prev) => {
                            prev = { ...prev };
                            prev.open = false;
                            prev.severity = 'warning';
                            prev.closeAlert = handleCloseAlert;
                            return prev;
                        });
                        setLoading(false);
                    }
                } catch (error: any) {
                    console.error('Error fetching data:', error);
                    setLoading(false);
                    setCustomAlertState((prev) => {
                        prev = { ...prev };
                        prev.open = true;
                        prev.severity = 'error';
                        prev.closeAlert = handleCloseAlert;
                        prev.message = error;
                        return prev;
                    });
                }
            };
            fetchData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [playerUid]);

    React.useEffect(() => {
        if (
            (editPlayer.name !== '' && selectedToEditPlayer.name !== editPlayer.name) ||
            selectedToEditPlayer.cardStyle !== editPlayer.cardStyle
        ) {
            setUpdateButtonDisabled(false);
        } else setUpdateButtonDisabled(true);
    }, [selectedToEditPlayer, editPlayer]);

    React.useEffect(() => {
        console.log(updateButtonDisabled);
    }, [updateButtonDisabled]);

    const handleUpdateDownloadUrl = async (newDownloadUrl: string) => {
        if (selectedToEditPlayer && editPlayer) {
            try {
                const playerId = selectedToEditPlayer.uid;
                const updatedData = {
                    ...selectedToEditPlayer,
                    image: newDownloadUrl,
                };
                const data = await updatePlayer(playerId, updatedData);
                if (data.success) {
                    setCustomAlertState((prev) => {
                        prev = { ...prev };
                        prev.open = true;
                        prev.message = data.message;
                        prev.severity = 'success';
                        return prev;
                    });
                    setEditPlayer({
                        ...editPlayer,
                        image: newDownloadUrl,
                    });
                    setSelectedToEditPlayer({ ...selectedToEditPlayer, image: newDownloadUrl });
                }
            } catch (error: any) {
                console.error('Error updating player image:', error.message);
                setCustomAlertState((prev) => {
                    prev = { ...prev };
                    prev.open = true;
                    prev.message = error.message;
                    prev.severity = 'error';
                    return prev;
                });
            }
        }
    };

    const handleCloseAlert = () => {
        setCustomAlertState((prev) => {
            prev = { ...prev };
            prev.open = false;
            return prev;
        });
    };

    const handleUpdateplayer = async (e: React.FormEvent<HTMLFormElement>): Promise<void> => {
        e.preventDefault();

        if (selectedToEditPlayer && editPlayer) {
            try {
                const mergedPlayer = {
                    ...selectedToEditPlayer,
                    // ...editPlayer,
                    name: editPlayer.name || selectedToEditPlayer.name,
                    cardStyle: editPlayer.cardStyle || selectedToEditPlayer.cardStyle,
                    position: editPlayer.position || selectedToEditPlayer.position,
                };
                const playerId = mergedPlayer.uid;
                const data = await updatePlayer(playerId, mergedPlayer);
                if (data.success) {
                    setCustomAlertState((prev) => {
                        prev = { ...prev };
                        prev.open = true;
                        prev.message = data.message;
                        prev.severity = 'success';
                        return prev;
                    });
                    setEditPlayer({ ...initialNewPlayerState, image: mergedPlayer.image });
                    setSelectedToEditPlayer(mergedPlayer);
                }
            } catch (error: any) {
                console.error('Error updating player:', error.message);
                setCustomAlertState((prev) => {
                    prev = { ...prev };
                    prev.open = true;
                    prev.message = error.message;
                    prev.severity = 'error';
                    return prev;
                });
            }
        }
    };

    return (
        <Container maxWidth='xl'>
            {loading ? (
                <LoadingOverlay message='Loading data.' />
            ) : (
                <>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Typography variant='h4'>Profile</Typography>
                            <Card>
                                <CardContent>
                                    <form onSubmit={handleUpdateplayer}>
                                        <Grid container spacing={3}>
                                            <Grid
                                                item
                                                xs={12}
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'space-between',
                                                }}
                                            >
                                                <Typography variant='h6'>
                                                    <b>{selectedToEditPlayer?.name}</b>
                                                </Typography>
                                                <Typography variant='subtitle2'>
                                                    <b>{selectedToEditPlayer?.uid}</b>
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    fullWidth
                                                    size='small'
                                                    label='Player Name'
                                                    value={editPlayer?.name || ''}
                                                    placeholder={selectedToEditPlayer?.name || ''}
                                                    onChange={(e: any) => {
                                                        setEditPlayer({ ...editPlayer, name: e.target.value });
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    fullWidth
                                                    size='small'
                                                    label='Email'
                                                    type='email'
                                                    disabled
                                                    value={editPlayer?.email || ''}
                                                    placeholder={selectedToEditPlayer?.email || ''}
                                                    error={!!editPlayer?.email && !isEmailValid}
                                                    onChange={(e) => {
                                                        const newEmail = e.target.value.trim();
                                                        setEditPlayer({ ...editPlayer, email: newEmail });
                                                        if (newEmail === '') {
                                                            setIsEmailValid(true);
                                                        } else {
                                                            setIsEmailValid(emailTest(newEmail));
                                                        }
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <FormControl fullWidth style={{ maxHeight: 200 }}>
                                                    <InputLabel>Card Style</InputLabel>
                                                    <Select
                                                        fullWidth
                                                        size='small'
                                                        label='Card Style'
                                                        style={{ maxHeight: 200 }}
                                                        value={editPlayer.cardStyle}
                                                        onChange={(e) => {
                                                            setEditPlayer((prev) => {
                                                                prev = { ...prev, cardStyle: e.target.value };
                                                                return prev;
                                                            });
                                                        }}
                                                        MenuProps={{
                                                            PaperProps: {
                                                                style: {
                                                                    maxHeight: 200,
                                                                },
                                                            },
                                                        }}
                                                    >
                                                        {cardStyles.map((cardStyle, index: number) => {
                                                            return (
                                                                <MenuItem key={index} value={cardStyle}>
                                                                    {cardStyle}
                                                                </MenuItem>
                                                            );
                                                        })}
                                                    </Select>
                                                </FormControl>
                                            </Grid>

                                            <Grid item xs={12}>
                                                <FormControl fullWidth>
                                                    <InputLabel>Position</InputLabel>
                                                    <Select
                                                        fullWidth
                                                        size='small'
                                                        label='Position'
                                                        value={editPlayer.position}
                                                        onChange={(e) => {
                                                            setEditPlayer({
                                                                ...editPlayer,
                                                                position: e.target.value,
                                                            });
                                                        }}
                                                    >
                                                        {positions.map((position, index: number) => {
                                                            return (
                                                                <MenuItem key={index} value={position}>
                                                                    {position}
                                                                </MenuItem>
                                                            );
                                                        })}
                                                    </Select>
                                                </FormControl>
                                            </Grid>

                                            <Grid item xs={12}>
                                                <Button
                                                    type='submit'
                                                    variant='contained'
                                                    disabled={updateButtonDisabled}
                                                >
                                                    Update Player
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </form>
                                </CardContent>
                            </Card>
                        </Grid>

                        <Grid item xs={12}>
                            <Divider />
                        </Grid>

                        <Grid item xs={12}>
                            <Typography variant='h5'>Image</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <img alt={selectedToEditPlayer.name} src={selectedToEditPlayer.image} />
                        </Grid>
                        <Grid item xs={12}>
                            <ImageUploader
                                endpoint='players'
                                uid={playerUid || ''}
                                updateDownloadUrl={handleUpdateDownloadUrl}
                                aspectRatio={2 / 3}
                                imageWidth={200}
                                imageHeight={300}
                            />
                        </Grid>
                    </Grid>
                    <CustomAlert {...customAlertState} />
                </>
            )}
        </Container>
    );
};
