import React from 'react';
import { Button, Card, CardContent, Container, Grid, Typography } from '@mui/material';
import { LoadingOverlay } from '../../../components/LoadingOverlay';
import { useAppSelector } from '../../../store/store';
import { useNavigate } from 'react-router-dom';

export const PublicTeams = (): JSX.Element => {
    const navigate = useNavigate();
    const [loading, setLoading] = React.useState(true);

    const teams = useAppSelector((state) => state.team.teams);

    React.useEffect(() => {
        if (teams) {
            setLoading(false);
        } else setLoading(true);
    }, [teams]);

    return (
        <Container maxWidth='xl'>
            {loading ? (
                <LoadingOverlay message='Loading teams.' />
            ) : (
                <>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Typography variant='h4'>Teams</Typography>
                        </Grid>
                        <Grid container spacing={1} item xs={12}>
                            {teams?.map((team) => {
                                return (
                                    <Grid item xs={12} sm={12} md={6} lg={4} xl={3} sx={{ padding: 0 }}>
                                        <Button
                                            sx={{ width: '100%', p: 0 }}
                                            onClick={() => navigate(`/teams/${team.uid}`)}
                                        >
                                            <Card variant='outlined' sx={{ width: '100%' }}>
                                                <CardContent>
                                                    <img src={team.image} alt={team.name} width={50} />
                                                    <Typography variant='subtitle2'>{team.name}</Typography>
                                                </CardContent>
                                            </Card>
                                        </Button>
                                    </Grid>
                                );
                            })}
                        </Grid>
                    </Grid>
                </>
            )}
        </Container>
    );
};
