import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FantasyTeam } from '../../types';

interface FantasyTeamsState {
    fantasyTeams: null | FantasyTeam[];
}

const initialState: FantasyTeamsState = {
    fantasyTeams: null,
};

export const FantasyTeams = createSlice({
    name: 'fantasyTeams',
    initialState,
    reducers: {
        setFantasyTeams: (state, action: PayloadAction<FantasyTeam[]>) => {
            state.fantasyTeams = action.payload;
        },
    },
});

export default FantasyTeams.reducer;
export const { setFantasyTeams } = FantasyTeams.actions;
