import { createAsyncThunk } from '@reduxjs/toolkit';
import {
    getAllNews,
    getAllPlayers,
    getAllSeasons,
    getAllTeams,
    getAllUsers,
    getMatchDays,
    getSiteData,
    getVotingSessions,
    getAllFantasyTeams,
    getAllFantasySessions,
} from './api';
import {
    getPlayerByUid as getPlayerByUidApi,
    getTeamByUid as getTeamByUidApi,
    addPlayer as addPlayerApi,
    addTeam as addTeamApi,
    addNews as addNewsApi,
    addSeason as addSeasonApi,
    addMatchDay as addMatchDayApi,
    postVote as postVoteApi,
    postVotingSession as postVotingSessionApi,
    postFantasyTeam as postFantasyTeamApi,
    postFantasySession as postFantasySessionApi,
    updatePlayer as updatePlayerApi,
    updateUser as updateUserApi,
    updateTeam as updateTeamApi,
    updateNews as updateNewsApi,
    updateSeason as updateSeasonApi,
    updateSiteData as updateSiteDataApi,
    updateMatchDay as updateMatchDayApi,
    updateFantasyTeamWeek as updateFantasyTeamWeekApi,
    updatePlayerStatsForMatch as updatePlayerStatsForMatchApi,
    deleteMatchDay as deleteMatchDayApi,
} from './api';
import { setPlayers } from '../store/features/playersSlice';
import {
    MatchDay,
    News,
    Player,
    Season,
    SeasonPayload,
    SiteData,
    GameStats,
    Team,
    GetPlayerByUidServerResponse,
    GetTeamByUidServerResponse,
    AuthenticatedUser,
    Vote,
    VotingSession,
    FantasyTeam,
    FantasySession,
} from '../types';
import { setTeams } from '../store/features/teamSlice';
import { setNews } from '../store/features/newsSlice';
import { setSeasons } from '../store/features/seasonSlice';
import { setSiteData } from '../store/features/siteDataSlice';
import { setMatchDays } from '../store/features/matchDaysSlice';
import { setUsers } from '../store/features/usersSlice';
import { setVotingSessions } from '../store/features/votingSessionsSlice';
import { setFantasyTeams } from '../store/features/fantasyTeamsSlice';
import { setFantasySessions } from '../store/features/fantasySessionsSlice';

// POST
export const addPlayer = createAsyncThunk(
    'players/addPlayer',
    async (player: Player, { dispatch, rejectWithValue }) => {
        try {
            const response = await addPlayerApi(player);
            dispatch(fetchAllPlayers());
            return response;
        } catch (error: any) {
            return rejectWithValue(error.message);
        }
    }
);

export const addTeam = createAsyncThunk('teams/addTeam', async (team: Team, { dispatch, rejectWithValue }) => {
    try {
        const response = await addTeamApi(team);
        dispatch(fetchAllTeams());
        return response;
    } catch (error: any) {
        return rejectWithValue(error.message);
    }
});

export const addNews = createAsyncThunk('news/addNews', async (news: News, { dispatch, rejectWithValue }) => {
    try {
        const response = await addNewsApi(news);
        dispatch(fetchAllNews());
        return response;
    } catch (error: any) {
        return rejectWithValue(error.message);
    }
});

export const addSeason = createAsyncThunk(
    'seasons/addSeason',
    async (season: SeasonPayload, { dispatch, rejectWithValue }) => {
        try {
            const response = await addSeasonApi(season);
            dispatch(fetchAllSeasons());
            return response;
        } catch (error: any) {
            return rejectWithValue(error.message);
        }
    }
);

export const addMatchDay = createAsyncThunk(
    'matchDays/addMatchDay',
    async (matchDay: MatchDay, { dispatch, rejectWithValue }) => {
        try {
            const response = await addMatchDayApi(matchDay);
            dispatch(fetchAllMatchDays());
            return response;
        } catch (error: any) {
            return rejectWithValue(error.message);
        }
    }
);

export const postVote = createAsyncThunk('votes/addMatchDay', async (vote: Vote, { dispatch, rejectWithValue }) => {
    try {
        const response = await postVoteApi(vote);
        dispatch(fetchAllVotingSessions());
        dispatch(fetchAllPlayers());
        return response;
    } catch (error: any) {
        return rejectWithValue(error.message);
    }
});

export const postVotingSession = createAsyncThunk(
    'votes/votingSessions',
    async (votingSession: VotingSession, { dispatch, rejectWithValue }) => {
        try {
            const response = await postVotingSessionApi(votingSession);
            dispatch(fetchAllVotingSessions());
            dispatch(fetchAllPlayers());
            return response;
        } catch (error: any) {
            return rejectWithValue(error.message);
        }
    }
);

export const addFantasyTeam = createAsyncThunk(
    'fantasyTeams/postFantasyTeam',
    async (fantasyTeam: FantasyTeam, { dispatch, rejectWithValue }) => {
        try {
            const response = await postFantasyTeamApi(fantasyTeam);
            dispatch(fetchAllFantasyTeams());
            return response;
        } catch (error: any) {
            return rejectWithValue(error.message);
        }
    }
);

export const postFantasySession = createAsyncThunk(
    'fantasySessions/postFantasySession',
    async (fantasySession: FantasySession, { dispatch, rejectWithValue }) => {
        try {
            const response = await postFantasySessionApi(fantasySession);
            dispatch(fetchAllFantasyTeams());
            return response;
        } catch (error: any) {
            return rejectWithValue(error.message);
        }
    }
);

// PUT
export const updatePlayer = createAsyncThunk(
    'players/updatePlayer',
    async (payload: { playerId: string; player: Player }, { rejectWithValue }) => {
        try {
            const { playerId, player } = payload;
            const response = await updatePlayerApi(playerId, player);
            return response;
        } catch (error: any) {
            return rejectWithValue(error.message);
        }
    }
);

export const updateUser = createAsyncThunk(
    'users/updateUser',
    async (payload: { userId: string; user: AuthenticatedUser }, { rejectWithValue }) => {
        try {
            const { userId, user } = payload;
            const response = await updateUserApi(userId, user);
            return response;
        } catch (error: any) {
            return rejectWithValue(error.message);
        }
    }
);

export const updateTeam = createAsyncThunk(
    'teams/updateTeam',
    async (payload: { teamId: string; team: Team }, { rejectWithValue }) => {
        try {
            const { teamId, team } = payload;
            const response = await updateTeamApi(teamId, team);
            return response;
        } catch (error: any) {
            return rejectWithValue(error.message);
        }
    }
);

export const updateNews = createAsyncThunk(
    'news/updateNews',
    async (payload: { newsId: string; news: News }, { rejectWithValue }) => {
        try {
            const { newsId, news } = payload;
            const response = await updateNewsApi(newsId, news);
            return response;
        } catch (error: any) {
            return rejectWithValue(error.message);
        }
    }
);

export const updateSeason = createAsyncThunk(
    'seasons/updateSeason',
    async (payload: { seasonId: string; season: Season }, { rejectWithValue }) => {
        try {
            const { seasonId, season } = payload;
            const response = await updateSeasonApi(seasonId, season);
            return response;
        } catch (error: any) {
            return rejectWithValue(error.message);
        }
    }
);

export const updateSiteData = createAsyncThunk(
    'siteData/updateSiteData',
    async (payload: { siteData: SiteData }, { rejectWithValue }) => {
        try {
            const { siteData } = payload;
            const response = await updateSiteDataApi(siteData);
            return response;
        } catch (error: any) {
            return rejectWithValue(error.message);
        }
    }
);

export const updateMatchDay = createAsyncThunk(
    'matchDays/updateMatchDay',
    async (payload: { matchDayId: string; matchDay: MatchDay }, { rejectWithValue }) => {
        try {
            const { matchDayId, matchDay } = payload;
            const response = await updateMatchDayApi(matchDayId, matchDay);
            return response;
        } catch (error: any) {
            return rejectWithValue(error.message);
        }
    }
);

export const updatePlayerStatsForMatch = createAsyncThunk(
    'players/updatePlayerStats',
    async (
        payload: { playerUid: string; matchDayUid: string; seasonUid: string; playerStats: GameStats },
        { rejectWithValue }
    ) => {
        try {
            const { playerUid, matchDayUid, seasonUid, playerStats } = payload;
            console.log('payload...', payload);
            const response = await updatePlayerStatsForMatchApi(playerUid, matchDayUid, seasonUid, playerStats);
            return response;
        } catch (error: any) {
            return rejectWithValue(error.message);
        }
    }
);

export const updateFantasyTeamWeek = createAsyncThunk(
    'fantasyTeams/addFantasyTeamWeek',
    async (
        payload: { fantasyTeamUid: string; fantasySessionUid: string; players: string[] },
        { dispatch, rejectWithValue }
    ) => {
        try {
            const { fantasyTeamUid, fantasySessionUid, players } = payload;
            const response = await updateFantasyTeamWeekApi(fantasyTeamUid, fantasySessionUid, players);
            dispatch(fetchAllFantasyTeams());
            return response;
        } catch (error: any) {
            return rejectWithValue(error.message);
        }
    }
);

// GET
export const fetchAllPlayers = createAsyncThunk('players/fetchAllPlayers', async (_, { dispatch, rejectWithValue }) => {
    try {
        const response = await getAllPlayers();
        dispatch(setPlayers(response.players));
        return response;
    } catch (error: any) {
        return rejectWithValue(error.message);
    }
});

export const fetchAllUsers = createAsyncThunk('users/fetchAllUSers', async (_, { dispatch, rejectWithValue }) => {
    try {
        const response = await getAllUsers();
        dispatch(setUsers(response.users));
        return response;
    } catch (error: any) {
        return rejectWithValue(error.message);
    }
});

export const fetchAllTeams = createAsyncThunk('teams/fetchAllTeams', async (_, { dispatch, rejectWithValue }) => {
    try {
        const response = await getAllTeams();
        dispatch(setTeams(response.teams));
        return response;
    } catch (error: any) {
        return rejectWithValue(error.message);
    }
});

export const fetchAllNews = createAsyncThunk('news/fetchAllNews', async (_, { dispatch, rejectWithValue }) => {
    try {
        const response = await getAllNews();
        dispatch(setNews(response.news));
        return response;
    } catch (error: any) {
        return rejectWithValue(error.message);
    }
});

export const fetchAllSeasons = createAsyncThunk('seasons/fetchAllSeasons', async (_, { dispatch, rejectWithValue }) => {
    try {
        const response = await getAllSeasons();
        dispatch(setSeasons(response.seasons));
        return response;
    } catch (error: any) {
        return rejectWithValue(error.message);
    }
});

export const fetchSiteData = createAsyncThunk('siteDate/fetchSiteData', async (_, { dispatch, rejectWithValue }) => {
    try {
        const response = await getSiteData();
        dispatch(setSiteData(response.siteData));
        return response;
    } catch (error: any) {
        return rejectWithValue(error.message);
    }
});

export const fetchAllMatchDays = createAsyncThunk(
    'matchDays/fetchAllMatchDays',
    async (_, { dispatch, rejectWithValue }) => {
        try {
            const response = await getMatchDays();
            dispatch(setMatchDays(response.matchDays));
            return response;
        } catch (error: any) {
            return rejectWithValue(error.message);
        }
    }
);

export const fetchAllVotingSessions = createAsyncThunk(
    'matchDays/fetchAllVotingSessions',
    async (_, { dispatch, rejectWithValue }) => {
        try {
            const response = await getVotingSessions();
            dispatch(setVotingSessions(response.votingSessions));
            return response;
        } catch (error: any) {
            return rejectWithValue(error.message);
        }
    }
);

export const fetchAllFantasyTeams = createAsyncThunk(
    'fantasyTeams/fetchAllFantasyTeams',
    async (_, { dispatch, rejectWithValue }) => {
        try {
            const response = await getAllFantasyTeams();
            dispatch(setFantasyTeams(response.fantasyTeams));
            return response;
        } catch (error: any) {
            return rejectWithValue(error.message);
        }
    }
);

export const fetchAllFantasySessions = createAsyncThunk(
    'fantasySessions/fetchAllFantasySessions',
    async (_, { dispatch, rejectWithValue }) => {
        try {
            const response = await getAllFantasySessions();
            dispatch(setFantasySessions(response.fantasySessions));
            return response;
        } catch (error: any) {
            return rejectWithValue(error.message);
        }
    }
);

export const fetchPlayer = async (playerId: string): Promise<GetPlayerByUidServerResponse> => {
    try {
        const response = await getPlayerByUidApi(playerId);
        return response;
    } catch (error) {
        console.error('Error fetching player:', error);
        throw error;
    }
};

export const fetchTeam = async (teamId: string): Promise<GetTeamByUidServerResponse> => {
    try {
        const response = await getTeamByUidApi(teamId);
        return response;
    } catch (error) {
        console.error('Error fetching team:', error);
        throw error;
    }
};

// DELETE
export const deleteMatchay = createAsyncThunk(
    'schools/deleteSchool',
    async (matchId: string, { dispatch, rejectWithValue }) => {
        try {
            await deleteMatchDayApi(matchId);
            const response = await getMatchDays();
            dispatch(setMatchDays(response.matchDays));
            return response;
        } catch (error: any) {
            return rejectWithValue(error.message);
        }
    }
);
