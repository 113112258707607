import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Autoplay, Pagination } from 'swiper/modules';

import 'swiper/css';
import 'swiper/css/pagination';

interface SwiperProps {
    children: JSX.Element[];
    slidesPerView: number;
    spaceBetween?: number;
    pagination: boolean;
    breakpoints?: any;
    paginationDotColor?: string;
    delay?: number;
}

const renderSwiperSlide = (slide: any, key: any): JSX.Element => {
    return (
        <SwiperSlide key={key} style={{ textAlign: 'left' }}>
            {slide}
        </SwiperSlide>
    );
};

export const SwiperCarousel = (props: SwiperProps): JSX.Element => {
    return (
        <Swiper
            slidesPerView={props.slidesPerView || 1}
            spaceBetween={props.spaceBetween || 0}
            pagination={props.pagination ? { type: 'progressbar' } : false}
            autoplay={{
                delay: props.delay || 3000,
            }}
            navigation={true}
            allowTouchMove={true}
            breakpoints={
                props.breakpoints || {
                    640: {
                        slidesPerView: 5,
                        spaceBetween: 10,
                    },
                    768: {
                        slidesPerView: 4,
                        spaceBetween: 10,
                    },
                    1024: {
                        slidesPerView: 5,
                        spaceBetween: 10,
                    },
                }
            }
            modules={[Autoplay, Pagination]}
            style={{ width: '100%' }}
            className={props.paginationDotColor}
        >
            {props.children.map((slide: any, key: number) => {
                return renderSwiperSlide(slide, key);
            })}
        </Swiper>
    );
};
