import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';
import { universalColorScheme } from '../../utils/d3';

interface D3MultiLineChartLegendProps {
    metrics: string[]; // Array of metrics (e.g., ['wins', 'draws', 'losses'])
}

export const D3MultiLineChartLegend: React.FC<D3MultiLineChartLegendProps> = ({ metrics }) => {
    const svgRef = useRef<SVGSVGElement>(null);

    useEffect(() => {
        if (!svgRef.current || metrics.length === 0) return;

        const itemHeight = 30;
        const margin = 5;

        const colorScale = d3.scaleOrdinal<string>().domain(metrics).range(universalColorScheme);

        const svg = d3.select(svgRef.current);

        // Remove existing legend items
        svg.selectAll('*').remove();

        // Create new legend items
        const legendItems = svg
            .selectAll('g')
            .data(metrics)
            .enter()
            .append('g')
            .attr('transform', (d, i) => `translate(0, ${i * (itemHeight + margin)})`)
            .style('opacity', 0); // Set initial opacity to 0

        legendItems
            .transition() // Add transition
            .duration(1000) // Set the duration of the transition in milliseconds
            .style('opacity', 1); // Transition opacity to 1

        legendItems
            .append('rect')
            .attr('width', 10)
            .attr('height', 10)
            .style('fill', (d) => colorScale(d));

        legendItems
            .append('text')
            .attr('x', 20)
            .attr('y', 10)
            .style('text-anchor', 'start')
            .style('font-size', '14px')
            .text((d) => d.charAt(0).toUpperCase() + d.slice(1)); // Capitalize first letter
    }, [metrics]);

    return <svg ref={svgRef} className='legend' width={'100%'} />;
};
