import React from 'react';
import {
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
    signOut,
    onAuthStateChanged,
    User,
    sendPasswordResetEmail,
    signInAnonymously,
    deleteUser,
    updateProfile,
} from 'firebase/auth';
import { firebaseAuth } from '../firebase-config';

const UserContext = React.createContext(null);

export const AuthContextProvider = ({ children }: any): JSX.Element => {
    const [user, setUser] = React.useState<User | null>(null);
    const [isLoading, setIsLoading] = React.useState<boolean>(true);

    const updateUser = async () => {
        const currentUser = await firebaseAuth.currentUser;
        if (currentUser) {
            setUser(currentUser);
        }
    };

    React.useEffect(() => {
        const unsubscribe = onAuthStateChanged(firebaseAuth, (currentUser) => {
            setUser(currentUser);
            setIsLoading(false);
        });
        return () => unsubscribe();
    }, []);

    const createUser = (email: string, password: string) => {
        return createUserWithEmailAndPassword(firebaseAuth, email, password);
    };

    const updateUserProfile = async (profileData: { displayName?: string; photoURL?: string }): Promise<void> => {
        if (user) {
            try {
                await updateProfile(user, profileData);
                await updateUser();
            } catch (error) {
                console.error('Error updating profile:', error);
                throw error;
            }
        }
    };

    // const signIn = (email: string, password: string): any => {
    //     return signInWithEmailAndPassword(firebaseAuth, email, password);
    // };

    const signIn = async (email: string, password: string): Promise<void> => {
        try {
            const credential = await signInWithEmailAndPassword(firebaseAuth, email, password);
            const user = credential.user;

            // Fetch custom claims
            const idTokenResult = await user.getIdTokenResult();

            // Access custom claims to determine the role
            const { roles } = idTokenResult.claims;

            setUser(user);

            console.log('roles in AuthConetxt: roles', roles);

            // Additional logic based on roles (e.g., redirect to specific routes)
            // ...
        } catch (error) {
            console.error('Error signing in:', error);
            throw error;
        }
    };

    const signInAnon = (): any => {
        return signInAnonymously(firebaseAuth);
    };

    const resetPassword = (email: string) => {
        return sendPasswordResetEmail(firebaseAuth, email);
    };

    const logout = () => {
        return signOut(firebaseAuth);
    };

    return (
        <UserContext.Provider
            value={
                {
                    createUser,
                    signIn,
                    updateUserProfile,
                    signInAnon,
                    resetPassword,
                    logout,
                    user,
                    isLoading,
                    deleteUser,
                } as any
            }
        >
            {children}
        </UserContext.Provider>
    );
};

export const UserAuth = (): any => {
    return React.useContext(UserContext);
};
