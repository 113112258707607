import React from 'react';
import { Grid } from '@mui/material';
import { Container, Typography } from '@mui/material';
import { CustomAlertProps, Player, VotingSession } from '../../../types';
import { useParams } from 'react-router-dom';
import { CustomAlert } from '../../../components/CustomAlert';
import { LoadingOverlay } from '../../../components/LoadingOverlay';
import { initialAlertState } from '../../../utils/consts';
import { fetchTeam as fetchTeamAsync } from '../../../utils/actions';
import { useAppSelector } from '../../../store/store';

export const VoteSessionDetails = (): JSX.Element => {
    const { voteSessionUid } = useParams<{ voteSessionUid: string }>();

    const votingSessions = useAppSelector((state) => state.votingSession.votingSessions);
    const players = useAppSelector((state) => state.player.players);

    const [loading, setLoading] = React.useState<boolean>(false);
    const [customAlertState, setCustomAlertState] = React.useState<CustomAlertProps>({
        ...initialAlertState,
        closeAlert: () => handleCloseAlert(),
    });
    const [playersWhoVoted, setPlayersWhoVoted] = React.useState<Player[]>([]);

    React.useEffect(() => {
        if (votingSessions && players && voteSessionUid) {
            const tempFoundSession: VotingSession | undefined = votingSessions.find(
                (session) => session.uid === voteSessionUid
            );
            if (tempFoundSession) {
                const tempPlayersWhoVoted = tempFoundSession.playersWhoVoted
                    .map((playerUid) => players.find((player) => player.uid === playerUid))
                    .filter((player) => player !== undefined);
                // @ts-ignore
                setPlayersWhoVoted(tempPlayersWhoVoted);
            }
        }
    }, [votingSessions, players, voteSessionUid]);

    React.useEffect(() => {
        if (voteSessionUid) {
            const fetchData = async () => {
                try {
                    const voteSessionData = await fetchTeamAsync(voteSessionUid);
                    if (voteSessionData.success) {
                        setCustomAlertState((prev) => {
                            prev = { ...prev };
                            prev.open = false;
                            prev.severity = 'warning';
                            prev.closeAlert = handleCloseAlert;
                            return prev;
                        });
                        setLoading(false);
                    }
                } catch (error: any) {
                    console.error('Error fetching data:', error);
                    setLoading(false);
                    setCustomAlertState((prev) => {
                        prev = { ...prev };
                        prev.open = true;
                        prev.severity = 'error';
                        prev.closeAlert = handleCloseAlert;
                        prev.message = error;
                        return prev;
                    });
                }
            };
            fetchData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [voteSessionUid]);

    const handleCloseAlert = () => {
        setCustomAlertState((prev) => {
            prev = { ...prev };
            prev.open = false;
            return prev;
        });
    };

    return (
        <Container maxWidth='xl'>
            {loading ? (
                <LoadingOverlay message='Loading details.' />
            ) : (
                <>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Typography variant='h4'>Vote Session Details</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography>Players who voted:</Typography>
                            {playersWhoVoted.map((player, index: number) => {
                                return (
                                    <Typography key={index} variant='subtitle2'>
                                        {index + 1}. {player.name}
                                    </Typography>
                                );
                            })}
                        </Grid>
                    </Grid>
                    <CustomAlert {...customAlertState} />
                </>
            )}
        </Container>
    );
};
