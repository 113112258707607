import React from 'react';
import { Colors } from '../styles';
import '../App.css';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider, createTheme } from '@mui/material/styles';

const ColorModeContext = React.createContext({ toggleColorMode: () => {} });

export const AppTheme = (props: { children: JSX.Element }) => {
    const [mode, setMode] = React.useState<'light' | 'dark'>('light');
    const colorMode = React.useMemo(
        () => ({
            toggleColorMode: () => {
                setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
            },
        }),
        []
    );

    const theme = React.useMemo(
        () =>
            createTheme({
                palette: {
                    background: {
                        default: Colors.light.background,
                        paper: Colors.light.paper,
                    },
                    mode,
                    primary: {
                        main: Colors.light.primary,
                    },
                    secondary: {
                        main: Colors.light.secondary,
                    },
                },

                components: {
                    MuiCard: {
                        defaultProps: {
                            style: {
                                borderRadius: 5,
                                backgroundColor: Colors.light.cardBackground,
                            },
                            variant: 'outlined',
                        },
                    },
                    MuiTableContainer: {
                        defaultProps: {
                            // variant: 'outlined',
                        },
                    },
                    MuiAppBar: {
                        defaultProps: {
                            color: 'primary',
                            elevation: 0,
                        },
                        styleOverrides: {
                            root: {
                                // backgroundColor: '#84ffd2',
                                // backgroundColor: '#52dea0',
                                background: '#fff',
                            },
                        },
                    },
                    MuiDrawer: {
                        styleOverrides: {
                            paper: {
                                // backgroundColor: '#2bc580 !important',
                            },
                        },
                    },
                    MuiTextField: {
                        styleOverrides: {
                            root: {
                                '& .MuiOutlinedInput-root': {
                                    '&:hover fieldset': {
                                        // borderColor: 'rgba(0, 0, 0, 0.23)',
                                    },
                                    '&.Mui-focused fieldset': {
                                        // borderColor: 'rgba(0, 0, 0, 0.23)',
                                    },
                                },
                            },
                        },
                        defaultProps: {
                            inputProps: {
                                style: {
                                    // height: '.85rem',
                                },
                            },
                            variant: 'filled',
                            size: 'small',
                        },
                    },
                    MuiTypography: {
                        styleOverrides: {
                            h4: {
                                fontWeight: 'bolder',
                                textTransform: 'uppercase',
                            },
                        },
                    },
                    MuiSkeleton: {
                        styleOverrides: {},
                        defaultProps: {
                            variant: 'rounded',
                            animation: 'wave',
                            height: 200,
                        },
                    },
                },
                typography: {
                    fontFamily: '"Titillium Web", sans-serif',
                },
            }),
        [mode]
    );
    const darkTheme = React.useMemo(
        () =>
            createTheme({
                palette: {
                    background: {
                        default: Colors.dark.background,
                        paper: Colors.dark.paper,
                    },
                    mode,
                    primary: {
                        main: Colors.dark.primary,
                    },
                    text: {
                        primary: Colors.dark.text_primary,
                    },
                },
            }),
        [mode]
    );
    return (
        <ColorModeContext.Provider value={colorMode}>
            <ThemeProvider theme={mode === 'light' ? theme : darkTheme}>
                <CssBaseline />
                {props.children}
            </ThemeProvider>
        </ColorModeContext.Provider>
    );
};
